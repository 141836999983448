import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../pages/auth/Login'
import EmailPassword from '../pages/auth/passwords/EmailPassword';
import ResetPassword from '../pages/auth/passwords/ResetPassword';

// Pages
import Home from '../pages/Home'
import About from '../pages/about/About';
import PageNotFound from '../pages/PageNotFound';
import ResourcesHCP from '../pages/resources/ResourcesHCP';
import ResourcesRep from '../pages/resources/ResourcesRep';
import ShowMeeting from '../pages/meetings/ShowMeeting';
import Speakers from '../pages/speakers/Speakers';
import Speaker from '../pages/speakers/Speaker';

// HCP Register
import RegisterHCP from '../pages/register/RegisterHCP';

// Rep Register
import RegisterRep from '../pages/register/RegisterRep';
import VerifyEmail from '../pages/register/VerifyEmail';
import SetPassword from '../pages/auth/passwords/SetPassword';

// User
import MyMeetings from '../pages/user/MyMeetings';
import EditProfile from '../pages/user/EditProfile';
import ChangePassword from '../pages/user/ChangePassword';

// Admin UI
import AdminSpeakers from '../pages/admin/Speakers'; // JC-33
import AdminUsers from '../pages/admin/Users';
import CreateUser from '../pages/admin/CreateUser';
import EditUser from '../pages/admin/EditUser';
import Meetings from '../pages/admin/Meetings';
import AdminMeetingRequests from '../pages/admin/MeetingRequests';
import AdminMeetingRequest from '../pages/admin/MeetingRequest';
import Territories from '../pages/admin/Territories';
import ShowTerritory from '../pages/admin/ShowTerritory';
import ShowSalesRep from '../pages/admin/ShowSalesRep';
import SalesReps from '../pages/admin/SalesReps';

// Wizard
import Wizard from '../pages/wizard/Wizard';
import Start from '../pages/wizard/Start';
import EditHCP from '../pages/wizard/EditHCP';
import SearchHCPs from '../pages/wizard/SearchHCPs'; // MSPR-17
import CreateHCP from '../pages/wizard/CreateHCP';
import Topic from '../pages/wizard/Topic';
import ReviewHCP from '../pages/wizard/ReviewHCP';
import ReviewRep from '../pages/wizard/ReviewRep';
import Schedule from '../pages/wizard/Schedule';

import {adminGuard, authGuard, guestGuard, repGuard, wizardGuard} from './guards';

Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
          layout: 'branded',
      }
  },
  { // MSPR-29 moved out of authGuard
      path: '/resources/hcp',
      name: 'hcp_resources',
      component: ResourcesHCP,
      meta: {
          layout: 'unbranded',
          title: 'Resources',
      }
  },
  {
      path: '/speakers',
      name: 'speakers',
      component: Speakers,
      meta: {
          layout: 'branded',
          title: 'Speakers'
      }
  },
  {
      path: '/speakers/:speakerID(\\d+)',
      name: 'speaker.show',
      component: Speaker,
      meta: {
          layout: 'unbranded',
          title: 'Speaker'
      }
  },
  ...wizardGuard([
      {
          path: '/wizard',
          component: Wizard,
          children: [
              {
                  path: '',
                  name: 'wizard.start',
                  component: Start,
                  meta: {
                      layout: 'branded',
                      title: 'Start'
                  },
              },
              {
                  path: '/wizard/topic',
                  name: 'wizard.topic',
                  component: Topic,
                  meta: {
                      layout: 'branded',
                      title: 'Select Topic',
                  }
              },
              {
                  path: '/wizard/hcps',
                  name: 'wizard.hcps',
                  component: SearchHCPs, // MSPR-17
                  meta: {
                      layout: 'branded',
                      title: 'Search HCPs',
                      roles: ['admin', 'rep'],
                  }
              },
              {
                  path: '/wizard/hcp/create',
                  name: 'wizard.create_hcp',
                  component: CreateHCP,
                  meta: {
                      layout: 'branded',
                      title: 'Add HCP',
                      roles: ['admin', 'rep'],
                  }
              },
              {
                  path: '/wizard/hcp/edit',
                  name: 'wizard.edit_hcp',
                  component: EditHCP,
                  meta: {
                      layout: 'branded',
                      title: 'Review',
                      roles: ['hcp'],
                  }
              },
              {
                  path: '/wizard/schedule',
                  name: 'wizard.schedule',
                  component: Schedule,
                  meta: {
                      layout: 'branded',
                      title: 'Schedule'
                  }
              },
              {
                  path: '/wizard/register/hcp',
                  name: 'register.hcp',
                  component: RegisterHCP,
                  meta: {
                      layout: 'unbranded',
                      title: 'Health Care Professional Registration',
                  }
              },
              {
                  path: '/wizard/review/hcp',
                  name: 'wizard.review_hcp',
                  component: ReviewHCP,
                  meta: {
                      layout: 'branded',
                      title: 'Review'
                  }
              },
              {
                  path: '/wizard/review/rep',
                  name: 'wizard.review_rep',
                  component: ReviewRep,
                  meta: {
                      layout: 'branded',
                      title: 'Review'
                  }
              },
          ]
      },
   ]),
   ...guestGuard([
      {
          path: '/login',
          name: 'login',
          component: Login,
          meta: {
              layout: 'unbranded',
              title: 'Log In',
          }
      },
      {
          path: '/password/reset',
          name: 'password.request',
          component: EmailPassword,
          meta: {
              layout: 'unbranded',
              title: 'Send Reset Password Link',
          },
      },
      {
          path: '/password/reset/:token',
          name: 'password.reset',
          component: ResetPassword,
          meta: {
              layout: 'unbranded',
              title: 'Reset Your Password',
          },
      },
      {
          path: '/register/rep',
          name: 'register.rep',
          component: RegisterRep,
          meta: {
              layout: 'unbranded',
              title: 'Sanofi Pharmaceuticals, Inc. Representative Registration',
          }
      },
      { // MSPR-25
          path: '/webhook/hcp/register',
          name: 'webhook.register.hcp',
          component: RegisterHCP,
          meta: {
              layout: 'unbranded',
              title: 'Health Care Professional Registration from Sanofi',
          }
      },
      {
        path: '/email/verify/:id?/:hash?',
        name: 'verification.notice',
        component: VerifyEmail,
        meta: {
            layout: 'unbranded',
            title: 'Verify Your Email Address',
        },
    },
   ]),
   ...repGuard([
      {
          path: '/about',
          name: 'about',
          component: About,
          meta: {
              layout: 'branded',
              title: 'About mChat',
              roles: ['admin', 'rep'],
          },
      },
      {
          path: '/meetings',
          name: 'meetings',
          component: MyMeetings,
          meta: {
              layout: 'branded',
              title: 'Meetings',
              roles: ['admin', 'rep'],
          },
      },
      {
          path: '/hcps/create',
          name: 'hcp.create',
          component: CreateHCP,
          meta: {
              layout: 'branded',
              title: 'Add HCP',
              roles: ['admin', 'rep'],
          }
      },
   ]),
   ...authGuard([
      {
          path: '/meetings/:meetingID(\\d+)',
          name: 'meeting.show',
          component: ShowMeeting,
          meta: {
              layout: 'branded',
              title: 'Show Meeting',
          }
      },
      {
          path: '/set-password',
          name: 'set_password',
          component: SetPassword,
          meta: {
              layout: 'unbranded',
              title: 'Set Your Password',
          },
      },
      {
          path: '/edit-profile',
          name: 'edit_profile',
          component: EditProfile,
          meta: {
              layout: 'unbranded',
              title: 'Edit Profile',
          },
      },
      {
          path: '/change-password',
          name: 'change_password',
          component: ChangePassword,
          meta: {
              layout: 'unbranded',
              title: 'Change My Password',
          },
      },
      {
          path: '/resources/rep',
          name: 'rep_resources',
          component: ResourcesRep,
          meta: {
              layout: 'unbranded',
              title: 'Resources',
          }
      },
      {
          path: '/005',
          redirect: '/005/wizard',
      },
      {
          path: '/007',
          redirect: '/007/wizard',
      },
   ]),

   ...adminGuard([
      {
          name: 'admin.meetings',
          path: '/admin/meetings',
          component: Meetings,
          meta: {
              layout: 'admin',
              title: 'Meetings',
          }
      },
      {
          name: 'admin.reps',
          path: '/admin/reps',
          component: SalesReps,
          meta: {
              layout: 'admin',
              title: 'Sales Reps',
          }
      },
      {
          name: 'admin.show_rep',
          path: '/admin/reps/:repID',
          component: ShowSalesRep,
          meta: {
              layout: 'admin',
              title: 'Sales Rep',
          }
      },
      {
          name: 'admin.requests',
          path: '/admin/requests',
          component: AdminMeetingRequests,
          meta: {
              layout: 'admin',
              title: 'Meeting Requests',
          }
      },
      {
          name: 'admin.request.show',
          path: '/admin/requests/:requestID(\\d+)',
          component: AdminMeetingRequest,
          meta: {
              layout: 'admin',
              title: 'Meeting Request',
          }
      },
      {
          name: 'admin.speakers',
          path: '/admin/speakers',
          component: AdminSpeakers,
          meta: {
              layout: 'admin',
              title: 'Speakers',
          }
      },
      {
          name: 'admin.territories',
          path: '/admin/territories',
          component: Territories,
          meta: {
              layout: 'admin',
              title: 'Territories',
          }
      },
      {
          name: 'admin.show_territory',
          path: '/admin/territories/:territoryID',
          component: ShowTerritory,
          meta: {
              layout: 'admin',
              title: 'Territory',
          }
      },
      {
          name: 'admin.users',
          path: '/admin/users',
          component: AdminUsers,
          meta: {
              layout: 'admin',
              title: 'Users',
          }
      },
      {
          name: 'user.create',
          path: '/admin/users/create',
          component: CreateUser,
          meta: {
              layout: 'admin',
              title: 'Create User',
          }
      },
      {
          name: 'user.edit',
          path: '/admin/users/:userID/edit',
          component: EditUser,
          meta: {
              layout: 'admin',
              title: 'Edit User',
          }
      },
      {
          path: '/admin*',
          redirect: '/admin/users',
      },
  ]),

  {
      path: '*',
      component: PageNotFound,
      meta: {
          layout: 'unbranded',
          title: 'Page Not Found',
      }
  }
];

const router = new VueRouter({
  mode: 'history',
  //base: 'http://devstatic.medchatforsanofi.com/',
  routes
})

// @see https://github.com/vuejs/vue-router/issues/914
router.afterEach((to, from) => {
    if (to && to.meta && to.meta.title) {
        document.title = to.meta.title;
    }
    if (typeof gtag === 'function' && window.GA_MEASUREMENT_ID) {
        try {
            // @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
            gtag('config', window.GA_MEASUREMENT_ID, {
                page_path: `${to.fullPath}`,
                page_title: document.title,
            });
        } catch (e) {
            console.error('gtag function does not exist.');
        }
    }
});

export default router
