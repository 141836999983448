<template>
    <v-container class="pt-10">
        <v-row>

            <!-- <v-col cols="12"
                   md="3">
                <brand-logo class="mt-5">
                </brand-logo>
            </v-col> -->

            <v-col cols="12"
                   md="7" class="Page-Center">

                <!--v-card outlined
                        class="mb-4">
                    <v-card-text class="tertiary--text">
                        As a reminder, the information provided through this website will be used to schedule and manage an mChat program and fulfill any other requests. You may withdraw registration by calling ConneXion360 at <span class="text-no-wrap">1-877-238-8500</span>. Our <a href="https://connexion360.com/index.php/privacy-policy/" target="_blank" class="tertiary--text">Privacy Policy</a> governs the use of the information you provide. By providing this information and clicking <span class="text-no-wrap">"Add New HCP,"</span> you indicate that you read, understand, and agree to these terms.
                    </v-card-text>
                </v-card-->

                <v-form @submit.prevent="submit"
                        ref="form">

                    <v-card outlined
                            tile
                            flat>
                        <v-toolbar color="primary"
                                   tile
                                   flat
                                   dark class="height-50">
                            <v-toolbar-title>
                                Add New HCPs That Are Not in Our System Yet
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="pt-8 pb-0">

                            <v-text-field v-model.trim="form.first_name"
                                          placeholder=" "
                                          autocomplete="given-name"
                                          outlined
                                          dense
                                          :rules="[rules.required]"
                                          :error-messages="form.errors.get('first_name')"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="First name"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.last_name"
                                          placeholder=" "
                                          autocomplete="family-name"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('last_name')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Last name"/>
                                </template>
                            </v-text-field>

                            <v-select v-model.trim="form.professional_designation"
                                      placeholder=" "
                                      :items="professionalDesignations"
                                      item-value="id"
                                      item-text="name"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('professional_designation')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Degree"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-select v-model.trim="form.specialty"
                                      placeholder=" "
                                      :items="specialties"
                                      item-value="id"
                                      item-text="name"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('specialty')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Specialty"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-text-field v-model.trim="form.npi"
                                          placeholder=" "
                                          label="NPI"
                                          autocomplete="new-npi"
                                          v-mask="'##########'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('npi')"
                                          pattern="[0-9]+"
                                          class="mb-4">
                            </v-text-field>

                            <v-text-field v-model.trim="form.email"
                                          placeholder=" "
                                          type="email"
                                          autocomplete="email"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('email')"
                                          :rules="[rules.required, rules.email]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Email"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.phone"
                                          placeholder=" "
                                          type="tel"
                                          autocomplete="tel"
                                          v-mask="'###-###-####'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('phone')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Phone"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.practice_name"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('practice_name')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Practice name"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.address1"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('address1')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Practice address 1"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.address2"
                                          label="Practice address 2"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('address2')"
                                          class="mb-4">
                            </v-text-field>

                            <v-text-field v-model.trim="form.city"
                                          placeholder=" "
                                          autocomplete="address-level2"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('city')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="City"/>
                                </template>
                            </v-text-field>

                            <v-select v-model="form.state"
                                      placeholder=" "
                                      autocomplete="address-level1"
                                      :items="states"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('state')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="State"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-text-field v-model.trim="form.zip_code"
                                          placeholder=" "
                                          autocomplete="postal-code"
                                          v-mask="'#####'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('zip_code')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="ZIP code"/>
                                </template>
                            </v-text-field>

                            <v-select v-model="form.timezone"
                                      placeholder=" "
                                      :items="timezones"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('timezone')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Time zone"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <!-- <v-checkbox v-model="form.attest"
                                        dense
                                        :error-messages="form.errors.get('attest')"
                                        :rules="[rules.required]"
                                        class="mb-4">
                                <template v-slot:label>
                                    <required-label label="I attest that the HCP whose information I have provided requested that I register them for the medChat program.">
                                    </required-label>
                                </template>
                            </v-checkbox> -->

                            <div class="font-italic">
                                <span class="error--text">*</span>Required field
                            </div>
                            <div v-if="error"
                                 class="error--text">
                                {{ error }}
                            </div>
                        </v-card-text>
                        <v-card-actions class="px-4 flex-column">
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   x-large
                                   depressed
                                   tile
                                   block>
                                ADD NEW HCP
                            </v-btn>
                            <v-btn :to="{name: 'wizard.hcps'}"
                                   v-if="isWizard"
                                   class="ml-0 font-weight-bold"
                                   x-large
                                   text
                                   block>
                                Cancel
                            </v-btn>
                            <v-btn :to="{name: 'meeting.show', params: {meetingID: meetingID}}"
                                   v-else-if="meetingID"
                                   class="ml-0 font-weight-bold"
                                   x-large
                                   text
                                   block>
                                Cancel
                            </v-btn>
                            <v-btn @click.prevent="$router.back()"
                                   v-else
                                   class="ml-0 font-weight-bold"
                                   x-large
                                   text
                                   block>
                                Cancel
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-form>

            </v-col>

        </v-row>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import BrandLogo from '../../components/BrandLogo';
import RequiredLabel from '../../components/forms/RequiredLabel';
import formValidation from '../../plugins/formValidation';
import SelectIcon from '../../components/forms/SelectIcon';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';

export default {
    components: {SelectIcon, RequiredLabel, BrandLogo},
    mixins: [formValidation],
    data() {
        return {
            error: null,
            form: new Form({
                first_name: '',
                last_name: '',
                email: '',
                timezone: '',
                specialty: '',
                professional_designation: '',
                phone: '',
                practice_name: '',
                street1: '',
                city: '',
                state: '',
                zip_code: '',
                npi: '',
                attest: false, // MSPR-6
            }),
        }
    },
    computed: {
        ...mapGetters([
            'professionalDesignations',
            'specialties',
            'states',
            'timezones',
            'wizard',
        ]),
        address() {
            const parts = [];
            if (this.form.street1) parts.push(this.form.street1);
            //if (this.form.street2) parts.push(this.form.street2);
            if (this.form.city) parts.push(this.form.city);
            if (this.form.state) parts.push(this.form.state);
            if (this.form.zip_code) parts.push(this.form.zip_code);
            return parts.join(' ');
        },
        isWizard() {
            return this.$route.name === 'wizard.create_hcp';
        },
        meetingID() {
            if (this.$route.query.meeting_id) {
                return parseInt(this.$route.query.meeting_id);
            }
            return null;
        },
    },
    created() {
        if (this.isWizard) {
            if (this.wizard
                && this.wizard.brand
                && this.wizard.brand.code === this.$root.brandCode
                && this.wizard.topic) {
                this.initForm();
                this.fetchData();
                return;
            }
            this.$root.startWizard();
        }
    },
    methods: {
        initForm() {

        },
        fetchData() {
            if (this.busy) {
                return;
            }
            this.busy = true;
            
            dataService.professionalDesignations()
                .then(data => {
                    this.professionalDesignationsObj = _.orderBy(data, 'id');
                })

            dataService.specialties()
                .then(data => {
                    this.specialtiesObj = _.orderBy(data, 'id');
                })
            
            dataService.timezones()
                .then(data => {
                    this.timezonesObj = _.orderBy(data, 'id');
                })

            dataService.getStates()
                .then(data => {
                    this.statesObj = _.orderBy(data, 'id');
                })
        },
        submit() {
            if (this.form.busy) {
                return;
            }
            if (!this.validateForm()) {
                return;
            }
            this.error = null; //Clear form errors
            
            // Set the header config so that if the form validation fails it will return json object
            let config = {
                headers: {'content-type': 'application/json;charset=utf-8',
                'X-Requested-With': 'XMLHttpRequest' }
            }
            this.form.post(`${env.MEDCHAT_API_URL}/api/hcps`, config )
                .then(response => {
                    
                    const hcp = response.data;
                    if (this.isWizard) {
                        this.$store.commit('ADD_WIZARD_HCP', hcp);
                        this.$router.push({name: 'wizard.hcps'});
                    } else if (this.meetingID) {
                        const form = {
                            hcp_ids: [hcp.id],
                        };
                        // console.log('Add selected HCPs to the meeting', form);
                        axios.post(`${env.MEDCHAT_API_URL}/api/meetings/${this.meetingID}/hcps`, form)
                            .then(response => {
                                this.$router.push({name: 'meeting.show', params: {meetingID: this.meetingID}})
                            })
                            .catch(err => {
                                if (err.response && err.response.data) {
                                    //this.$toastr.error(err.response.data);
                                }
                            });
                    } else {
                        this.$router.back();
                    }
                })
        }
    }
}
</script>
