<template>
    <v-card outlined
            flat
            tile
            class="Speaker-Panel fill-height">
        <v-toolbar color="primary"
                   flat
                   tile
                   dark class="height-50">
            {{ speaker.full_name_designation }}
        </v-toolbar>
        <v-card-text style="font-size: 14px; color: #2c3a61 !important;">
            <div class="d-flex">
                <div class="mr-3">
                    <v-avatar size="104px">
                        <v-img :src="speaker.headshot_url"/>
                    </v-avatar>
                </div>
                <div class="flex-grow-1">
                    <div class="mb-2 tertiary--text">
                        <div v-if="speaker.job_title">
                            {{ speaker.job_title }}
                        </div>
                        <div v-if="speaker.practice_name">
                            {{ speaker.practice_name }}
                        </div>
                        <div v-if="speaker.city && speaker.state">
                            {{ cityName }}, {{ stateName }}
                        </div>
                    </div>
                    <div>
                        <router-link :to="{name: 'speaker.show', params: {speakerID: speaker.id}}"
                                     class="tertiary--text">
                            View Profile
                        </router-link>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
    props: {
        speaker: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            // states: dataService.getStates(),
        }
    },
    computed: {
        ...mapGetters([
            'states',
        ]),
        cityName() {
            return this.speaker.city;
        },
        stateName() {
            if (!this.speaker.state) {
                return '';
            }
            const s = this.states.find(st => st.id === this.speaker.state.toUpperCase());
            if (s) {
                return s.name;
            }
            return this.speaker.state.toUpperCase();
        },
    },
}
</script>
