import store from '../store';

/**
 * @see https://gist.github.com/ivalkenburg/3e73b98a0c9b1c8dae71bd1c76363360
 * @see https://css-tricks.com/protecting-vue-routes-with-navigation-guards/
 * @param routes
 * @returns {*}
 */
function guestGuard(routes) {
    return routes.map(route => {
        return {
            ...route,
            beforeEnter: async (to, from, next) => {
                next()
            }
        };
    });
}

function wizardGuard(routes) {
    return routes.map(route => {
        return {
            ...route,
            beforeEnter: async (to, from, next) => {
                next();
            }
        };
    })
}

/**
 * @see https://gist.github.com/ivalkenburg/3e73b98a0c9b1c8dae71bd1c76363360
 * @see https://css-tricks.com/protecting-vue-routes-with-navigation-guards/
 * @param routes
 * @returns {*}
 */
function authGuard(routes) {
    return routes.map(route => {
        return {
            ...route,
            beforeEnter: async (to, from, next) => {
                if (store.getters.authenticated) {
                    next();
                } else {
                    await store.dispatch('fetchUser');

                    if (store.getters.authenticated) {
                        next();
                    } else {
                        console.log('authGuard fetchUser failed, sending to login');
                        next({name: 'login', query: {redirect: to.fullPath}})
                    }
                }
            }
        };
    });
}

function adminGuard(routes) {
    return routes.map(route => {
        return {
            ...route,
            beforeEnter: async (to, from, next) => {
                if (store.getters.isAdmin) {
                    next();
                } else {
                    await store.dispatch('fetchUser');

                    if (store.getters.isAdmin) {
                        next();
                    } else {
                        await store.dispatch('logout');

                        console.log('adminGuard fetchUser failed, sending to login');
                        next({name: 'login', query: {redirect: to.fullPath}})
                    }
                }
            }
        };
    });
}

function hcpGuard(routes) {
    return routes.map(route => {
        return {
            ...route,
            beforeEnter: async (to, from, next) => {
                if (store.getters.isHCP) {
                    next();
                } else {
                    await store.dispatch('fetchUser');

                    if (store.getters.isHCP) {
                        next();
                    } else {
                        await store.dispatch('logout');

                        console.log('repGuard fetchUser failed, sending to login');
                        next({name: 'login', query: {redirect: to.fullPath}})
                    }
                }
            }
        };
    });
}

function repGuard(routes) {
    return routes.map(route => {
        return {
            ...route,
            beforeEnter: async (to, from, next) => {
                if (store.getters.isRep || store.getters.isAdmin) {
                    next();
                } else {
                    await store.dispatch('fetchUser');

                    if (store.getters.isRep || store.getters.isAdmin) {
                        next();
                    } else {
                        await store.dispatch('logout');

                        console.log('repGuard fetchUser failed, sending to login');
                        next({name: 'login', query: {redirect: to.fullPath}})
                    }
                }
            }
        };
    });
}


export {
    adminGuard,
    authGuard,
    guestGuard,
    hcpGuard,
    repGuard,
    wizardGuard,
};
