<template>
    <v-container>

        <v-form @submit.prevent="submit">
            <v-card>
                <v-toolbar color="secondary"
                           dark>
                    <v-toolbar-title>
                        Create User
                    </v-toolbar-title>
                </v-toolbar>
                <user-form :form="form">
                </user-form>
                <v-card-actions>
                    <v-btn type="submit"
                           dark
                           color="secondary">
                        Submit
                    </v-btn>
                    <v-btn text
                           @click.prevent="$router.back()">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

    </v-container>
</template>
<script>
    import axios from 'axios';
    import UserForm from './UserForm';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';

    export default {
        components: {UserForm},
        data() {
            return {
                form: new Form(dataService.initUserForm()),
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {

            },
            submit() {
                this.form.post(`${env.MEDCHAT_API_URL}/api/users`)
                    .then(response => {
                        this.$router.push({name: 'admin.users'});
                    })
            }
        }
    }
</script>
