<template>
    <div>
        <v-container>
            <!-- <div class="Home__Hero px-0 py-8 d-flex flex-column align-center">
                <v-img src="/images/hero_logo.png"
                       max-width="740"
                       alt="mChat Logo"/>
                <h1 class="Home__Hero-Title">
                    Online Scheduling Assistant
                </h1>
                <h2 class="Home__Hero-Subtitle text-center">
                    medChat is a webinar platform that provides healthcare professionals the opportunity to engage with national and regional thought leaders via virtual speaker programs.
                </h2> 
            </div> -->

            <v-row class="py-0 pt-9 justify-center Home__Align-End">
                <v-col cols="12"
                        md="3">
                        <v-img class="mb-1" src="/images/medchat.png"
                        alt="mChat Logo"/>
                </v-col>
                <v-col cols="12"
                        md="5">
                        <h1 class="Home__Hero-Title">
                            Online Scheduling Assistant
                        </h1>
                        <h2 class="Home__Hero-Subtitle">
                            medChat is a webinar platform that provides healthcare professionals the opportunity to engage with national and regional thought leaders via virtual speaker programs.
                        </h2>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="pb-0 pt-5">
            <v-row class="px-10 pb-6 Padding-No">
                <v-col class="auto-height" cols="12"
                       md="6">

                    <v-card tile
                            flat>
                        <v-toolbar color="primary"
                                   dark
                                   flat
                                   tile
                                   class="d-flex justify-center height-50">
                            <v-toolbar-title class="text-uppercase show-overflow">
                                HOW TO REGISTER AN HCP
                            </v-toolbar-title>
                        </v-toolbar>

                        <div class="px-7 py-4 Common-Text-Color-Black">

                            <p>
                                Click on the <strong>REQUEST CUSTOM medCHAT</strong> button below or link above for a custom medChat or one of the <strong>REGISTER HCPs</strong> button below for a prescheduled medChat.
                            </p>

                            <div class="step">
                                <div class="label">
                                    STEP 1:
                                </div>
                                <div>
                                    Select the requested program module topic and click <strong>"NEXT."</strong>
                                </div>
                            </div>

                            <div class="step">
                                <div class="label">
                                    STEP 2:
                                </div>
                                <div>
                                    Select up to 3 speakers (as requested by the HCP attendee) using the <strong>"Speaker"</strong> drop down.
                                    <ul class="mb-4">
                                        <li>
                                            Select up to 3 dates and times
                                        </li>
                                        <li>
                                            Click <strong class="text-uppercase">"NEXT"</strong>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="step">
                                <div class="label">
                                    STEP 3:
                                </div>
                                <div>
                                    Search for your HCP customer’s name by entering it in the search box.
                                    <ul>
                                        <li>
                                            If found, add your HCP by clicking <strong>"Add"</strong>
                                        </li>
                                        <li>
                                            If not found, create a new HCP record by clicking <strong>"Not found - add new HCP"</strong>
                                            <ul>
                                                <li>
                                                    Enter HCP information
                                                </li>
                                                <li>
                                                    Click <strong class="text-uppercase">"Add new HCP"</strong>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <p>
                                Check all information before clicking <strong>"SUBMIT THIS medCHAT REQUEST."</strong>
                            </p>

                            <v-btn :to="{name: 'wizard.start'}"
                                   color="secondary"
                                   class="font-weight-bold text-no-transform"
                                   tile
                                   depressed
                                   block>
                                REQUEST CUSTOM medCHAT
                            </v-btn>
                        </div>

                    </v-card>


                </v-col>
                <v-col class="auto-height" cols="12"
                       md="6">
                    <v-card tile
                            flat>
                        <v-toolbar color="primary"
                                   dark
                                   flat
                                   tile
                                   class="d-flex justify-center height-50 mb-4">
                            <v-toolbar-title class="text-uppercase">
                                ADDITIONAL INFORMATION
                            </v-toolbar-title>
                        </v-toolbar>

                        <div class="px-7 py-4 tertiary--text pd-15"
                             style="font-size: 14px;">
                            <v-btn :to="{name: 'speakers'}"
                                   color="secondary"
                                   dark
                                   class="font-weight-bold mb-3"
                                   tile
                                   depressed
                                   block
                                   x-large>
                                <img src="/images/speakers_icon.png"
                                     alt="Speaker icon"
                                     class="mr-3"/>
                                Speakers
                            </v-btn>
                            <div class="text-center mb-9 text-black">
                                See available speakers.
                            </div>

                            <v-btn :to="{name: 'rep_resources'}"
                                   color="secondary"
                                   dark
                                   class="font-weight-bold mb-3"
                                   tile
                                   depressed
                                   block
                                   x-large>
                                <img src="/images/folder_icon.png"
                                     alt="Folder icon"
                                     class="mr-3"/>
                                Resources
                            </v-btn>
                            <div class="text-center mb-4 text-black">
                                Download the program content and the medChat Mobile Player.
                            </div>
                        </div>
                    </v-card>
                </v-col>
                
            </v-row>
        </v-container>

        <meeting-table>
        </meeting-table>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import jstz from 'jstz';
import MeetingTable from '../../components/meetings/MeetingTable';
import moment from 'moment-timezone'

export default {
    name: 'about',
    components: {MeetingTable},
    data() {
        return {
            busy: false,
            meetings: [],
            showMeetings: false,
            startDate: moment(),
            endDate: moment().add(2, 'months'),

        }
    },
    computed: {
        ...mapGetters([
            'authenticated',
            'brands',
            'user',
        ]),
        sortedMeetings() {
            return _.groupBy(this.meetings, meeting => {
                return moment(meeting.meeting_scheduled_at).format('MMMM D, YYYY');
            })
        },
        now() {
            return moment();
        },
        previousLink() {
            return this.startDate.isSameOrBefore(this.now, 'day');
        },
        nextLink() {
            return this.startDate.isSameOrAfter(moment().add(6, 'months'), 'day');
        },
    }
}
</script>
