<template>
    <v-container fluid>

        <v-card>
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    Meeting Requests
                </v-toolbar-title>

            </v-toolbar>
            <v-card-text>

                <v-skeleton-loader v-if="busy"
                                   type="table"
                                   :types="types">

                </v-skeleton-loader>

                <v-simple-table v-if="!busy">
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Requested Date
                        </th>
                        <th>
                            HCP Name
                        </th>
                        <th>
                            HCP Email
                        </th>
                        <th>
                            Topic
                        </th>
                        <th>
                            Territory
                        </th>
                        <th>
                            Created
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="request in requests"
                        :key="request.id">
                        <td>
                            <router-link :to="{name: 'admin.request.show', params: {requestID: request.id}}">
                                {{ request.id }}
                            </router-link>
                        </td>
                        <td>
                            <router-link :to="{name: 'admin.request.show', params: {requestID: request.id}}">
                                {{ request.appointment | datetime('YYYY-MM-DD h:mm a z') }}
                            </router-link>
                        </td>
                        <td>
                            {{ request.hcp.name }}
                        </td>
                        <td>
                            {{ request.hcp.email }}
                        </td>
                        <td>
                            {{ request.topic.name }}
                        </td>
                        <td>
                            <div v-if="request.territory">
                                {{ request.territory.name }}
                            </div>
                            <div v-else>
                                Unknown
                            </div>
                        </td>
                        <td>
                            {{ request.created_at | datetime }}
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import axios from 'axios';
    import _ from 'lodash';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';

    export default {
        data() {
            return {
                busy: false,
                requests: [],
                types: {
                    //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                    'table': 'table-thead, table-tbody',
                    'table-thead': 'heading@4',
                    'table-tbody': 'table-row-divider@4',
                    'table-row-divider': 'table-row, divider',
                    'table-row': 'table-cell@7',
                    'table-cell': 'text',
                }
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get(`${env.MEDCHAT_API_URL}/api/meeting-requests`)
                    .then(response => {
                        this.requests = _.orderBy(response.data, ['id']);
                    })
                    .finally(() => {
                        this.busy = false;
                    })
            }
        }
    }
</script>
