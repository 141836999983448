<template>
    <v-container>

        <v-card>
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    Sales Rep
                    <v-btn @click.prevent="fetchData"
                           text
                           right
                           v-if="!busy">
                        <v-icon>
                            <!-- mdi-refresh -->
                            {{ mdiRefresh }}
                        </v-icon>
                    </v-btn>
                    <v-progress-circular v-if="busy"
                                         class="ml-5"
                                         size="20"
                                         indeterminate>
                    </v-progress-circular>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="rep">
                <v-simple-table>
                    <tbody>
                    <tr>
                        <th>
                            Name
                        </th>
                        <td>
                            {{ rep.name }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Email
                        </th>
                        <td>
                            {{ rep.email }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Region
                        </th>
                        <td>
                            {{ rep.region_name }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            District
                        </th>
                        <td>
                            {{ rep.district_name }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            RDT
                        </th>
                        <td>
                            {{ rep.rdt }}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Territory
                        </th>
                        <td>
                            <span>{{ rep.territory_id }}</span>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>

        <v-card>
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    Zip Codes
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="rep">
                <v-simple-table>
                    <thead>
                    <tr>
                        <th>
                            Zip Code
                        </th>
                        <th>
                            City
                        </th>
                        <th>
                            State
                        </th>
                        <th>
                            Team
                        </th>
                        <th>
                            Roster Date
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="zt in rep.zipTerritories"
                        :key="zt.id">
                        <td>
                            {{ zt.zip_code }}
                        </td>
                        <td>
                            {{ zt.city }}
                        </td>
                        <td>
                            {{ zt.state }}
                        </td>
                        <td>
                            {{ zt.team }}
                        </td>
                        <td>
                            {{ zt.roster_date | datetime('YYYY-MM-DD') }}
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import formValidation from '../../plugins/formValidation';
    // @see https://github.com/cretueusebiu/vform/issues/97
    // @see https://github.com/therealparmesh/object-to-formdata
    import {objectToFormData} from 'object-to-formdata';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';
    import { mdiRefresh } from '@mdi/js';

    export default {
        mixins: [formValidation],
        data() {
            return {
                mdiRefresh,
                busy: false,
                rep: null,
                types: {
                    //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                    'table': 'table-thead, table-tbody',
                    'table-thead': 'heading@4',
                    'table-tbody': 'table-row-divider@4',
                    'table-row-divider': 'table-row, divider',
                    'table-row': 'table-cell@4',
                    'table-cell': 'text',
                }
            }
        },
        computed: {
            repID() {
                return this.$route.params.repID;
            },
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get(`${env.MEDCHAT_API_URL}/api/reps/${this.repID}`)
                    .then(response => {
                        this.rep = response.data;
                    })
                    .finally(() => {
                        this.busy = false;
                    })
            },
        }
    }
</script>
