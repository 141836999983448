<template>
    <v-container class="pt-10">

        <div class="d-flex align-start">
            <!-- <brand-logo class="mr-10">
            </brand-logo> -->

            <div class="flex Wizard__Topic_Align_Center">
                <v-form @submit.prevent="submit"
                        ref="form">
                    <v-card outlined
                            flat
                            tile>
                        <v-toolbar color="primary"
                                   flat
                                   tile
                                   dark class="height-50">
                            <v-toolbar-title class="Word-Break-Text">
                                <strong>Step 1:</strong> Please Select the medChat Topic
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pt-7 pb-0">
                            <v-select v-model="form.topic_id"
                                      :items="topics"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      single-line
                                      dense
                                      label="Select Topic"
                                      :rules="[rules.required]"
                                      required>
                                <template v-slot:item="slotProps">
                                    <div v-html="slotProps.item.clean_name">
                                    </div>
                                </template>
                                <template v-slot:selection="slotProps">
                                    <div v-html="slotProps.item.clean_name">
                                    </div>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>
                        </v-card-text>
                        <v-card-actions class="px-4 pt-0 pb-4 mt-12 flex-column">
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   x-large
                                   depressed
                                   tile
                                   block>
                                Next
                            </v-btn>
                            <v-btn v-if="isRep || isAdmin"
                                   :to="{name: 'about'}"
                                   exact
                                   color="#414141"
                                   class="ml-0 font-weight-bold"
                                   large
                                   text
                                   block>
                                Go Back
                            </v-btn>
                            <v-btn v-else
                                   :to="{name: 'home'}"
                                   exact
                                   color="#414141"
                                   class="ml-0 font-weight-bold"
                                   large
                                   text
                                   block>
                                Go Back
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <v-alert type="error"
                         v-if="error"
                         class="my-4">
                    {{ error }}
                </v-alert>
            </div>
        </div>

    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import BrandLogo from '../../components/BrandLogo';
import dataService from '../../services/dataService';
import formValidation from '../../plugins/formValidation';
import _ from 'lodash';
import SelectIcon from '../../components/forms/SelectIcon';
import speakerService from '../../services/speakerService';

export default {
    components: {SelectIcon, BrandLogo},
    mixins: [formValidation],
    data() {
        return {
            busy: false,
            error: null,
            form: new Form({
                topic_id: '',
            }),
            topics: [],
        }
    },
    computed: {
        ...mapGetters([
            'wizard',
            'isAdmin',
            'isHCP',
            'isRep',
            'user',
            'brands',
        ]),
        topic() {
            return _.find(this.topics, t => t.id === this.form.topic_id);
        }
    },
    created() {
        if (this.wizard) {
            if (this.wizard.topic) {
                this.form.topic_id = this.wizard.topic.id;
            }
            this.fetchData();
            return;
        }
        this.$root.startWizard();
    },
    methods: {
        fetchData() {
            //new change
            // dataService.getTopics(this.$root.brandCode)
            //     .then(data => {
            //         this.topics = data;
            //     })

            // TODO: Update this so that it pulls brand codes from the state object instead of some
            // random variable in the dataService 
            dataService.getMultipleTopics(dataService.BRAND_CODES)
                .then(data => {
                    this.topics = _.orderBy(data, 'id');
                })
        },
        submit() {
            this.error = null;
            this.validateForm();
            if (!this.form.topic_id) {
                return;
            }
            speakerService.getSpeakersForTopic(this.form.topic_id)
                .then(data => {
                    if (data.length === 0) {
                        this.error = 'There are no speakers associated with that topic. Contact the mChat administrator.';
                        return;
                    }
                    this.$store.commit('SET_WIZARD_TOPIC', this.topic);
                    // console.log('before dispatch fetch', this.topic.brand_id);
                    let brand = _.find(this.brands, b => b.id === this.topic.brand_id);
                    // console.log('brand:');
                    // console.log(brand);
                    this.$store.dispatch('fetchBrand', brand.code);
                    this.$store.commit('SET_WIZARD_BRAND', brand);
                    this.$router.push({name: 'wizard.schedule'});
                })
        }
    }
}
</script>
