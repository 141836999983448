import Vue from 'vue';
// import accounting from './accounting';
import pluralize from 'pluralize';

// Vue.filter('money', function(number, precision = 2, symbol = '$') {
//   return accounting.formatMoney(number, symbol, precision)
// });

Vue.filter('pluralize', function(number, word, inclusive = true) {
  return pluralize(word, number, inclusive)
});
