import axios from 'axios'
import * as types from '../mutation-types'
import dataService from '../../services/dataService'
import env from '../../../../config/dev.env';

// state
export const state = {
    authenticated: false,
    user: null,
    accessToken: null,
};

// mutations
export const mutations = {


    [types.FETCH_USER_SUCCESS](state, {user}) {
        state.authenticated = true;
        state.user = user
        // localStorage.setItem('user', JSON.stringify(user));
    },

    [types.FETCH_USER_FAILURE](state) {
        state.user = null;
        state.accessToken = null;
    },

    [types.LOGOUT](state) {
        state.authenticated = false;
        state.user = null;
        window.localStorage.clear();
        window.sessionStorage.clear();
    },

    [types.UPDATE_USER](state, {user}) {
        state.user = user
    },

    [types.SET_ACCESS_TOKEN](state, token) {
        // console.log('SET_ACCESS_TOKEN');
        // console.log(token);
        state.accessToken = token;
    }
};

// actions
export const actions = {

    async fetchUser({commit, state}) {
        try {
            if(!axios.defaults.headers.common.Authorization && state.accessToken) {
                axios.defaults.headers.common = {'Authorization': `Bearer ${state.accessToken}`};
                // console.log('Set bearer token from state');
            }
            else {
                console.log('Issue with access token');
            }
            // console.log(axios.defaults.headers.common);
            const {data} = await axios.get(`${env.MEDCHAT_API_URL}/api/user`);
            // console.log(data);
            commit(types.FETCH_USER_SUCCESS, {user: data})

            return data;
        } catch (e) {
            commit(types.FETCH_USER_FAILURE)
            return null;
        }
    },
    async setAccessToken({commit}, token) {
        // console.log('setting user token');
        // console.log(token);
        
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`};
        commit(types.SET_ACCESS_TOKEN, token)
        
        return true;
    },

    async updateUser({commit}, payload) {
        commit(types.UPDATE_USER, payload)
    },

    async logout({commit}) {
        try {
            commit(types.SET_ACCESS_TOKEN, null);
            axios.defaults.headers.common.Authorization = null;
            await axios.post(`${env.MEDCHAT_API_URL}/logout`);
            
            location.assign('/'); // MSPR-29 redirect the now-anonymous viewer to the home page.
        } catch (e) {
            console.log('error logging out' + e);
        }

        commit(types.LOGOUT)
    }
};

// getters
export const getters = {
    user: state => state.user,
    authenticated: state => state.authenticated,
    isAdmin: state => state.user && state.user.is_admin,
    isHCP: state => state.user && state.user.is_hcp,
    isRep: state => state.user && state.user.is_rep,
    verified: state => state.user && !!state.user.email_verified_at,
    accessToken: state => state.accessToken,
    // isPasswordSet: state => state.user && state.user.
};
