<template>
    <v-container fluid class="mt-6 px-8">
        <!-- <brand-logo class="mb-4">
        </brand-logo> -->
        
        <v-card flat
                tile>
            <v-toolbar color="primary"
                       dark
                       flat
                       tile class="height-50">
                <v-toolbar-title>
                    My Programs
                </v-toolbar-title>
            </v-toolbar>

            <!-- Mobile Table -->
            <div class="px-1 py-3 text-black d-lg-none">
                <v-skeleton-loader v-if="busy"
                                   type="table"
                                   :types="types">
                </v-skeleton-loader>
                <template v-if="meetings.length">
                    <v-container>
                        <v-row no-gutters v-for="meeting in meetings"
                        :key="meeting.id"
                        class="mb-8">
                            
                            <!-- Meeting Column -->
                            <v-col cols="4" class="mb-1" >
                                <v-row no-gutters class="mb-1">
                                    <v-col cols="12" class="font-weight-medium meeting-col-heading">
                                        Meeting ID
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        {{ meeting.id }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            
                            <!-- Status Column -->
                            <v-col cols="4"class="mb-1" >
                                <v-row no-gutters class="mb-1">
                                    <v-col cols="12" class="font-weight-medium meeting-col-heading">
                                        Status
                                    </v-col>
                                </v-row>
                                <v-row no-gutters >
                                    <v-col cols="12">
                                        <router-link :to="{name: 'meeting.show', params: {meetingID: meeting.id}}"
                                             v-if="meeting.meeting_scheduled_at && meeting.host_id"
                                             class="text-decoration-underline">
                                            {{ meeting.status }}
                                        </router-link>
                                        <div v-else>
                                            {{ meeting.status }}
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            
                            <!-- Date/Time Column -->
                            <v-col cols="4" class="mb-1">
                                <v-row no-gutters class="mb-1">
                                    <v-col cols="12" class="font-weight-medium meeting-col-heading">
                                        Date/Time
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <div class="text-no-wrap" v-if="meeting.meeting_scheduled_at">
                                    {{ meeting.meeting_scheduled_at | datetime('MM/DD/YYYY') }} <br/>
                                    {{ meeting.meeting_scheduled_at | datetime('h:mm') }} <span class="text-small-caps">{{ meeting.meeting_scheduled_at | datetime('a') }}</span> ET
                                    </div>
                                <div v-else
                                     class="text-no-wrap">
                                    <div v-if="meeting.appointment1">
                                        {{ meeting.appointment1 | datetime('MM/DD/YYYY') }} <br/>
                                        {{ meeting.appointment1 | datetime('h:mm') }} <span class="text-small-caps">{{ meeting.appointment1 | datetime('a') }}</span> ET
                                    </div>
                                    <div v-if="meeting.appointment2">
                                        {{ meeting.appointment2 | datetime('MM/DD/YYYY') }} <br/>
                                        {{ meeting.appointment2 | datetime('h:mm') }}<span class="text-small-caps">{{ meeting.appointment2 | datetime('a') }}</span> ET
                                    </div>
                                    <div v-if="meeting.appointment3">
                                        {{ meeting.appointment3 | datetime('MM/DD/YYYY') }} <br/>
                                        {{ meeting.appointment3 | datetime('h:mm') }}<span class="text-small-caps">{{ meeting.appointment3 | datetime('a') }}</span> ET
                                    </div>
                                </div>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <!-- Type Column -->
                            <v-col cols="4">
                                <v-row no-gutters class="mb-1">
                                    <v-col cols="12" class="font-weight-medium meeting-col-heading">
                                        Type
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <v-img v-if="meeting.is_source_portal"
                                       src="/images/small-medchat.png"
                                       width="58"
                                       height="32"
                                       contain
                                       alt="mChat icon"/>

                                <v-img v-if="meeting.is_source_api"
                                       src="/images/small-medchat.png"
                                       width="58"
                                       height="32"
                                       contain
                                       alt="mChat icon"/>
                                    </v-col>
                                </v-row>
                            </v-col>
                            
                            <!-- Topic Column -->
                            <v-col cols="4" >
                                <v-row no-gutters class="mb-1">
                                    <v-col cols="12" class="font-weight-medium meeting-col-heading" >
                                        Topic
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        {{ meeting.topic.name }}
                                    </v-col>
                                </v-row>
                            </v-col>
                            
                            <!-- Speaker Column -->
                            <v-col cols="4" >
                                <v-row no-gutters class="mb-1">
                                    <v-col cols="12" class="font-weight-medium meeting-col-heading">
                                        Speaker
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <div>
                                    <div v-if="meeting.speaker">
                                        {{ meeting.speaker.name }}
                                    </div>
                                    <div v-else>
                                        <div v-for="ms in meeting.meetingSpeakers"
                                             :key="ms.speaker_id"
                                             class="mb-1">
                                            {{ ms.speaker.full_name_designation }}
                                        </div>
                                    </div>
                                </div>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                    </v-container>
                </template>
            </div>
            <!-- Desktop Table -->
            <div class="px-7 py-3 text-black d-none d-lg-block">
                <v-skeleton-loader v-if="busy"
                                   type="table"
                                   :types="types">
                </v-skeleton-loader>
                <v-simple-table v-if="!busy" class="text-wrap ">
                    <thead>
                    <tr>
                        <th class="text-no-wrap">
                            Meeting ID
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Date/Time
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Topic
                        </th>
                        <th>
                            Speaker
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="meetings.length">
                        <tr v-for="meeting in meetings"
                            :key="meeting.id">
                            <td>
                                {{ meeting.id }}
                            </td>
                            <td>
                                <router-link :to="{name: 'meeting.show', params: {meetingID: meeting.id}}"
                                             v-if="meeting.meeting_scheduled_at && meeting.host_id"
                                             class="text-decoration-underline">
                                    {{ meeting.status }}
                                </router-link>
                                <div v-else>
                                    {{ meeting.status }}
                                </div>
                            </td>
                            <td class="text-nowrap">
                                <div class="text-no-wrap" v-if="meeting.meeting_scheduled_at">
                                    {{ meeting.meeting_scheduled_at | datetime('MM/DD/YYYY h:mm') }} <span class="text-small-caps">{{ meeting.meeting_scheduled_at | datetime('a') }}</span> ET
                                </div>
                                <div v-else
                                     class="text-no-wrap">
                                    <div v-if="meeting.appointment1">
                                        {{ meeting.appointment1 | datetime('MM/DD/YYYY h:mm') }} <span class="text-small-caps">{{ meeting.appointment1 | datetime('a') }}</span> ET
                                    </div>
                                    <div v-if="meeting.appointment2">
                                        {{ meeting.appointment2 | datetime('MM/DD/YYYY h:mm') }} <span class="text-small-caps">{{ meeting.appointment2 | datetime('a') }}</span> ET
                                    </div>
                                    <div v-if="meeting.appointment3">
                                        {{ meeting.appointment3 | datetime('MM/DD/YYYY h:mm') }} <span class="text-small-caps">{{ meeting.appointment3 | datetime('a') }}</span> ET
                                    </div>
                                </div>
                            </td>
                            <td>
                                <v-img v-if="meeting.is_source_portal"
                                       src="/images/small-medchat.png"
                                       width="58"
                                       height="32"
                                       contain
                                       alt="mChat icon"/>

                                <v-img v-if="meeting.is_source_api"
                                       src="/images/small-medchat.png"
                                       width="58"
                                       height="32"
                                       contain
                                       alt="mChat icon"/>
                            </td>
                            <td style="width: 25%;">
                                {{ meeting.topic.name }}
                            </td>
                            <td>
                                <div class="py-2">
                                    <div v-if="meeting.speaker">
                                        {{ meeting.speaker.name }}
                                    </div>
                                    <div v-else>
                                        <div v-for="ms in meeting.meetingSpeakers"
                                             :key="ms.speaker_id"
                                             class="text-no-wrap">
                                            {{ ms.speaker.full_name_designation }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-if="!busy && !meetings.length"
                              v-for="n in 8">
                        <tr>
                            <td>
                                {{ (1000 + n )}}
                            </td>
                            <td>
                                [STATUS]
                            </td>
                            <td>
                                [Date, Time]
                            </td>
                            <td>
                                <v-img v-if="n === 0 || n % 2 === 0"
                                       src="/images/small-medchat.png"
                                       width="58"
                                       height="32"
                                       contain
                                       alt="mChat icon"/>
                                <v-img v-else
                                       src="/images/small-medchat.png"
                                       width="58"
                                       height="32"
                                       contain
                                       alt="mChat icon"/>
                            </td>
                            <td>
                                [TOPIC NAME]
                            </td>
                            <td>
                                [SPEAKER NAME]
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </v-simple-table>
            </div>
        </v-card>

    </v-container>
</template>
<script>
import axios from 'axios';
import find from 'lodash/find';
import BrandLogo from '../../components/BrandLogo';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';

export default {
    components: {BrandLogo},
    data() {
        return {
            busy: false,
            meetings: [],
            speakers: [],
            types: {
                //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                'table': 'table-thead, table-tbody',
                'table-thead': 'heading@6',
                'table-tbody': 'table-row-divider@6',
                'table-row-divider': 'table-row, divider',
                'table-row': 'table-cell@6',
                'table-cell': 'text',
            }
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            this.busy = true;

            const {data: speakers} = await axios.get(`${env.MEDCHAT_API_URL}/api/speakers`);
            this.speakers = speakers;

            //const userData = JSON.parse(localStorage.user);

            const {data: meetings} = await axios.get(`${env.MEDCHAT_API_URL}/api/meetings/rep`);
            this.meetings = meetings;
            this.meetings.forEach(m => {
                m.meetingSpeakers.forEach(ms => {
                    ms.speaker = find(this.speakers, s => s.id === ms.speaker_id)
                })
            })
            this.busy = false;
        },
    }
}
</script>

