<template>
    <v-container v-if="meeting && user"
                 class="pt-9">
        <!-- <brand-logo :max-width="281"
                    class="mb-7">
        </brand-logo> -->
        <v-row>
            <v-col cols="12"
                   class="pt-0">
                <v-card class="Meeting__Card">
                    <v-toolbar color="primary"
                               flat
                               tile
                               dark class="height-50">
                        <v-toolbar-title>
                            {{ meeting.topic.name }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>

                        <div class="d-flex">

                            <div class="mr-4">
                                <v-avatar size="137">
                                    <img :src="speakerImage"
                                         alt="Speaker headshot"/>
                                </v-avatar>
                            </div>
                            <v-row>
                                <v-col cols="12"
                                       sm="4">

                                    <div class="font-weight-bold text-uppercase">
                                        Speaker
                                    </div>
                                    <div class="mb-4">
                                        <div v-if="meeting.speaker">
                                            {{ meeting.speaker.name }}, {{ meeting.speaker.professional_designation }}
                                        </div>
                                    </div>

                                </v-col>
                                <v-col cols="12"
                                       sm="8">
                                    <div class="font-weight-bold text-uppercase">
                                        Date
                                    </div>
                                    <div class="mb-4">
                                        <div v-if="meeting.meeting_scheduled_at">
                                            {{ meeting.meeting_scheduled_at | datetime('dddd, MMMM D, YYYY') }}
                                        </div>
                                    </div>

                                    <div class="font-weight-bold text-uppercase">
                                        Time
                                    </div>
                                    <div v-if="meeting.meeting_scheduled_at">
                                        <meeting-time :meeting="meeting"
                                                      timezone="America/New_York">
                                        </meeting-time>
                                        <meeting-time :meeting="meeting"
                                                      timezone="America/Chicago">
                                        </meeting-time>
                                        <meeting-time :meeting="meeting"
                                                      timezone="America/Denver">
                                        </meeting-time>
                                        <meeting-time :meeting="meeting"
                                                      timezone="America/Los_Angeles">
                                        </meeting-time>
                                    </div>
                                    <div v-else>
                                        TBD
                                    </div>
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="isHCP"
                             class="mt-4">
                            <div v-if="attendingHCP">
                                <v-alert type="success"
                                         dense
                                         color="secondary"
                                         class="my-0">
                                    You are registered for this event.
                                </v-alert>
                            </div>
                            <div v-else>

                                <v-btn color="secondary"
                                       tile
                                       block
                                       dark
                                       depressed
                                       class="font-weight-bold text-no-transform"
                                       @click.prevent="attend">
                                    REGISTER TO ATTEND THIS mCHAT
                                </v-btn>
                            </div>
                        </div>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="canAdd">
            <v-col cols="12"
                   sm="6">
                <meeting-sales-rep-card :meeting="meeting"
                                        @updated="fetchData">
                </meeting-sales-rep-card>

            </v-col>

            <v-col cols="12"
                   sm="6">
                <meeting-hcps-card :meeting="meeting">
                </meeting-hcps-card>
            </v-col>
        </v-row>

        <v-row v-if="canAdd">
            <v-col cols="12">
                <add-hcps-card :meeting="meeting"
                               @updated="fetchData">
                </add-hcps-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog"
                  persistent
                  width="600">
            <v-card flat
                    tile>
                <v-toolbar color="primary"
                           flat
                           tile
                           dark class="height-50">
                    <v-toolbar-title>
                        mChat Meeting Registration Received
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="text-center py-4">
                    <div>
                        You are registered for this event.
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary"
                           class="font-weight-bold"
                           tile
                           depressed
                           large
                           block
                           @click.prevent="dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import MeetingTime from '../../components/meetings/MeetingTime';
import HcpRow from '../../components/meetings/HcpRow';
import SalesRepRow from '../../components/meetings/SalesRepRow';
import MeetingSalesRepCard from '../../components/meetings/MeetingSalesRepCard';
import MeetingHcpsCard from '../../components/meetings/MeetingHCPsCard';
import AddHcpsCard from '../../components/meetings/AddHCPsCard';
import BrandLogo from '../../components/BrandLogo';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';

export default {
    components: {BrandLogo, AddHcpsCard, MeetingHcpsCard, MeetingSalesRepCard, SalesRepRow, HcpRow, MeetingTime},
    data() {
        return {
            busy: false,
            dialog: false,
            meeting: null,
        }
    },
    computed: {
        ...mapGetters([
            'isHCP',
            'user',
        ]),
        alreadyAttending() {
            if (this.user && this.user.is_rep || this.user.is_admin) {
                if (this.meeting.rep_id === this.user.mchat_rep_id) {
                    return true;
                }
                if (this.meeting.meetingSalesReps.map(r => r.rep_id).includes(this.user.mchat_rep_id)) {
                    return true;
                }
            }
            if (this.meeting.meetingHCPs.map(h => h.hcp_id).includes(this.user.mchat_hcp_id)) {
                return true;
            }
            return false;
        },
        attendingHCP() {
            if (this.isHCP && this.meeting.hcp_ids.includes(this.user.mchat_hcp_id)) {
                return true;
            }
            return false;
        },
        canAdd() {
            return this.user && (this.user.is_rep || this.user.is_admin);
        },
        meetingID() {
            return parseInt(this.$route.params.meetingID);
        },
        speakerImage() {
            if (this.meeting.speaker && this.meeting.speaker.headshot_url) {
                return this.meeting.speaker.headshot_url;
            }
            return '/images/gravatar.png';
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            if (this.busy) {
                return;
            }
            this.busy = true;
            axios.get(`${env.MEDCHAT_API_URL}/api/meetings/${this.meetingID}`)
                .then(response => {
                    this.meeting = response.data;
                })
                .finally(() => {
                    this.busy = false;
                })
        },
        attend() {
            if (!this.isHCP) {
                return;
            }
            //this.form.startProcessing();
            let form = {
                hcp_ids: [this.user.mchat_hcp_id],
            };
            axios.post(`${env.MEDCHAT_API_URL}/api/meetings/${this.meeting.id}/add-current-hcp`, form)
                .then(response => {
                    this.fetchData();
                    this.dialog = true;
                })
                .catch(err => {
                    //this.form.setErrors(err.response.data);
                })
        }
    }
}
</script>
