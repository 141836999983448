<template>
    <v-container>

        <v-card>
            <v-toolbar color="secondary"
                       dark
                       extended>
                <v-toolbar-title>
                    Users
                </v-toolbar-title>
                <template v-slot:extension>
                    <v-btn fab
                           color="accent"
                           bottom
                           right
                           absolute
                           title="Add User"
                           :to="{name: 'user.create'}">
                        <v-icon>
                            <!-- mdi-plus -->
                            {{ mdiPlus }}
                        </v-icon>
                    </v-btn>
                </template>
            </v-toolbar>
            <v-card-text>

                <v-skeleton-loader v-if="busy"
                                   type="table"
                                   :types="types">

                </v-skeleton-loader>

                <v-simple-table v-if="!busy">
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Email Verified
                        </th>
                        <th>
                            Password Set
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="user in users"
                        :key="user.id">
                        <td>
                            <router-link :to="{name: 'user.edit', params: {userID: user.id}}">
                                {{ user.id }}
                            </router-link>
                        </td>
                        <td>
                            <router-link :to="{name: 'user.edit', params: {userID: user.id}}">
                                {{ user.name }}
                            </router-link>
                        </td>
                        <td>
                            {{ user.email }}
                        </td>
                        <td>
                            {{ user.type }}
                        </td>
                        <td>
                            {{ user.email_verified_at ? 'Yes' : 'No' }}
                        </td>
                        <td>
                            {{ user.is_password_set ? 'Yes' : 'No' }}
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import axios from 'axios';
    import _ from 'lodash';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';
    import { mdiPlus } from '@mdi/js';
    export default {
        data() {
            return {
                mdiPlus,
                busy: false,
                types: {
                    //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                    'table': 'table-thead, table-tbody',
                    'table-thead': 'heading@6',
                    'table-tbody': 'table-row-divider@6',
                    'table-row-divider': 'table-row, divider',
                    'table-row': 'table-cell@5',
                    'table-cell': 'text',
                },
                users: [],
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get(`${env.MEDCHAT_API_URL}/api/users`)
                    .then(response => {
                        this.users = _.orderBy(response.data, ['slug']);
                    })
                    .finally(() => {
                        this.busy = false;
                    })
            }
        }
    }
</script>
