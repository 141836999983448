<template>
    <div class="MeetingListing">
        <v-row>
            <v-col cols="2">
                <meeting-time :meeting="meeting">
                </meeting-time>
            </v-col>
            <v-col cols="5"
                   class="d-flex pl-6">
                <div class="MeetingListing__Type mr-4">
                    <v-img :src="icon"
                           :alt="meeting.is_source_portal ? 'Pre-scheduled by X360' : `${meeting.brand.code} meeting, scheduled by a rep`"
                           width="60"/>
                </div>
                <div class="MeetingListing__Topic mr-4 flex">
                    {{ meeting.topic.name }}
                </div>
            </v-col>
            <v-col cols="3">
                <div class="MeetingListing__Speaker"
                     v-if="meeting.speaker">
                    {{ meeting.speaker.name }}
                </div>
            </v-col>
            <v-col cols="2"
                   class="d-flex justify-end">
                <v-btn color="secondary"
                       :to="{name: 'meeting.show', params: {meetingID: meeting.id}}"
                       v-if="user && (user.is_admin || user.is_rep)">
                    Register HCPs
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import MeetingTime from './MeetingTime';

export default {
    components: {MeetingTime},
    props: {
        meeting: {
            type: Object,
            required: true,
        }
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        icon() {
            if (this.meeting.is_source_portal) {
                return `/images/mchat_icon.png`;
            }
            return `/images/${this.meeting.brand.code}_mchat_icon.png`;
        }
    }
}
</script>
