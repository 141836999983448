<template>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-title @click.prevent="$emit('select', hcp)">
                {{ hcpName }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="hcp.email">
                Email: {{ hcp.email }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="hcp.npi">
                NPI: {{ hcp.npi }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="hcp.phone_formatted">
                Phone: {{ hcp.phone_formatted }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="address && address.city && address.state ">
                {{ address.city }}, {{ address.state }}
            </v-list-item-subtitle>
            <div class="text-caption"
                 v-if="!isProduction">
                <a href="#"
                   @click.prevent="expanded = !expanded">
                    Toggle
                </a> (for testing only, will not appear in production)
            </div>
            <pre v-if="expanded">{{ hcp }}</pre>
        </v-list-item-content>
        <v-list-item-action @click.prevent="$emit('select', hcp)">
            <img src="/images/add_hcp_icon.png"
                 alt="Add HCP"/>
        </v-list-item-action>
    </v-list-item>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
    name: 'sanofi-hcp-list-item',
    props: {
        hcp: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            expanded: false,
        }
    },
    computed: {
        ...mapGetters([
            'isProduction',
        ]),
        hcpName() {
            return this.hcp.full_name_designation;
        },
        address() {
            return this.hcp.bestAddress;
        }
    },
    methods: {}
}
</script>
