<template>
    <v-container class="pt-10">

        <!--<v-card outlined
                class="Resources__Card mb-8">
            <v-toolbar color="primary"
                       flat
                       tile
                       dark>
                <v-toolbar-title>
                    Resources for US healthcare professionals
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>

                <support-dialog>
                </support-dialog>

            </v-card-text>
        </v-card>-->

        <download-app-card>
        </download-app-card>


    </v-container>
</template>
<script>

    import SupportDialog from '../../components/resources/SupportDialog';
    import DownloadAppCard from '../../components/resources/DownloadAppCard';
    export default {
        components: {DownloadAppCard, SupportDialog},
        computed: {}
    }
</script>
