<template>
    <v-container>

        <v-form @submit.prevent="upload"
                ref="upload"
                class="mb-8">
            <v-card>
                <v-toolbar color="secondary"
                           dark>
                    <v-toolbar-title>
                        Upload Speakers JSON
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>

                    <v-file-input v-model="form.file"
                                  label="Speakers JSON"
                                  hint="Must be a JSON file"
                                  persistent-hint
                                  :rules="[rules.required]"
                                  :error-messages="form.errors.get('file')"
                                  accept="application/json">
                    </v-file-input>

                </v-card-text>
                <v-card-actions class="px-5">
                    <v-btn type="submit"
                           color="secondary"
                           :disabled="!form.file || form.busy">
                        Upload
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

        <v-card>
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    Speakers
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>

                <v-skeleton-loader v-if="busy"
                                   type="table"
                                   :types="types">

                </v-skeleton-loader>

                <v-btn @click.prevent="downloadJSON"
                       v-if="!busy"
                       color="primary">
                    Download JSON
                </v-btn>

                <v-simple-table v-if="!busy">
                    <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Speaker Type
                        </th>
                        <th>
                            Topics
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="speaker in speakers"
                        :key="speaker.id">
                        <td>
                            {{ speaker.full_name }}
                        </td>
                        <td>
                            {{ speaker.type }}
                        </td>
                        <td>
                            <ul v-for="topic in speaker.topics"
                                v-if="speaker.topics && speaker.topics.length"
                                :key="topic.id">
                                {{ topic.name }}
                            </ul>

                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import axios from 'axios';
    import _ from 'lodash';
    import formValidation from '../../plugins/formValidation';
    // @see https://github.com/cretueusebiu/vform/issues/97
    // @see https://github.com/therealparmesh/object-to-formdata
    import {objectToFormData} from 'object-to-formdata';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';

    export default {
        mixins: [formValidation],
        data() {
            return {
                busy: false,
                form: new Form({
                    file: null,
                }),
                speakers: [],
                types: {
                    //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                    'table': 'table-thead, table-tbody',
                    'table-thead': 'heading@3',
                    'table-tbody': 'table-row-divider@3',
                    'table-row-divider': 'table-row, divider',
                    'table-row': 'table-cell@5',
                    'table-cell': 'text',
                },
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get(`${env.MEDCHAT_API_URL}/api/speakers`)
                    .then(response => {
                        this.speakers = _.orderBy(response.data, ['slug']);
                    })
                    .finally(() => {
                        this.busy = false;
                    })
            },
            downloadJSON() {
                axios.get(`${env.MEDCHAT_API_URL}/api/speakers`)
                    .then(response => {
                        let speakers = _.orderBy(response.data, ['slug']);
    
                        // Create JSON String
                        let json = JSON.stringify(speakers);
                        
                        // Create Blob
                        json = [json];
                        let blob1 = new Blob(json, {type: "text/plain;charset=utf-8"})
                        
                        // Check Browser then download file speakers.json
                        var isIE = false || !!document.documentMode;
                        console.log(isIE);
                        if (isIE)  {
                            window.navigator.msSaveBlob(blob1, "speakers.json");
                        } else {
                            let url = window.URL || window.webkitURL;
                            let link = url.createObjectURL(blob1);
                            let a = document.createElement("a");
                            a.download = "speakers.json";
                            a.href = link;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                        }
                        
                    })
                    .finally(() => {
                        this.busy = false;
                    })
            },
            upload() {
                if (!this.validateForm('upload')) {
                    return;
                }
                if (this.form.busy || this.busy) {
                    return;
                }
                // @see https://github.com/cretueusebiu/vform/issues/97
                // @see https://github.com/therealparmesh/object-to-formdata
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    transformRequest: [data => objectToFormData(data)]
                };
                this.busy = true;
                this.form.post(`${env.MEDCHAT_API_URL}/api/speakers/upload`, config)
                    .then(response => {
                        this.fetchData();
                        this.resetFormValidation('upload');
                        this.form.reset();
                    })
            }
        }
    }
</script>
