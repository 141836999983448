import axios from 'axios'
import store from '../store'
import router from '../router'
import i18n from './vue-i18n'

window.axios = axios;
axios.defaults.withCredentials = true; // For Laravel Sanctum
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axios.interceptors.response.use(response => response, error => {
    
    // console.log(error.response);
    const {status} = error.response;

    if (status >= 500) {

    }
    // Validation failurs from laravel requests will return 422
    // this json object needs to be passed through in order to provide messages
    if (status === 422) {
        return Promise.reject(error)
    }

    if (status === 401 && store.getters.authenticated) {
        try {
            store.dispatch('logout')
        } finally {
            console.log('logging out');
            router.push({name: 'login'})
        }
    }

    

    return Promise.reject(error)
});
