<template>
    <v-container>

        <v-card class="mb-4">
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title
                    class="d-flex flex justify-space-between">
                    <div class="flex">
                        <span>
                            Territory
                        </span>
                        <template v-if="territory">
                            {{territory.id }} - {{ territory.name }}
                        </template>
                    </div>
                    <div>
                        <v-btn @click.prevent="fetchData"
                               text
                               right
                               v-if="!busy">
                            <v-icon>
                                <!-- mdi-refresh -->
                                {{ mdiRefresh }}
                            </v-icon>
                        </v-btn>
                        <v-progress-circular v-if="busy"
                                             class="ml-5"
                                             size="20"
                                             indeterminate>
                        </v-progress-circular>
                    </div>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text v-if="territory">

            </v-card-text>
        </v-card>

        <v-card class="mb-4"
                v-if="territory">
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    Sales Reps
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-simple-table v-if="!busy">
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Title
                        </th>
                        <th>
                            Position Type
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="rep in territory.reps"
                        :key="rep.id">
                        <td>
                            <router-link :to="{name: 'admin.show_rep', params: {repID: rep.id}}">
                                {{ rep.id }}
                            </router-link>
                        </td>
                        <td>
                            {{ rep.name }}
                        </td>
                        <td>
                            {{ rep.email }}
                        </td>
                        <td>
                            {{ rep.title }}
                        </td>
                        <td>
                            {{ rep.position_type }}
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

            </v-card-text>
        </v-card>

        <v-card class="mb-4"
                v-if="territory">
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    Zip Codes
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-simple-table v-if="!busy">
                    <thead>
                    <tr>
                        <th>
                            Zip Code
                        </th>
                        <th>
                            Position Type
                        </th>
                        <th>
                            Sales Force Value
                        </th>
                        <th>
                            Sales Force Name
                        </th>
                        <th>
                            Effective Date
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="zt in territory.zipTerritories"
                        :key="zt.id">
                        <td>
                            {{ zt.zip_code }}
                        </td>
                        <td>
                            {{ zt.position_type }}
                        </td>
                        <td>
                            {{ zt.sales_force_value }}
                        </td>
                        <td>
                            {{ zt.sales_force_name }}
                        </td>
                        <td>
                            {{ zt.effective_date | datetime('YYYY-MM-DD') }}
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import formValidation from '../../plugins/formValidation';
    // @see https://github.com/cretueusebiu/vform/issues/97
    // @see https://github.com/therealparmesh/object-to-formdata
    import {objectToFormData} from 'object-to-formdata';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';

    export default {
        mixins: [formValidation],
        data() {
            return {
                busy: false,
                territory: null,
                types: {
                    //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                    'table': 'table-thead, table-tbody',
                    'table-thead': 'heading@4',
                    'table-tbody': 'table-row-divider@4',
                    'table-row-divider': 'table-row, divider',
                    'table-row': 'table-cell@4',
                    'table-cell': 'text',
                }
            }
        },
        computed: {
            territoryID() {
                return this.$route.params.territoryID;
            },
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get(`${env.MEDCHAT_API_URL}/api/territories/${this.territoryID}`)
                    .then(response => {
                        this.territory = response.data;
                    })
                    .finally(() => {
                        this.busy = false;
                    })
            },
        }
    }
</script>
