<template>
    <v-container class="pt-10">
        <h1 class="display-1">
            Page Not Found
        </h1>
    </v-container>
</template>
<script>
    export default {}
</script>
