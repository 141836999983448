<template>
    <v-app class="Layout--Unbranded"
           :class="routeClass">
        <!-- <system-bar>
        </system-bar> -->
        <app-bar :show-navigation="false">
        </app-bar>

        <v-main class="ML__Content">
            <transition name="page"
                        mode="out-in">
                <router-view class="ML__Content_Main"></router-view>
            </transition>
        </v-main>

        <company-footer>
        </company-footer>
    </v-app>
</template>

<script>
import AppBar from '../components/AppBar';
import NavigationDrawer from '../components/NavigationDrawer';
import CompanyFooter from './partials/CompanyFooter';
//import SystemBar from '../components/SystemBar'; // by vigneswaran

export default {
    components: {CompanyFooter, NavigationDrawer, AppBar},
    computed: {
        routeClass() {
            return `${this.$root.theme} Route-${this.$route.name}`;
        }
    }
}
</script>
