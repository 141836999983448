import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

// Load store modules dynamically.
const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )
    .reduce((modules, [name, module]) => {
        modules[name] = typeof module.default === 'undefined' ? module : module.default;
        return modules
    }, {});

// https://github.com/robinvdvleuten/vuex-persistedstate
const stateOptions = {
    fetchBeforeUse: true,
    overwrite: true,
    storage: window.localStorage,
};


const dataState = createPersistedState({
    storage: window.sessionStorage,
    paths: [
        'auth.authenticated',
        'mchat.brand',
        'mchat.wizard',
        'auth.accessToken'
    ]
})

// const sessionSate = createPersistedState({

// })

const actions = {
    async initStore(context) {
        console.time('initStore');
        await context.dispatch('getBlackoutDates'); // MCHAT-449
        // console.log('brand code' + process.env.MCHAT_BRAND_CODE);
        // await context.dispatch('fetchBrand', process.env.MCHAT_BRAND_CODE); // MLYNX-8
        await context.dispatch('fetchBrands')
        
        if (context.getters.authenticated) {
            await context.dispatch('fetchUser');
        }
        
        await context.dispatch('getProfessionalDesignations')
        await context.dispatch('getSpecialties')
        console.timeEnd('initStore');

        return true;
    }
};

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules,
    actions,
    plugins: [
        dataState,
    ],
})
