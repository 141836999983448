<template>
    <v-container class="pt-10">
        <v-row class="mb-4">
            <!-- <v-col cols="12"
                   md="3">
                <brand-logo>
                </brand-logo>
            </v-col> -->
            <v-col cols="12"
                   md="12">
                <v-card outlined
                        class="mb-4">
                    <v-card-text>
                        <div class="mb-2">
                            Please review your medChat meeting request. If everything is correct, click SUBMIT THIS medCHAT REQUEST.
                        </div>
                        <div class="mb-2">
                            Please allow 5 business days for your meeting to be confirmed. Once confirmed, you will receive confirmation and reminder emails for your upcoming medChat.
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-form @submit.prevent="submit"
                ref="form">
            <v-card outlined>
                <v-toolbar color="primary"
                           dark class="height-50">
                    <v-toolbar-title>
                        Review Your medChat Request
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="subtitle-1 pb-0">

                    <v-card flat
                            class="mb-4">
                        <v-toolbar flat
                                   color="grey lighten-3">
                            <v-toolbar-title>
                                Step 1: Topic
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="subtitle-1">
                            <div class="font--size-18 mb-2">
                                The topic will be:
                            </div>
                            <div class="Wizard__Review-Box">
                                <div v-html="wizard.topic.name"
                                     class="Wizard__Review-Box-Row">
                                </div>
                            </div>
                        </v-card-text>
                        <v-card-actions class="px-4">
                            <v-btn color="#d9ecf7"
                                   class="lighten-3 blue--text text--darken-4 font-weight-bold"
                                   :to="{name: 'wizard.topic'}">
                                <v-icon left>
                                    <!-- mdi-pencil -->
                                    {{ mdiPencil }}
                                </v-icon>
                                Change Topic
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card flat
                            class="mb-4">
                        <v-toolbar flat
                                   color="grey lighten-3">
                            <v-toolbar-title class="Word-Break-Text">
                                Step 2: Selected speakers, dates, and times
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="subtitle-1">
                            <div class="font--size-18 mb-4">
                                These speakers will be invited in this order:
                            </div>

                            <div class="Wizard__Review-Box mb-4">
                                <div class="Wizard__Review-Box-Row"
                                     v-for="speaker in wizard.speakers"
                                     :key="speaker.id">
                                    {{ speaker.full_name_designation }}
                                </div>
                            </div>

                            <div class="mb-4">
                                <div class="font--size-18 mb-4">
                                    The medChat will take place at one of these times:
                                </div>

                                <div class="Wizard__Review-Box mb-4">
                                    <div class="Wizard__Review-Box-Row"
                                         v-for="(appointment, index) in wizard.appointments"
                                         :key="index">
                                        <div class="font--size-16 font-weight-bold">
                                            {{ appointmentDate(appointment) }}
                                        </div>
                                        <div class="font--size-16">
                                            {{ appointmentTime(appointment) }}
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </v-card-text>
                        <v-card-actions class="px-4">
                            <v-btn color="#d9ecf7"
                                   class="lighten-3 blue--text text--darken-4 font-weight-bold"
                                   :to="{name: 'wizard.schedule'}">
                                <v-icon left>
                                    <!-- mdi-pencil -->
                                    {{ mdiPencil }}
                                </v-icon>
                                Edit Speakers and Times
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card flat
                            class="mb-4">
                        <v-toolbar flat
                                   color="grey lighten-3">
                            <v-toolbar-title>
                                Step 3: Invite HCPs
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="subtitle-1 pb-0">
                            <div class="font--size-18 mb-2">
                                The following HCPs will be invited to the medChat (at least 3 HCPs must be in attendance):
                            </div>

                            <v-text-field v-for="hcp in wizard.hcps"
                                          :key="hcp.id"
                                          :value="hcp.full_name_designation"
                                          disabled
                                          outlined
                                          single-line
                                          hide-details
                                          class="mb-4">
                            </v-text-field>

                        </v-card-text>
                        <v-card-actions class="px-4">
                            <v-btn color="#d9ecf7"
                                   class="lighten-3 blue--text text--darken-4 font-weight-bold"
                                   :to="{name: 'wizard.hcps'}">
                                <v-icon left>
                                    <!-- mdi-pencil -->
                                    {{  mdiPencil }}
                                </v-icon>
                                Change HCP
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <!--<div class="font&#45;&#45;size-18 px-4">
                        <p class="mb-0">
                            If everything is correct:
                        </p>
                    </div>-->

                </v-card-text>
                <v-card-actions class="flex-column pa-4 justify-center">
                    <v-btn type="submit"
                           v-if="!form.busy"
                           color="secondary"
                           class="font-weight-bold"
                           x-large
                           depressed
                           tile
                           block
                           style="text-transform: none">
                        SUBMIT THIS medCHAT REQUEST
                    </v-btn>
                    <v-progress-circular v-if="form.busy"
                                         indeterminate>
                    </v-progress-circular>

                    <v-alert type="error"
                             class="mt-2"
                             width="100%"
                             v-if="form.errors.any()">
                        There was a problem:
                        <div v-if="error">
                            {{ error }}
                        </div>
                        <div v-for="(error, field) in form.errors.all()">
                            <div class="d-flex">
                                <div class="mr-3 font-weight-bold">
                                    {{ field }}:
                                </div>
                                <div>
                                    {{ error[0] }}
                                </div>
                            </div>
                        </div>
                    </v-alert>
                </v-card-actions>
            </v-card>
        </v-form>

        <v-dialog v-model="dialog"
                  persistent
                  width="600">
            <v-card outlined
                    flat
                    tile>
                <v-toolbar color="primary"
                           dark class="height-50">
                    <v-toolbar-title>
                        medChat Meeting Request Received
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="text-center mt-1 py-4">
                    <div>
                        Thank you for your medChat request.
                    </div>
                    <div>
                        You will receive a confirmation email within 5 business days.
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary"
                           class="font-weight-bold"
                           @click.prevent="close"
                           large
                           tile
                           depressed
                           block>
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import BrandLogo from '../../components/BrandLogo';
import formValidation from '../../plugins/formValidation';
import moment from 'moment-timezone';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';
import { mdiPencil } from '@mdi/js';

export default {
    components: {BrandLogo},
    mixins: [formValidation],
    data() {
        return {
            mdiPencil,
            busy: false,
            dialog: false,
            error: null,
            form: new Form({
                appointments: [],
                brand_code: '',
                hcps: [],
                speaker_ids: [],
                topic_id: '',
            }),
        }
    },
    computed: {
        ...mapGetters([
            'wizard',
            'isHCP',
        ]),
        timezone() {
            return 'America/New_York';
        }
    },
    created() {
        if (this.wizard
            && this.wizard.brand
            && this.wizard.brand.code === this.$root.brandCode
            && this.wizard.topic
            && (this.wizard.hcps.length || this.wizard.speakers.length)
            && this.wizard.speakers.length) {
            return;
        }
        this.$root.startWizard();
    },
    methods: {
        close() {
            this.$store.commit('CLEAR_WIZARD');
            this.$router.replace({name: 'about'});
        },
        appointmentDate(appointment) {
            return moment.tz(appointment.start, this.timezone).format('dddd, MMMM D, YYYY')
        },
        appointmentTime(appointment) {
            return moment.tz(appointment.start, this.timezone).format('h:mm A [ET]') // NNI-44
        },
        submit() {
            if (!this.validateForm()) {
                return;
            }
            this.busy = true;
            this.error = null;

            this.form.appointments = this.wizard.appointments.map(a => a.start);
            
            this.form.brand_code = this.wizard.brand.code;
            
            this.form.hcps = this.wizard.hcps;
            this.form.speaker_ids = this.wizard.speakers.map(s => s.id);
            this.form.topic_id = this.wizard.topic.id;

            // const userData = JSON.parse(localStorage.user);
            // this.form.user = userData;

            this.form.post(`${env.MEDCHAT_API_URL}/api/meetings`)
                .then(({data}) => {
                    this.dialog = true;
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        this.error = err.response.data.message;
                    }
                })
                .finally(() => {
                    this.busy = false;
                })
        }
    }
}
</script>
