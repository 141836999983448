// @see https://itnext.io/anyway-heres-how-to-create-a-multiple-layout-system-with-vue-and-vue-router-b379baa91a05
import Vue from 'vue';
import AdminLayout from './AdminLayout';
import BrandedLayout from './BrandedLayout';
import UnbrandedLayout from './UnbrandedLayout';
import Brand1Footer from './partials/Brand1Footer';

Vue.component('admin-layout', AdminLayout);
Vue.component('branded-layout', BrandedLayout);
Vue.component('unbranded-layout', UnbrandedLayout);

Vue.component('brand1-footer', Brand1Footer);
