<template>
    <v-container>

        <v-form @submit.prevent="upload"
                ref="upload"
                class="mb-8">
            <v-card>
                <v-toolbar color="secondary"
                           dark>
                    <v-toolbar-title>
                        Upload Employee Roster
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>

                    <v-file-input v-model="form.file"
                                  label="Employee Roster"
                                  hint="Must be a TXT file with Pipe delimiters"
                                  persistent-hint
                                  :rules="[rules.required]"
                                  :error-messages="form.errors.get('file')"
                                  accept="text/plain">
                    </v-file-input>

                </v-card-text>
                <v-card-actions class="px-5">
                    <v-btn type="submit"
                           color="secondary"
                           :disabled="!form.file || form.busy">
                        Upload
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

        <v-card>
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    Sales Reps
                    <v-btn @click.prevent="fetchData"
                           text
                           right
                           v-if="!busy">
                        <v-icon>
                            <!-- mdi-refresh -->
                            {{ mdiRefresh }}
                        </v-icon>
                    </v-btn>
                    <v-progress-circular v-if="busy"
                                         class="ml-5"
                                         size="20"
                                         indeterminate>
                    </v-progress-circular>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-skeleton-loader v-if="busy"
                                   type="table"
                                   :types="types">

                </v-skeleton-loader>
                <v-simple-table v-if="!busy">
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            RDT
                        </th>
                        <th>
                            Territory ID
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="rep in reps"
                        :key="rep.id">
                        <td>
                            <router-link :to="{name: 'admin.show_rep', params: {repID: rep.id}}">
                                {{ rep.id }}
                            </router-link>
                        </td>
                        <td>
                            {{ rep.name }}
                        </td>
                        <td>
                            {{ rep.email }}
                        </td>
                        <td>
                            {{ rep.rdt }}
                        </td>
                        <td>
                            {{ rep.territory_id }}
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import formValidation from '../../plugins/formValidation';
    // @see https://github.com/cretueusebiu/vform/issues/97
    // @see https://github.com/therealparmesh/object-to-formdata
    import {objectToFormData} from 'object-to-formdata';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';
    import { mdiRefresh } from '@mdi/js';

    export default {
        mixins: [formValidation],
        data() {
            return {
                mdiRefresh,
                busy: false,
                form: new Form({
                    file: null,
                }),
                reps: [],
                types: {
                    //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                    'table': 'table-thead, table-tbody',
                    'table-thead': 'heading@6',
                    'table-tbody': 'table-row-divider@6',
                    'table-row-divider': 'table-row, divider',
                    'table-row': 'table-cell@6',
                    'table-cell': 'text',
                }
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get(`${env.MEDCHAT_API_URL}/api/reps`)
                    .then(response => {
                        this.reps = response.data;
                    })
                    .finally(() => {
                        // console.log('finally');
                        this.busy = false;
                    })
            },
            upload() {
                if (!this.validateForm('upload')) {
                    return;
                }
                if (this.form.busy || this.busy) {
                    return;
                }
                // @see https://github.com/cretueusebiu/vform/issues/97
                // @see https://github.com/therealparmesh/object-to-formdata
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    transformRequest: [data => objectToFormData(data)]
                };
                this.busy = true;
                this.form.post(`${env.MEDCHAT_API_URL}/api/reps/upload`, config)
                    .then(response => {
                        this.fetchData();
                        this.resetFormValidation('upload');
                        this.form.reset();
                    })
            }
        }
    }
</script>
