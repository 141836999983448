<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12"
                   sm="8"
                   md="6">

                <v-form @submit.prevent="submit">
                    <v-card outlined
                            flat
                            tile>
                        <v-toolbar color="primary"
                                   dark
                                   flat class="height-50">
                            <v-toolbar-title>
                                Set Your Password
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5 pt-5 pb-0">
                            <input type="hidden"
                                   name="email"
                                   :value="user.email"/>

                            <v-text-field v-model="form.password"
                                          type="password"
                                          label="Password"
                                          placeholder=" "
                                          autocomplete="new-password"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('password')"
                                          persistent-hint
                                          class="mb-2"
                                          required>
                            </v-text-field>

                            <v-text-field v-model="form.password_confirmation"
                                          type="password"
                                          label="Confirm password"
                                          placeholder=" "
                                          autocomplete="new-password"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('password_confirmation')"
                                          required>
                            </v-text-field>

                            <p class="caption mb-1">
                                Your password must be at least 8 characters long and contain letters and numbers.
                            </p>

                        </v-card-text>
                        <v-card-actions>
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   :disabled="form.busy"
                                   block
                                   tile
                                   depressed
                                   dark>
                                Set Password
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
                <v-alert type="success"
                         color="#23004c"
                         class="mt-3"
                         v-if="message">
                    <span>
                        {{ message }}
                    </span>
                </v-alert>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import dataService from '../../../services/dataService'
import env from '../../../../../config/dev.env';

export default {
    data() {
        return {
            form: new Form({
                password: '',
                password_confirmation: '',
            }),
            message: '',
        }
    },
    computed: {
        ...mapGetters([
            'isHCP',
            'isPasswordSet',
            'user',
        ]),
    },
    created() {
        if (this.isPasswordSet) {
            if (this.isHCP) {
                this.$router.push({name: 'home'});
            } else {
                this.$router.push({name: 'about'});
            }
        }
    },
    methods: {
        async submit() {
            try {
                // const userData = JSON.parse(localStorage.user);
                // this.form.user = userData;
                let config = {
                    headers: {'content-type': 'application/json;charset=utf-8',
                    'X-Requested-With': 'XMLHttpRequest' }
                }
                const {data} = await this.form.post(`${env.MEDCHAT_API_URL}/api/user/password`, config);
                this.message = data.message;
                
                await this.$store.dispatch('fetchUser');

                if (this.isHCP) {
                    this.$router.push({name: 'home'});
                } else {
                    this.$router.push({name: 'about'});
                }
            } catch (e) {
                if(e.response && e.response.data) {
                    this.errors = e.response.data
                } else {
                    // Log Error Somehow
                }
                // console.log(e);
            }
        }
    }
}
</script>
