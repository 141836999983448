<template>
    <tr v-if="hcp">
        <td>
            {{ hcp.full_name_designation }}
        </td>
        <td>
            <div v-if="hcp.city && hcp.state">
                {{ hcp.city }}, {{ hcp.state }}
            </div>
        </td>
    </tr>
</template>
<script>
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';
export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            hcp: null,
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            axios.get(`${env.MEDCHAT_API_URL}/api/hcps/${this.id}`)
                .then(response => {
                    this.hcp = response.data;
                })
                .catch(err => {

                })
        }
    }
}
</script>
