<template>
    <v-container class="pt-10">

        <div class="d-flex align-start">
            <!-- <brand-logo class="mr-10">
            </brand-logo> -->

            <div class="flex Page-Center Wizard__Search-Hcp-Width">
                <v-form @submit.prevent="search"
                        ref="form">
                    <v-card outlined
                            flat
                            tile>
                        <v-toolbar color="primary"
                                   flat
                                   tile
                                   dark class="height-50">
                            <v-toolbar-title class="Wizard__Search-Title-Mobile">
                                <strong>Step 3:</strong> Which HCPs Will Attend the Program?
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pb-0">

                            <div class="Alert Alert__Info grey white--text pa-4 mb-6">
                                Search for HCPs who are already in our system. If you don't find any matches, click <strong>"Not found - add new HCP."</strong>
                            </div>

                            <v-row>
                                <v-col class="col-12 col-md-6">
                                    <v-text-field v-model="form.first_name"
                                                  label="First name"
                                                  outlined
                                                  :error-messages="form.errors.get('first_name')">
                                    </v-text-field>
                                </v-col>
                                <v-col class="col-12 col-md-6">
                                    <v-text-field v-model="form.last_name"
                                                  label="Last name"
                                                  outlined
                                                  required
                                                  :rules="[rules.required]"
                                                  :error-messages="form.errors.get('last_name')">
                                        <template v-slot:label>
                                            <required-label label="Last name"/>
                                        </template>
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="col-12 col-md-6">
                                    <v-text-field v-model="form.city"
                                                  label="City"
                                                  outlined
                                                  :error-messages="form.errors.get('city')">
                                    </v-text-field>
                                </v-col>
                                <v-col class="col-12 col-md-6">
                                    <v-select v-model="form.state"
                                              label="State"
                                              :items="states"
                                              item-value="id"
                                              item-text="name"
                                              outlined
                                              :menu-props="{offsetY: true}"
                                              :error-messages="form.errors.get('state')">
                                    </v-select>
                                </v-col>
                            </v-row>

                        </v-card-text>
                        <v-card-actions class="pa-4 pb-0 flex-column">
                            <v-btn type="submit"
                                   @click.stop="search"
                                   color="secondary"
                                   class="font-weight-bold"
                                   :disabled="busy"
                                   x-large
                                   tile
                                   block>
                                SEARCH HCPs
                            </v-btn>
                        </v-card-actions>
                        <div class="d-flex justify-center pt-4"
                             v-if="busy">
                            <v-progress-circular indeterminate>
                            </v-progress-circular>
                        </div>
                        <v-card-text v-if="searched || selectedHCPs.length">
                            <template v-if="!hcpBusy">
                                <div style="color: #2c3a61;" class="font--size-24 mb-2">
                                    {{ hcps.length | number }} Matches
                                </div>
                                <v-list class="mb-2 HCP-Search-Results">
                                    <hcp-list-item v-for="hcp in hcps"
                                                   :key="hcp.id"
                                                   :hcp="hcp"
                                                   @addHCP="addHCP">
                                    </hcp-list-item>
                                </v-list>
                            </template>

                            <template>
                                <template v-if="pagination && crmHCPs.length">
                                    <div style="color: #2c3a61;"
                                         class="font--size-24 mb-2">
                                        Found {{ crmHCPs.length | number }} of {{ pagination.total | number }} in Sanofi CRM
                                    </div>
                                    <v-list class="mb-2 HCP-Search-Results">
                                        <sanofi-hcp-list-item v-for="hcp in crmHCPs"
                                                               :key="hcp.id"
                                                               :hcp="hcp"
                                                               @select="addsanofiHCP">
                                        </sanofi-hcp-list-item>
                                    </v-list>
                                </template>
                                <div v-if="busy || crmBusy"
                                     class="d-flex justify-center my-4">
                                    <v-progress-circular indeterminate>
                                    </v-progress-circular>
                                </div>
                                <div v-if="pagination && pagination.total_pages > pagination.current_page"
                                     class="d-flex justify-center my-4">
                                    <v-btn @click.prevent="searchCRM(pagination.current_page + 1)">
                                        Load more
                                    </v-btn>
                                </div>
                            </template>

                            <v-list class="mb-2 HCP-Search-Results"
                                    v-if="!busy && !hcpBusy && !crmBusy && !form.errors.any()">
                                <v-list-item :to="{name: 'wizard.create_hcp', query: {client: 'sanofi'}}">
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold">
                                            Not found - add new HCP
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <img src="/images/add_hcp_icon.png"
                                             alt="Add HCP"/>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>

                            <div v-if="!busy && selectedHCPs.length">
                                <div style="color: #2c3a61;" class="font--size-24 mb-2">
                                    Selected
                                </div>
                                <v-list class="HCP-Search-Results">
                                    <v-list-item v-for="hcp in selectedHCPs"
                                                 :key="hcp.id"
                                                 @click.stop="removeHCP(hcp)">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ hcp.full_name_designation }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle v-if="hcp.npi">
                                                NPI: {{ hcp.npi }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="hcp.practice_name">
                                                {{ hcp.practice_name }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="hcp.city && hcp.state">
                                                {{ hcp.city }}, {{ hcp.state }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <img src="/images/remove_hcp_icon.png"
                                                 alt="Remove HCP"/>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </div>
                        </v-card-text>
                        <v-card-actions class="pa-4 flex-column">
                            <v-btn v-if="selectedHCPs.length > 0"
                                   @click.prevent="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   :disabled="busy"
                                   x-large
                                   depressed
                                   tile
                                   block>
                                Next
                            </v-btn>
                            <v-btn :to="{name: 'wizard.schedule'}"
                                   color="#414141"
                                   class="font-weight-bold"
                                   :disabled="busy"
                                   large
                                   text
                                   block>
                                Go Back
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-alert type="error"
                             v-if="error"
                             class="my-4">
                        {{ error }}
                    </v-alert>
                </v-form>
            </div>
        </div>
        <v-dialog v-if="hcp"
                  v-model="hcpDialog"
                  max-width="800">
            <create-from-sanofi :hcp="hcp"
                                 @dismiss="closeDialog">
            </create-from-sanofi>
        </v-dialog>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {findIndex, uniqBy} from 'lodash';
import {Form} from 'vform';
import BrandLogo from '../../components/BrandLogo';
import formValidation from '../../plugins/formValidation';
import SelectIcon from '../../components/forms/SelectIcon';
import SanofiHcpListItem from './sanofi/HCPListItem';
import dataService from '../../services/dataService';
import CreateFromSanofi from './sanofi/CreateFromSanofi';
import RequiredLabel from '../../components/forms/RequiredLabel';
import HcpListItem from './HCPListItem';
import env from '../../../../config/dev.env';

export default {
    name: 'search-sanofi-hcps',
    components: {HcpListItem, RequiredLabel, CreateFromSanofi, SanofiHcpListItem, SelectIcon, BrandLogo},
    mixins: [formValidation],
    data() {
        return {
            busy: false,
            error: null,
            form: new Form({
                first_name: '',
                last_name: '',
                city: '',
                state: '',
                page: 1,
                per_page: 10,
            }),
            hcpBusy: false,
            hcpDialog: false,
            hcp: null,
            searched: false,
            hcps: [],
            crmBusy: false,
            crmError: null,
            crmHCPs: [],
            pagination: null,
            sanofiEnabled: true,
        }
    },
    computed: {
        ...mapGetters([
            'wizard',
            'states',
        ]),
        selectedHCPs() {
            return this.wizard.hcps;
        },
        states() {
            const states = dataService.getStates();
            states.unshift({id: '', name: 'Any'});
            return states;
        },
        isFormValid() {
            const dirty = `${this.form.first_name}${this.form.last_name}${this.form.city}${this.form.state}`;
            return dirty.trim().length > 0;
        },
    },
    created() {
        if (this.wizard
            && this.wizard.brand
            && this.wizard.brand.code === this.$root.brandCode) {
            this.fetchData();
            return;
        }
        this.$root.startWizard();
    },
    methods: {
        fetchData() {
            dataService.getStates()
            .then(data => {
                this.statesObj = _.orderBy(data, 'id');
            })
        },
        search() {
            if (this.busy) {
                return;
            }
            this.hcps = [];
            this.crmHCPs = [];
            this.pagination = null;
            this.busy = true;
            this.searched = false;
            Promise.all([
                this.searchHCPs(),
                //this.searchCRM(1),
            ])
                .then(() => {
                    this.searched = true;
                })
                .finally(() => {
                    this.busy = false;
                })
        },
        searchCRM(page = 1) {
            if (!this.sanofiEnabled) {
                console.warn('Sanofi is disabled so search will not be performed');
                return Promise.resolve(true)
            }
            const params = this.form.data();
            params.page = page;
            this.crmBusy = true;
            // console.log('searching CRM page', page);
            this.form.post(`${env.MEDCHAT_API_URL}/api/hcps/sanofi`, {data: params})
                .then(({data}) => {
                    this.crmHCPs = uniqBy(this.crmHCPs.concat(data.data), 'jnj_id');
                    this.pagination = data.meta.pagination;
                    this.form.page = this.pagination.current_page;
                    // console.log('Searched CRM page', this.form.page);
                })
                .catch(err => {
                    this.crmHCPs = [];
                    this.pagination = null;
                })
                .finally(() => {
                    this.crmBusy = false;
                })
        },
        searchHCPs() {
            const options = {
                params: this.form.data(),
            }
            this.hcpBusy = true;
            return axios.get(`${env.MEDCHAT_API_URL}/api/hcps`, options)
                .then(response => {
                    this.hcps = response.data.filter(h => {
                        const name = h.full_name_designation.toLowerCase();
                        return !name.includes('sales')
                            && !name.includes('rep-');
                    });
                })
                .catch(err => {
                    this.hcps = [];
                })
                .finally(() => {
                    this.hcpBusy = false;
                })
        },
        clearSearch() {
            this.form.query = '';
            this.hcps = [];
            this.searched = false;
        },
        addsanofiHCP(hcp) {
            this.hcp = hcp;
            this.hcpDialog = true;
            this.$store.commit('SET_PIN_ISI', false);
        },
        closeDialog() {
            // console.log('closeDialog');
            this.hcp = null;
            this.hcpDialog = false;
            this.search();
        },
        addHCP(hcp) {
            this.hcp = hcp;
            this.hcpDialog = true;
            this.$store.commit('SET_PIN_ISI', false);
            // const index = findIndex(this.selectedHCPs, h => h.id === hcp.id);
            // if (index === -1) {
            //     this.$store.commit('ADD_WIZARD_HCP', hcp);
            //     //this.$toast.success(`Added ${hcp.full_name_designation}`);
            // } else {
            //     //this.$toast.info(`${hcp.full_name_designation} is already selected`);
            // }
        },
        removeHCP(hcp) {
            const index = findIndex(this.selectedHCPs, h => h.id === hcp.id);
            if (index !== -1) {
                this.$store.commit('REMOVE_WIZARD_HCP', hcp);
                //this.$toast.success(`Removed ${hcp.full_name_designation}`);
            }
        },
        submit() {
            if (this.selectedHCPs.length === 0) {
                return;
            }
            this.error = null;
            this.validateForm();
            if (!this.selectedHCPs.length) {
                //this.$toast.error('You must select at least one HCP');
                return;
            }
            this.$router.push({name: 'wizard.review_rep'});
        },
    }
}
</script>
