// state
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';
import _ from 'lodash';

export const state = {
    blackoutDates: [], // MCHAT-449
    brands: [],   //all brands
    brand: null, //current Brand ?

    loginTarget: '/',
    speakerSearch: {
        last_name: '',
        specialty: '',
        state: '',
        topic: '',
        page: 1
    },
    pinISI: true,
    meetingTypes: dataService.meetingTypes(),
    professionalDesignations: [],
    specialties: [],
    states: dataService.getStates(),
    timezones: dataService.timezones(),
    wizard: dataService.getWizardFields(),
};

// mutations
export const mutations = {
    SET_PIN_ISI(state, pinISI) {
        state.pinISI = pinISI;
    },
    SET_BLACKOUT_DATES(state, blackoutDates) { // MCHAT-449
        state.blackoutDates = blackoutDates;
    },
    SET_BRANDS(state, brands) {
        state.brands = brands;
    },
    SET_BRAND(state, brand) {
        state.brand = brand;
    },
    CLEAR_WIZARD(state) {
        state.wizard = dataService.getWizardFields();
        dataService.clearWizardFields();
    },
    SET_WIZARD(state, wizard) {
        state.wizard = wizard;
    },
    SET_WIZARD_BRAND(state, brand) {
        state.wizard.brand = brand;
    },
    SET_WIZARD_TOPIC(state, topic) {
        state.wizard.topic = topic;
        state.wizard.topic_id = topic.id;
    },
    ADD_WIZARD_HCP(state, hcp) {
        const index = _.findIndex(state.wizard.hcps, h => h.id === hcp.id);
        if (index === -1) {
            state.wizard.hcps.push(hcp);
        }
    },
    REMOVE_WIZARD_HCP(state, hcp) {
        state.wizard.hcps = state.wizard.hcps.filter(h => h.id !== hcp.id);
    },
    SET_WIZARD_HCP(state, hcp) {
        state.wizard.hcp = hcp;
    },
    SET_WIZARD_SPEAKER_CONFIRMED(state, confirmed) {
        state.wizard.speakerConfirmed = confirmed;
    },
    SET_WIZARD_APPOINTMENTS(state, appointments) {
        state.wizard.appointments = appointments;
    },
    SET_WIZARD_SPEAKERS(state, speakers) {
        state.wizard.speakers = speakers;
    },
    SET_PROFESSIONAL_DESIGNATIONS(state, professionalDesignations) {
        state.professionalDesignations = professionalDesignations;
    },
    SET_SPECIALTIES(state, specialties) {
        state.specialties = specialties;
    }
};

// actions
export const actions = {
    // Get Current Brand
    async fetchBrand({commit}, brandCode) {
        // console.log('Brand Code:' + brandCode) // MLYNX-8
        try {
            const {data: brand} = await axios.get(`${env.MEDCHAT_API_URL}/api/brands/${brandCode}`);     
            const {data: topics} = await axios.get(`${env.MEDCHAT_API_URL}/api/brands/${brandCode}/topics`);
            
            brand.topics = topics;
            commit('SET_BRAND', brand);
            return brand;
        } catch (e) {
            console.log(e);
            return null;
        }
    },
    // Get all brands by Client
    async fetchBrands({commit}) {
        const {data} = await axios.get(`${env.MEDCHAT_API_URL}/api/brands`);
        // console.log(data);
        commit('SET_BRANDS', data);
    },
    // Set Scheduling Wizard State
    setWizard({commit}, wizard) {
        commit('SET_WIZARD', wizard);
    },
    async getBlackoutDates({commit}) { // MCHAT-449
        try {
            const {data} = await axios.get(`${env.MEDCHAT_API_URL}/api/mchat/blackout-dates`);
            commit('SET_BLACKOUT_DATES', data.map(d => d.date));
        } catch (e) { // MPEX-17
            console.error('Could not set blackout dates', e.message);
        }
    },
    async getSpecialties({commit}) {
        // console.log('fetching specialties');
        try {
            const {data} = await axios.get(`${env.MEDCHAT_API_URL}/api/specialties`);
            let dataMap =  _.map(data, s => {
                return {
                    id: _.isObject(s) ? s.id : s,
                    name: _.isObject(s) ? s.name : s,
                    display_order: _.isObject(s) ? s.display_order : 0,
                }
            });    
            commit('SET_SPECIALTIES', dataMap);
            
            
        }catch (e) {
            console.error("Unable to retrieve speciatlies", e.message);
        }
    },
    async getProfessionalDesignations({commit}) {
        //  console.log('fetching designation');
        try{
            const {data} = await axios.get(`${env.MEDCHAT_API_URL}/api/degrees`);
            // console.log(data);
            const dataMap =  _.map(data, p => {
                return {
                    id: _.isObject(p) ? p.id : p,
                    name: _.isObject(p) ? p.name : p,
                    display_order: _.isObject(p) ? p.display_order : 0,
                }
            });
            // console.log(data);
            commit('SET_PROFESSIONAL_DESIGNATIONS', dataMap)
        } catch (e) {
            console.error('Unable to retrieve designations', e.message);
        }
    }
};

// getters
export const getters = {
    blackoutDates: state => state.blackoutDates, // MCHAT-449
    brand: state => state.brand,
    brandCode: state => state.brand ? state.brand.code : state.brands[0].code, // MLYNX-8
    brands: state => state.brands,
    brandSettings: state => {
        if (state.wizard && state.wizard.brand && state.wizard.brand.settings) {
            return state.wizard.brand.settings;
        }
        return null;
    },
    pinISI: state => state.pinISI,
    meetingType: state => {
        switch (state.wizard.meeting_type) {
            case 'mchat':
                return 'mChat';
            case 'tlc':
                return 'Thought Leader ConneXion';
            default:
                return 'Live Webinar';
        }
    },
    meetingTypes: state => state.meetingTypes,
    professionalDesignations: state => state.professionalDesignations,
    specialties: state => state.specialties,
    states: state => state.states,
    timezones: state => state.timezones,
    topic: state => {
        return _.find(state.wizard.brand.topics, t => {
            return t.id === state.wizard.topic_id;
        });
    },
    wizard: state => state.wizard,
    env: state => process.env ? process.env.env : 'production',
    isProduction: state => process.env ? process.env.isProduction : true,
};
