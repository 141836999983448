<template>
    <v-dialog v-model="dialog"
              max-width="640">
        <template v-slot:activator="{on}">
            <v-btn v-on="on"
                   text
                   x-large
                   tile
                   class="font-weight-bold ml-auto">
                <v-img src="/images/agent_icon_white.png"
                       width="22"
                       alt="Support"
                       class="mr-2"
                       contain/>
                Support
            </v-btn>
        </template>
        <v-card flat
                tile>
            <v-toolbar flat
                       tile
                       dark
                       color="primary" class="height-50">
                <v-toolbar-title>
                    Support
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pt-7 pb-4 font--size-16">
                <p class="mb-1">
                    Please contact ConneXion360 at <span class="text-no-wrap">1-877-238-8500</span> between 8&nbsp;<span class="text-small-caps">am</span> and 6&nbsp;<span class="text-small-caps">pm</span>&nbsp;ET.
                </p>
                <p class="mb-0">
                    For reschedule or cancellation requests, please have your event ID listed on the event confirmation email ready.
                </p>
            </v-card-text>
            <v-card-actions class="py-5 px-6">
                <v-btn @click.prevent="dialog = false"
                       color="secondary"
                       class="font-weight-bold"
                       tile
                       depressed
                       x-large
                       block>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'app-bar-support-dialog',
    data() {
        return {
            dialog: false,
        }
    },
}
</script>
