<template>
    <div class="MeetingListing__Time">
        <div v-if="scheduled"
             class="d-flex">
            <div class="mr-1">
                {{ scheduled.start.tz(timezone).format('h:mm') }}
            </div>
            <div class="text-small-caps">
                {{ scheduled.start.tz(timezone).format('a') }}
            </div>
            <div class="mx-1">
                -
            </div>
            <div class="mr-1">
                {{ scheduled.end.tz(timezone).format('h:mm') }}
            </div>
            <div class="text-small-caps mr-1">
                {{ scheduled.end.tz(timezone).format('a') }}
            </div>
            <div>
                {{ timezoneAbbreviation }}
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment-timezone';

export default {
    props: {
        meeting: {
            type: Object,
            required: true,
        },
        timezone: {
            type: String,
            default: 'America/New_York'
        },
    },
    computed: {
        scheduled() {
            if (this.meeting && this.meeting.meeting_scheduled_at) {
                return {
                    start: moment(this.meeting.meeting_scheduled_at),
                    end: moment(this.meeting.meeting_scheduled_at).add(this.meeting.meeting_scheduled_length, 'minutes'),
                };
            }
            return null;
        },
        timezoneAbbreviation() {
            switch (this.timezone) {
                case 'America/New_York':
                    return 'ET';
                case 'America/Chicago':
                    return 'CT';
                case 'America/Denver':
                    return 'MT';
                case 'America/Los_Angeles':
                    return 'PT';
                default:
                    return '';
            }
        }
    }
}
</script>
