<template>
    <v-app-bar app
               color="#23004c"
               flat
               tile
               height="52"
               dark
               class="App-Bar-Menu">


        <!-- For HCP Loggedin Menu -->
        <div v-if="!isAuthRelatedRoute && !isRep" class="App_Toggle_Hide">
            <v-menu
                bottom
                left
             class="Mob-Menu-Res">
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on" class="mob-res-icon"
                >
                    <v-app-bar-nav-icon class="mob-res-icon"></v-app-bar-nav-icon>
                </v-btn>
                </template>

                <v-list class="Mob-Menu-Res">
                    <v-list-item>
                        <v-list-item-title class="App_Mob_Menu_Icon">
                            <v-img src="/images/close-menu.png"
                            contain
                            width="43"
                            height="27"
                            alt="mChat logo"
                            class="App_Mob_Menu_Close"/>
                            <router-link :to="logoRoute">
                            <v-img src="/images/small-medchat.png"
                            contain
                            width="62"
                            height="33"
                            alt="medChat"
                            class="App_Mob_Menu_logo"/>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!isRep" :to="{name: 'wizard.start'}">
                        <v-list-item-title>
                            REQUEST FOR A medCHAT
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'speakers'}">
                        <v-list-item-title>
                            SPEAKERS
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'hcp_resources'}">
                        <v-list-item-title>
                            RESOURCES
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <!-- END For HCP Loggedin Menu -->

        <!-- For Rep Loggedin Menu -->

        <div v-if="isRep && (verified || user.is_password_set) && !isAuthRelatedRoute" class="App_Toggle_Hide">
            <v-menu
                bottom
                left
             class="Mob-Menu-Res">
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    dark
                    icon
                    v-bind="attrs"
                    v-on="on" class="mob-res-icon"
                >
                    <v-app-bar-nav-icon class="mob-res-icon"></v-app-bar-nav-icon>
                </v-btn>
                </template>

                <v-list class="Mob-Menu-Res">
                    <v-list-item>
                        <v-list-item-title class="App_Mob_Menu_Icon">
                            <v-img src="/images/close-menu.png"
                            contain
                            width="43"
                            height="27"
                            alt="mChat logo"
                            class="App_Mob_Menu_Close"/>
                            <router-link :to="logoRoute">
                            <v-img src="/images/small-medchat.png"
                            contain
                            width="62"
                            height="33"
                            alt="mChat logo"
                            class="App_Mob_Menu_logo"/>
                            </router-link>
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'about'}">
                        <v-list-item-title>
                            ABOUT
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'wizard.start'}">
                        <v-list-item-title>
                            REQUEST FOR A medCHAT
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'meetings'}">
                        <v-list-item-title>
                            MY PROGRAMS
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'speakers'}">
                        <v-list-item-title>
                            SPEAKERS
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'rep_resources'}">
                        <v-list-item-title>
                            RESOURCES
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
        <!-- END For Rep Loggedin Menu -->

        <router-link :to="logoRoute"
                     class="mr-5 remove-mr">
            <v-img src="/images/head_medChat.png"
                   contain
                   width="62"
                   height="33"
                   alt="medChat"/>
        </router-link>

        <div class="App_Toggle_Show">
            <template v-if="!isAuthRelatedRoute && !isRep">
                <v-btn v-if="!isRep"
                    :to="{name: 'wizard.start'}"
                    text
                    x-large
                    tile
                    class="font-weight-bold text-no-transform">
                    REQUEST FOR A medCHAT
                </v-btn>
                <v-btn :to="{name: 'speakers'}"
                    text
                    x-large
                    tile
                    class="font-weight-bold">
                    SPEAKERS
                </v-btn>
                <v-btn v-if="!isRep"
                    :to="{name: 'hcp_resources'}"
                    text
                    x-large
                    tile
                    class="font-weight-bold">
                    RESOURCES
                </v-btn>
            </template>
        </div>
       
        <template v-if="isRep && (verified || user.is_password_set) && !isAuthRelatedRoute">
            <div class="App_Toggle_Show">
                <v-btn :to="{name: 'about'}"
                    text
                    x-large
                    tile
                    class="font-weight-bold">
                    ABOUT
                </v-btn>
                <v-btn :to="{name: 'wizard.start'}"
                    text
                    x-large
                    tile
                    class="font-weight-bold text-no-transform">
                    REQUEST FOR A medCHAT
                </v-btn>
                <v-btn :to="{name: 'meetings'}"
                    text
                    x-large
                    tile
                    class="font-weight-bold">
                    MY PROGRAMS
                </v-btn>
                <v-btn :to="{name: 'speakers'}"
                    text
                    x-large
                    tile
                    class="font-weight-bold">
                    SPEAKERS
                </v-btn>
                <v-btn :to="{name: 'rep_resources'}"
                    text
                    x-large
                    tile
                    class="font-weight-bold">
                    RESOURCES
                </v-btn>
            </div>

            <v-spacer class="mob-remove-space"></v-spacer>
             <div class="menu-dropdown">
                <v-menu offset-y
                    v-if="isAdmin || isRep">
                   
                <template v-slot:activator="{on}">
                    <v-btn text
                           class="font-weight-bold pd-mob"
                           x-large
                           tile
                           v-on="on">
                        MY ACCOUNT
                    </v-btn>
                </template>
                <v-list class="py-0 Myaccount-List">
                    <v-list-item :to="{name: 'edit_profile'}">
                        <v-list-item-title>
                            Edit My Profile
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{name: 'change_password'}">
                        <v-list-item-title>
                            Change My Password
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
                
            </v-menu>
            </div>
            <v-btn text
                   x-large
                   tile
                   class="font-weight-bold pd-mob"
                   v-if="!isHCP"
                   @click.prevent="logout">
                SIGN OUT
            </v-btn>
        </template>

        <template v-if="authenticated && (!verified || !user.is_password_set)">
            <v-spacer class="mob-remove-space"></v-spacer>
            <v-btn text
                   x-large
                   tile
                   class="font-weight-bold ml-auto"
                   :to="{name: 'login'}">
                SANOFI REP LOGIN
            </v-btn>
        </template>

        <template v-else-if="isHCP || !authenticated">
            <v-spacer class="mob-remove-space"></v-spacer>
            <v-btn text
                   x-large
                   tile
                   class="font-weight-bold ml-auto"
                   :to="{name: 'login'}">
                SANOFI REP LOGIN
            </v-btn>
        </template>
        <template v-else>
            <!-- <v-spacer class="mob-remove-space"></v-spacer> -->
        </template>

        <app-bar-support-dialog>
        </app-bar-support-dialog>

    </v-app-bar>
</template>
<script>
import {mapGetters} from 'vuex';
import AppBarSupportDialog from './resources/AppBarSupportDialog';

export default {
    components: {AppBarSupportDialog},
    props: {
        showNavigation: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        ...mapGetters([
            'authenticated',
            'isAdmin',
            'isHCP',
            'isRep',
            'user',
            'verified',
        ]),
        isAuthRelatedRoute() {
            return [
                'login',
                'password.request',
                'password.reset',
                'register.rep',
                'verification.notice',
                'set_password',
            ].includes(this.$route.name);
        },
        isHomeRoute() {
            return this.$route.name === 'home';
        },
        isWizardRoute() { // MSPR-29
            return this.$route.name.startsWith('wizard');
        },
        logoRoute() {
            if (this.authenticated && this.verified) {
                if (this.isHCP) {
                    return {
                        name: 'home',
                    }
                }
                return {
                    name: 'about',
                }
            }
            return {
                name: 'home',
            }
        },
    },
    methods: {
        async logout() {
            await this.$store.dispatch('logout');
            this.$router.push({name: 'home'});
        }
    }
}
</script>
