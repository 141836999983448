<template>
    <v-container class="pt-10">
        <v-row justify="center">

            <!-- <v-col cols="12"
                   md="3">
                <brand-logo class="mt-5">
                </brand-logo>
            </v-col> -->

            <v-col cols="12"
                   md="8">

                <v-form @submit.prevent="submit"
                        class="mb-4">
                    <v-card outlined>
                        <v-toolbar color="primary"
                                   dark class="height-50">
                            <v-toolbar-title>
                                Edit HCP Registration Information
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="pt-7 pb-0">

                            <v-text-field v-model.trim="form.first_name"
                                          placeholder=" "
                                          autocomplete="given-name"
                                          outlined
                                          dense
                                          :rules="[rules.required]"
                                          :error-messages="form.errors.get('first_name')"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="First name"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.last_name"
                                          placeholder=" "
                                          autocomplete="family-name"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('last_name')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Last name"/>
                                </template>
                            </v-text-field>

                            <v-select v-model.trim="form.professional_designation"
                                      placeholder=" "
                                      :items="professionalDesignations"
                                      item-value="name"
                                      item-text="name"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('professional_designation')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Degree"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-select v-model.trim="form.specialty"
                                      placeholder=" "
                                      :items="specialties"
                                      item-value="name"
                                      item-text="name"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('specialty')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Specialty"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-text-field v-model.trim="form.npi"
                                          placeholder=" "
                                          label="NPI"
                                          autocomplete="new-npi"
                                          v-mask="'##########'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('npi')"
                                          pattern="[0-9]+"
                                          class="mb-4">
                            </v-text-field>

                            <v-text-field v-model.trim="form.email"
                                          placeholder=" "
                                          type="email"
                                          autocomplete="email"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('email')"
                                          :rules="[rules.required, rules.email]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Email"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.phone"
                                          placeholder=" "
                                          type="tel"
                                          autocomplete="tel"
                                          v-mask="'###-###-####'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('phone')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Phone"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.address1"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('address1')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Practice Address 1"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.address2"
                                          label="Practice Address 2"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('address2')"
                                          class="mb-4">
                            </v-text-field>

                            <v-text-field v-model.trim="form.city"
                                          placeholder=" "
                                          autocomplete="address-level2"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('city')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="City"/>
                                </template>
                            </v-text-field>

                            <v-select v-model="form.state"
                                      placeholder=" "
                                      autocomplete="address-level1"
                                      :items="states"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('state')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="State"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-text-field v-model.trim="form.zip_code"
                                          placeholder=" "
                                          autocomplete="postal-code"
                                          v-mask="'#####'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('zip_code')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="ZIP code"/>
                                </template>
                            </v-text-field>

                            <v-select v-model="form.timezone"
                                      placeholder=" "
                                      :items="timezones"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('timezone')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Time zone"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <div class="font-italic">
                                <span class="error--text">*</span>Required field
                            </div>

                        </v-card-text>
                        <v-card-actions class="px-4">
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   dark
                                   :disabled="form.busy"
                                   x-large
                                   tile
                                   depressed
                                   block>
                                Register
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

            </v-col>

        </v-row>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import BrandLogo from '../../components/BrandLogo';
import RequiredLabel from '../../components/forms/RequiredLabel';
import formValidation from '../../plugins/formValidation';
import SelectIcon from '../../components/forms/SelectIcon';
import _ from 'lodash';
import axios from 'axios';
import dataService from '../../services/dataService'
import env from '../../../../config/dev.env';

export default {
    components: {SelectIcon, RequiredLabel, BrandLogo},
    mixins: [formValidation],
    data() {
        return {
            form: new Form({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                timezone: '',
                professional_designation: '',
                specialty: '',
                address1: '',
                address2: '',
                city: '',
                state: '',
                zip_code: '',
                npi: '',
                recaptcha_token: null, // JC-38
            }),
        }
    },
    computed: {
        ...mapGetters([
            'isHCP',
            'professionalDesignations',
            'specialties',
            'states',
            'timezones',
            'user',
            'wizard',
        ]),
    },
    created() {
        this.initForm();
        this.fetchData();
    },
    methods: {
        fetchData() {
            if (this.busy) {
                return;
            }
            this.busy = true;
            
            dataService.professionalDesignations()
                .then(data => {
                    this.professionalDesignationsObj = _.orderBy(data, 'id');
                })

            dataService.specialties()
                .then(data => {
                    this.specialtiesObj = _.orderBy(data, 'id');
                })
            
            dataService.timezones()
                .then(data => {
                    this.timezonesObj = _.orderBy(data, 'id');
                })

            dataService.getStates()
                .then(data => {
                    this.statesObj = _.orderBy(data, 'id');
                })
        },
        async initForm() {
            //axios.get('/sanctum/csrf-cookie')

            await this.$store.dispatch('fetchUser');

            let hcp = this.wizard.hcp;
            if (this.isHCP) {
                hcp = this.user;
            }
            this.form.first_name = hcp.first_name;
            this.form.last_name = hcp.last_name;
            this.form.email = hcp.email;
            this.form.phone = hcp.phone;
            this.form.timezone = hcp.timezone;
            this.form.address1 = hcp.address1;
            this.form.address2 = hcp.address2;
            this.form.city = hcp.city;
            this.form.state = hcp.state;
            this.form.zip_code = hcp.zip_code;
            this.form.npi = hcp.npi;
            this.form.specialty = hcp.specialty;
            this.form.professional_designation = hcp.professional_designation;
        },
        async submit() {
            try {

                if (this.isHCP) {
                    await this.form.put(`${env.MEDCHAT_API_URL}/api/users/${this.user.id}`)

                    const {data: hcp} = await this.form.put(`${env.MEDCHAT_API_URL}/api/hcps/${this.user.mchat_hcp_id}`);

                    this.$store.commit('SET_WIZARD_HCP', hcp);

                    await this.$store.dispatch('fetchUser');

                    this.$router.push({name: 'wizard.review_hcp'});
                } else {
                    // TODO
                }
            } catch (e) {
                if (e.response && e.response.data) {
                    //this.$toast.error(e.response.data.message);
                } else {
                    //this.$toast.error(e.message);
                }
            }
        }
    }
}
</script>
