<template>
    <section class="Footer-Section">

        <v-footer class="Footer py-8 mt-16"
                  color="white">
            <v-container>
                <v-row>
                    <v-col cols="12"
                           md="10">
                        <p class="mb-0">
                            &copy; Sanofi Pasteur {{ new Date().getFullYear() }} - All rights reserved.
                            <br/>
                           MAT-US-2110409-v1.0-04/2022
                        </p>
                    </v-col>
                    <v-col cols="12"
                           md="2">
                        <v-img src="/images/footer-logo.png"
                               alt="Sanofi"
                               contain
                               class="mb-0 Footer-Logo"/>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </section>

</template>
<script>
import {keys} from 'lodash';
import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'authenticated',
        ]),
        formFields() {
            if (this.sampleForm) {
                return keys(this.sampleForm)
            }
            return null;
        },
        sampleForm() {
            return window.sampleForm ? window.sampleForm : {};
        },
    },
}
</script>
