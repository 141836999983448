<template>
    <v-container class="pt-10">
        <v-row class="mb-4">
            <!-- <v-col cols="12"
                   md="3">
                <brand-logo>
                </brand-logo>
            </v-col> -->
            <v-col cols="12"
                   md="12">
                <v-card outlined
                        class="mb-4">
                    <v-card-text>
                        <div class="mb-2">
                            Please review your medChat meeting request. If everything is correct, click SUBMIT THIS medCHAT REQUEST.
                        </div>
                        <div class="mb-2">
                            Please allow 5 business days for your meeting to be confirmed. Once confirmed, you will receive confirmation and reminder emails for your upcoming medChat.
                        </div>
                        <div class="mb-2">
                            If your meeting cannot be scheduled, a Sanofi Sales Representative will contact you.
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-form @submit.prevent="submit"
                ref="form">
            <v-card outlined>
                <v-toolbar color="primary"
                           dark class="height-50">
                    <v-toolbar-title>
                        Review Your medChat Request
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="subtitle-1 pb-0">

                    <v-card flat
                            class="mb-4">
                        <v-toolbar flat
                                   color="grey lighten-3">
                            <v-toolbar-title>
                                Step 1: Topic
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="subtitle-1">
                            <div class="font--size-18 mb-2">
                                The topic chosen is:
                            </div>
                            <div class="Wizard__Review-Box">
                                <div v-html="wizard.topic.name"
                                     class="Wizard__Review-Box-Row">
                                </div>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="#d9ecf7"
                                   class="lighten-3 blue--text text--darken-4 font-weight-bold"
                                   :to="{name: 'wizard.topic'}">
                                <v-icon left>
                                    <!-- mdi-pencil -->
                                    {{ mdiPencil }}
                                </v-icon>
                                Change Topic
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card flat
                            class="mb-4">
                        <v-toolbar flat
                                   color="grey lighten-3">
                            <v-toolbar-title class="Word-Break-Text">
                                Step 2: Selected speakers, dates, and times
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="subtitle-1">
                            <div class="font--size-18 mb-4">
                                These speakers will be invited in this order:
                            </div>

                            <div class="Wizard__Review-Box mb-4">
                                <div class="Wizard__Review-Box-Row"
                                     v-for="speaker in wizard.speakers"
                                     :key="speaker.id">
                                    {{ speaker.full_name_designation }}
                                </div>
                            </div>

                            <div class="mb-0">
                                <div class="font--size-18 mb-4">
                                    The medChat will take place at one of these times:
                                </div>

                                <div class="Wizard__Review-Box mb-0">
                                    <div class="Wizard__Review-Box-Row"
                                         v-for="(appointment, index) in wizard.appointments"
                                         :key="index">
                                        <div class="font--size-16 font-weight-bold">
                                            {{ appointmentDate(appointment) }}
                                        </div>
                                        <div class="font--size-16">
                                            {{ appointmentTime(appointment) }}
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </v-card-text>
                        <v-card-actions>
                            <v-btn color="#d9ecf7"
                                   class="lighten-3 blue--text text--darken-4 font-weight-bold"
                                   :to="{name: 'wizard.schedule'}">
                                <v-icon left>
                                    <!-- mdi-pencil -->
                                    {{ mdiPencil }}
                                </v-icon>
                                Edit Speakers and Times
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card flat
                            class="mb-4">
                        <v-toolbar flat
                                   color="grey lighten-3">
                            <v-toolbar-title>
                                Step 3: Participant
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="subtitle-1">
                            <div class="font--size-18 mb-2">
                                Your information:
                            </div>

                            <v-text-field v-model="wizard.hcp.first_name"
                                          label="First Name"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.last_name"
                                          label="Last Name"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.professional_designation"
                                          label="Degree"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.specialty"
                                          label="Specialty"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.npi"
                                          label="NPI"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.email"
                                          label="Email"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.phone"
                                          label="Phone"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.street1"
                                          label="Practice Address 1"
                                          placeholder=" "
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.street2"
                                          v-if="wizard.hcp.street2"
                                          label="Practice Address 2"
                                          placeholder=" "
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.city"
                                          label="City"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.state"
                                          label="State"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field v-model="wizard.hcp.zip_code"
                                          label="ZIP code"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>

                            <v-text-field :value="timezoneName"
                                          label="Time Zone"
                                          disabled
                                          outlined
                                          single-line
                                          dense>
                            </v-text-field>
                        </v-card-text>
                        <v-card-actions class="pb-4">
                            <v-btn color="#d9ecf7"
                                   class="lighten-3 blue--text text--darken-4 font-weight-bold"
                                   :to="{name: 'wizard.edit_hcp'}">
                                <v-icon left>
                                    <!-- mdi-pencil -->
                                    {{ mdiPencil }}
                                </v-icon>
                                Edit
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-card-text>
                <v-card-actions class="flex-column pa-4 justify-center">
                    <v-btn type="submit"
                           v-if="!form.busy"
                           color="secondary"
                           class="font-weight-bold text-no-transform"
                           x-large
                           depressed
                           tile
                           block>
                        SUBMIT THIS medCHAT REQUEST
                    </v-btn>
                    <v-progress-circular v-if="form.busy"
                                         indeterminate>
                    </v-progress-circular>

                    <v-alert type="error"
                             class="mt-2"
                             width="100%"
                             v-if="form.errors.any()">
                        There was a problem:
                        <div v-for="error in form.errors.all()">
                            {{ error }}
                        </div>
                    </v-alert>
                </v-card-actions>
            </v-card>
        </v-form>

        <v-dialog v-model="dialog"
                  persistent
                  width="600">
            <v-card outlined
                    flat
                    tile>
                <v-toolbar color="primary"
                           flat
                           tile
                           dark class="height-50">
                    <v-toolbar-title>
                        medChat Meeting Request Received
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="text-center py-4">
                    <div>
                        Thank you for your medChat request.
                    </div>
                    <div>
                        You will receive a confirmation email within 5 business days.
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="secondary"
                           class="font-weight-bold"
                           @click="HCPlogout()"
                           large
                           tile
                           depressed
                           block>
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import BrandLogo from '../../components/BrandLogo';
import formValidation from '../../plugins/formValidation';
import dataService from '../../services/dataService';
import moment from 'moment-timezone';
import env from '../../../../config/dev.env';
import { mdiPencil } from '@mdi/js';
import _ from 'lodash';

export default {
    components: {BrandLogo},
    mixins: [formValidation],
    data() {
        return {
            mdiPencil,
            busy: false,
            dialog: false,
            form: new Form({
                appointments: [],
                brand_code: '',
                hcps: [],
                speaker_ids: [],
                topic_id: '',
            })
        }
    },
    computed: {
        ...mapGetters([
            'wizard',
            'isHCP',
        ]),
        timezone() {
            return 'America/New_York';
        },
        timezoneName() {
            const timezone = _.find(dataService.timezones(), t => t.id === this.wizard.hcp.timezone);
            if (timezone) {
                return timezone.name;
            }
            return this.wizard.hcp.timezone;
        },
    },
    created() {
        if (this.wizard
            && this.wizard.brand
            && this.wizard.brand.code === this.$root.brandCode
            && this.wizard.topic
            && (this.wizard.hcp || this.wizard.hcps.length)
            && this.wizard.speakers.length) {
            return;
        }
        this.$root.startWizard();
    },
    methods: {
        appointmentDate(appointment) {
            return moment.tz(appointment.start, this.timezone).format('dddd, MMMM D, YYYY')
        },
        appointmentTime(appointment) {
            return moment.tz(appointment.start, this.timezone).format('h:mm A [ET]') // NNI-44
        },
        submit() {
            this.$store.commit('LOGOUT');
            if (!this.validateForm()) {
                return;
            }
            this.busy = true;

            this.form.appointments = this.wizard.appointments.map(a => a.start);
            this.form.brand_code = this.wizard.brand.code;
            
            this.form.hcps = [this.wizard.hcp];
            this.form.speaker_ids = this.wizard.speakers.map(s => s.id);
            this.form.topic_id = this.wizard.topic.id;

            this.form.post(`${env.MEDCHAT_API_URL}/api/meetings`)
                .then(({data}) => {
                    this.dialog = true;
                })
                .finally(() => {
                    this.busy = false;
                })
        },
        async HCPlogout() {
            await this.$store.dispatch('logout');
            this.$router.push({name: 'home'});
        }
    }
}
</script>
