<template>
    <v-container fluid class="mt-5">
        <v-row align="center"
               justify="center">
            <v-col cols="12"
                   sm="8"
                   md="5">

                <v-form @submit.prevent="submit"
                        ref="form">
                    <v-card flat
                            tile
                            outlined>
                        <v-toolbar color="primary"
                                   dark
                                   flat class="height-50">
                            <v-toolbar-title>
                                Reset Your Password
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pt-10 pb-4">
                            <v-text-field v-model="form.email"
                                          type="email"
                                          label="Email"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :rules="[rules.required, rules.email]"
                                          :error-messages="form.errors.get('email')"
                                          required>
                            </v-text-field>

                        </v-card-text>
                        <v-card-actions class="d-block px-4">
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   tile
                                   depressed
                                   block
                                   :disabled="form.busy">
                                Send Password Reset Link
                            </v-btn>
                            <v-btn :to="{name: 'login'}"
                                   class="font-weight-bold"
                                   color="primary"
                                   block
                                   text>
                                Back to Login
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <v-alert type="success"
                         color="#23004c"
                         dark
                         class="mt-3"
                         v-if="message">
                    {{ message }}
                </v-alert>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {Form} from 'vform';
import formValidation from '../../../plugins/formValidation';
import env from '../../../../../config/dev.env';
import axios from 'axios';

export default {
    mixins: [formValidation],
    data() {
        return {
            form: new Form({
                email: '',
            }),
            message: null,
        }
    },
    methods: {
        async submit() {
            if (!this.$refs.form.validate()) {
                return;
            }
            
            this.form.post(`${env.MEDCHAT_API_URL}/password/email`)
                .then(({data}) => {
                    // console.log(data);
                    this.message = data.message;
                })
        }
    }
}
</script>
