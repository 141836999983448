<template>
    <v-container class="pt-10">
        <v-row justify="center">

            <v-col cols="12"
                   md="8">

                <v-card flat
                        tile
                        class="mb-10 Register-Disclaimer pa-4"
                        style="font-size: 16px; color: #336699;">
                    The information you provide will be used by Sanofi, our affiliates, and our service providers for your registration and participation in the medChat program. You may withdraw from the program by calling <span class="text-no-wrap">1-877-238-8500</span>. Our <a href="https://connexion360.com/index.php/privacy-policy/" target="_blank text-decoration-underline">Privacy Policy</a> further governs the use of the information you provide. By providing this information and selecting the SIGN UP button, you indicate that you read, understand, and agree to these terms.
                </v-card>

                <v-form @submit.prevent="submit"
                        ref="form"
                        class="mb-4">
                    <v-card outlined
                            flat
                            tile>
                        <v-toolbar color="primary"
                                   flat
                                   tile
                                   dark class="height-50">
                            <v-toolbar-title>
                                Sanofi Rep Sign-up
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="px-5 pt-7 pb-0">

                            <v-text-field v-model.trim="form.first_name"
                                          label="First name"
                                          placeholder=" "
                                          autocomplete="given-name"
                                          outlined
                                          dense
                                          :rules="[rules.required]"
                                          :error-messages="form.errors.get('first_name')"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <v-text-field v-model.trim="form.last_name"
                                          label="Last name"
                                          placeholder=" "
                                          autocomplete="family-name"
                                          outlined
                                          dense
                                          :rules="[rules.required]"
                                          :error-messages="form.errors.get('last_name')"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <v-text-field v-model.trim="form.email"
                                          type="email"
                                          label="Email address"
                                          placeholder="Use your Sanofi account"
                                          autocomplete="email"
                                          outlined
                                          dense
                                          :rules="[rules.required, rules.email]"
                                          :error-messages="form.errors.get('email')"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <v-text-field v-model="form.phone"
                                          label="Phone number"
                                          placeholder=" "
                                          type="tel"
                                          autocomplete="tel"
                                          v-mask="'###-###-####'"
                                          outlined
                                          dense
                                          :rules="[rules.required]"
                                          :error-messages="form.errors.get('phone')"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <v-select v-model="form.timezone"
                                      label="Time zone"
                                      placeholder=" "
                                      :items="timezones"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      :rules="[rules.required]"
                                      :error-messages="form.errors.get('timezone')"
                                      class="mb-2"
                                      required>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <div class="mb-2">
                                We'll send you a verification email so you can set your password.
                            </div>
                            <div v-if="error"
                                 class="error--text">
                                {{ error }}
                            </div>

                        </v-card-text>
                        <v-card-actions class="px-5 pb-7">
                            <v-btn type="submit"
                                   :disabled="form.busy"
                                   color="secondary"
                                   class="font-weight-bold"
                                   x-large
                                   tile
                                   depressed
                                   block>
                                Sign Up
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

            </v-col>

        </v-row>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import BrandLogo from '../../components/BrandLogo';
import {Form} from 'vform';
import dataService from '../../services/dataService';
import SelectIcon from '../../components/forms/SelectIcon';
import RequiredLabel from '../../components/forms/RequiredLabel';
import formValidation from '../../plugins/formValidation';
import _ from 'lodash';
import axios from 'axios';
import env from '../../../../config/dev.env';

export default {
    components: {BrandLogo, RequiredLabel, SelectIcon},
    mixins: [formValidation],
    data() {
        return {
            error: null,
            form: new Form({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                timezone: window.hcpForm ? window.hcpForm.timezone : '',
                recaptcha_token: null, // JC-38
            }),
            timezonesObj: [],
        }
    },
    computed: {
        ...mapGetters([
            'states',
            'timezones',
        ]),
    },
    created() {
        this.initForm();
        this.fetchData();
    },
    methods: {
        fetchData() {
            if (this.busy) {
                return;
            }
            this.busy = true;
        },
        initForm() {
            //axios.get(`${env.MEDCHAT_API_URL}/sanctum/csrf-cookie`)
        },
        async submit() {
            this.error = null; //Clear form errors
            
            if (!this.$refs.form.validate()) {
                return;
            }
            
            try {
                //this.form.recaptcha_token = await grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'register_rep'});
                // Set the header config so that if the form validation fails it will return json object
                let config = {
                    headers: {'content-type': 'application/json;charset=utf-8',
                    'X-Requested-With': 'XMLHttpRequest' }
                }
                let res = await this.form.post(`${env.MEDCHAT_API_URL}/api/register/rep`, config)

                // // Error handling needs to be revisited at some point,
                // // Working around the axios error rejection here
                // if(res.data.errors && res.data.errors.email) {
                //     this.error = res.data.errors.email[0];
                //     return;
                // } else if(res.data.errors && res.data.errors.message) {
                //     this.error = res.data.errors.message
                //     return;
                // } else if(res.data.errors) {
                //     this.error = 'There was an error submitting the form.'
                //     return;
                // }
                
                if(res.data.access_token) {
                    this.$store.commit('SET_ACCESS_TOKEN', res.data.access_token)
                    axios.defaults.headers.common = {'Authorization': `Bearer ${res.data.access_token}`};

                    await this.$store.dispatch('fetchUser');
                    
                    this.$router.push({name: 'verification.notice', query: {email: this.form.email}});
                }


            } catch (e) {
                if (e.response && e.response.data) {
                    //this.$toast.error(e.response.data.message);
                    this.errors = e.response.data;
                } else {
                    //this.$toast.error(e.message);
                }
            }

        }
    }
}
</script>
