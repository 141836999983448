<template>
    <v-card>
        <v-toolbar color="primary"
                   flat
                   tile
                   dark class="height-50">
            <v-toolbar-title>
                HCPs Already Attending
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Location
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <hcp-row v-for="m in meeting.meetingHCPs"
                             :key="m.id"
                             :id="m.hcp_id">
                    </hcp-row>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>
<script>
import HcpRow from './HcpRow';
export default {
    name: 'meeting-hcps-card',
    components: {HcpRow},
    props: {
        meeting: {
            type: Object,
            required: true,
        },
    }
}
</script>
