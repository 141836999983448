<template>
    <div class="MeetingList__Container">
        <div class="d-flex justify-space-between mb-4">
            <v-btn :disabled="busy || startDate.isSameOrBefore(now, 'day')"
                   @click="gotoPrevious"
                   tile>
                Previous
            </v-btn>

            <v-btn :disabled="busy"
                   @click="gotoNext"
                   tile>
                Next
            </v-btn>
        </div>

        <v-skeleton-loader type="text"
                           v-if="busy">
        </v-skeleton-loader>

        <p v-if="!busy && filteredMeetings.length === 0"
           class="mb-0">
            There are no meetings scheduled between <span class="text-no-wrap">{{ startDate.format('MMMM D, YYYY') }} and {{ endDate.format('MMMM D, YYYY') }}</span>.
        </p>
        <template v-if="!busy && filteredMeetings.length > 0">

            <div class="MeetingList flex MeetingList--Striped">

                <div v-for="meeting in filteredMeetings"
                     :key="meeting.id"
                     class="MeetingList__Meeting py-4">
                    <div class="font-weight-bold mb-5">
                        {{ meeting.topic.name }}
                    </div>
                    <div class="d-flex justify-space-between">
                        <div v-if="meeting.speaker">
                            <div class="d-flex">
                                {{ meeting.speaker.professional_designation ? `${meeting.speaker.name}, ${meeting.speaker.professional_designation}` : meeting.speaker.name }}
                            </div>
                            <div v-if="meeting.speaker.practice_name">
                                {{ meeting.speaker.practice_name }}
                            </div>
                            <div class="d-flex">
                                <div v-if="meeting.speaker.city"
                                     class="mr-1">
                                    {{ meeting.speaker.city }},
                                </div>
                                <div>
                                    {{ meeting.speaker.state }}
                                </div>
                            </div>
                        </div>
                        <div v-else>

                        </div>
                        <div class="d-flex flex-column align-end">
                            <div class="font-weight-bold text-no-wrap">
                                {{ meeting.meeting_scheduled_at | datetime('MMMM D, YYYY') }}
                            </div>
                            <div class="mb-2">
                                {{ meeting.meeting_scheduled_at | datetime('h:mm') }} <span class="text-small-caps">{{ meeting.meeting_scheduled_at | datetime('a') }}</span> {{ meeting.meeting_scheduled_at | datetime('z').replace('ST', 'T').replace('DT', 'T') }}
                            </div>
                            <v-btn v-if="authenticated"
                                   :to="{name: 'meeting.show', params: {meetingID: meeting.id}}"
                                   color="secondary"
                                   tile
                                   depressed
                                   class="font-weight-bold">
                                Register
                            </v-btn>
                            <v-btn v-else
                                   :to="{name: 'register.hcp', query: {redirect: `/meetings/${meeting.id}`}}"
                                   color="secondary"
                                   tile
                                   depressed
                                   class="font-weight-bold">
                                Register
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import groupBy from 'lodash/groupBy';
import jstz from 'jstz';
import MeetingRow from './MeetingRow';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';

export default {
    name: 'meeting-list',
    components: {MeetingRow},
    props: {
        prescheduled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            busy: false,
            meetings: [],
            startDate: moment(),
            endDate: moment().add(1, 'month'),
        }
    },
    computed: {
        ...mapGetters([
            'authenticated',
            'isHCP',
        ]),
        now() {
            return moment();
        },
        filteredMeetings() {
            if (this.prescheduled) {
                return this.meetings.filter(m => m.is_source_portal);
            }
            return this.meetings;
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.getMeetings();
        },
        gotoPrevious() {
            if (!this.startDate.isAfter(moment(), 'day')) {
                return;
            }
            this.startDate.add(-2, 'months');
            this.endDate.add(-2, 'months');
            this.getMeetings();
        },
        gotoNext() {
            this.startDate.add(2, 'months');
            this.endDate.add(2, 'months');
            this.getMeetings();
        },
        getMeetings() {
            if (this.busy) {
                return;
            }
            let options = {
                params: {
                    start: this.startDate.format('YYYY-MM-DD'),
                    end: this.endDate.format('YYYY-MM-DD'),
                    timezone: jstz.determine().name(),
                }
            };
            this.busy = true;
            axios.get(`${env.MEDCHAT_API_URL}/api/meetings/upcoming`, options)
                .then(response => {
                    this.meetings = response.data;
                })
                .catch(err => {
                })
                .finally(() => {
                    this.busy = false;
                })
        }
    }
}
</script>
