<template>
    <v-container>

        <v-form @submit.prevent="upload"
                ref="upload"
                class="mb-8">
            <v-card>
                <v-toolbar color="secondary"
                           dark>
                    <v-toolbar-title>
                        Upload ZIP-to-Territory File
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>

                    <v-file-input v-model="form.file"
                                  label="ZIP-to-Territory File"
                                  hint="Must be a CSV"
                                  persistent-hint
                                  :rules="[rules.required]"
                                  :error-messages="form.errors.get('file')"
                                  accept="text/csv">
                    </v-file-input>

                </v-card-text>
                <v-card-actions class="px-5">
                    <v-btn type="submit"
                           color="secondary"
                           :disabled="!form.file || form.busy">
                        Upload
                    </v-btn>

                    <v-btn class="ml-3"
                           color="accent"
                           href="https://docs.google.com/spreadsheets/d/1caucbYVQe7-k_pXjYYZGzj3Nl6YJOOxe71PFJq_PglM/edit?usp=sharing"
                           target="_blank">
                        Get the Template
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

        <v-card>
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    Territories
                    <v-btn @click.prevent="fetchData"
                           text
                           right
                           v-if="!busy">
                        <v-icon>
                            <!-- mdi-refresh -->
                            {{ mdiRefresh }}
                        </v-icon>
                    </v-btn>
                    <v-progress-circular v-if="busy"
                                         class="ml-5"
                                         size="20"
                                         indeterminate>
                    </v-progress-circular>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-skeleton-loader v-if="busy"
                                   type="table"
                                   :types="types">

                </v-skeleton-loader>
                <v-simple-table v-if="!busy">
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Name
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="territory in territories"
                        :key="territory.id">
                        <td>
                            <router-link :to="{name: 'admin.show_territory', params: {territoryID: territory.id}}">
                                {{ territory.id }}
                            </router-link>
                        </td>
                        <td>
                            {{ territory.name }}
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>

            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import formValidation from '../../plugins/formValidation';
    // @see https://github.com/cretueusebiu/vform/issues/97
    // @see https://github.com/therealparmesh/object-to-formdata
    import {objectToFormData} from 'object-to-formdata';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';
    import { mdiRefresh } from '@mdi/js';

    export default {
        mixins: [formValidation],
        data() {
            return {
                mdiRefresh,
                busy: false,
                form: new Form({
                    file: null,
                }),
                territories: [],
                types: {
                    //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                    'table': 'table-thead, table-tbody',
                    'table-thead': 'heading@4',
                    'table-tbody': 'table-row-divider@4',
                    'table-row-divider': 'table-row, divider',
                    'table-row': 'table-cell@4',
                    'table-cell': 'text',
                }
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get(`${env.MEDCHAT_API_URL}/api/territories`)
                    .then(response => {
                        this.territories = response.data;
                    })
                    .finally(() => {
                        // console.log('finally');
                        this.busy = false;
                    })
            },
            upload() {
                if (!this.validateForm('upload')) {
                    return;
                }
                if (this.form.busy || this.busy) {
                    return;
                }
                // @see https://github.com/cretueusebiu/vform/issues/97
                // @see https://github.com/therealparmesh/object-to-formdata
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    transformRequest: [data => objectToFormData(data)]
                };
                this.busy = true;
                this.form.post(`${env.MEDCHAT_API_URL}/api/zip-territories/upload`, config)
                    .then(response => {
                        this.fetchData();
                        this.resetFormValidation('upload');
                        this.form.reset();
                    })
            }
        }
    }
</script>
