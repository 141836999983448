<template>
    <v-container>

        <v-row justify="center" class="mt-3">
            <v-col cols="12"
                   md="8">

                <v-form @submit.prevent="submit"
                        class="mb-4">
                    <v-card outlined
                            flat
                            tile>
                        <v-toolbar color="primary"
                                   flat
                                   tile
                                   dark class="height-50">
                            <v-toolbar-title>
                                Edit My Profile
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="px-5 pt-7 pb-0">

                            <v-text-field v-model.trim="form.first_name"
                                          label="First name"
                                          placeholder=" "
                                          autocomplete="given-name"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('first_name')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <v-text-field v-model.trim="form.last_name"
                                          label="Last name"
                                          placeholder=" "
                                          autocomplete="family-name"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('last_name')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <v-text-field v-model.trim="form.email"
                                          type="email"
                                          label="Email address"
                                          placeholder=" "
                                          autocomplete="email"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('email')"
                                          :rules="[rules.required, rules.email]"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <v-text-field v-model.trim="form.phone"
                                          type="tel"
                                          label="Phone number"
                                          placeholder=" "
                                          autocomplete="tel"
                                          outlined
                                          dense
                                          v-mask="'###-###-####'"
                                          :error-messages="form.errors.get('phone')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <v-select v-model="form.timezone"
                                      label="Time zone"
                                      placeholder=" "
                                      :items="timezones"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('timezone')"
                                      :rules="[rules.required]"
                                      class="mb-0"
                                      required>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                        </v-card-text>
                        <v-card-actions class="px-5 pb-7">
                            <v-btn type="submit"
                                   :disabled="form.busy"
                                   color="secondary"
                                   class="font-weight-bold"
                                   x-large
                                   depressed
                                   tile
                                   block>
                                Update Profile
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

            </v-col>
        </v-row>

    </v-container>
</template>
<script>
    import {mapGetters} from 'vuex';
    import BrandLogo from '../../components/BrandLogo';
    import {Form} from 'vform';
    import dataService from '../../services/dataService';
    import SelectIcon from '../../components/forms/SelectIcon';
    import RequiredLabel from '../../components/forms/RequiredLabel';
    import formValidation from '../../plugins/formValidation';
    import _ from 'lodash';
    import axios from 'axios';
    import env from '../../../../config/dev.env';

    export default {
        components: {BrandLogo, RequiredLabel, SelectIcon},
        mixins: [formValidation],
        data() {
            return {
                form: new Form({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    timezone: '',
                }),
                timezonesObj: [],
            }
        },
        computed: {
            ...mapGetters([
                'timezones',
                'user',
            ]),
        },
        created() {
            this.initForm();
            this.fetchData();
        },
        methods: {
            fetchData() {
                if (this.busy) {
                    return;
                }
                this.busy = true;
                
                dataService.timezones()
                    .then(data => {
                        this.timezonesObj = _.orderBy(data, 'id');
                    })
            },
            initForm() {
                this.form.first_name = this.user.first_name;
                this.form.last_name = this.user.last_name;
                this.form.email = this.user.email;
                this.form.phone = this.user.phone;
                this.form.timezone = this.user.timezone;
            },
            async submit() {
                try {
                    //await this.form.put(`${env.MEDCHAT_API_URL}/api/users/${this.user.id}`)
                    // const userData = JSON.parse(localStorage.user);
                    // this.form.user = userData;
                    const updateUser = await this.form.put(`${env.MEDCHAT_API_URL}/api/users/${this.user.id}`)

                    //localStorage.user = JSON.stringify(updateUser.data);

                    await this.$store.dispatch('fetchUser');

                    ////this.$toast.success('Profile updated');

                    this.$router.push({name: 'about'});

                } catch (e) {
                    //this.$toast.error(e);
                }

            }
        }
    }
</script>
