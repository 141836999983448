<template>
    <v-container v-if="speaker"
                 class="pt-10">

        <v-row justify="center">
            <v-col cols="12"
                   md="10">
                <v-card outlined
                        flat
                        tile
                        class="mb-10">
                    <v-toolbar color="primary"
                               flat
                               tile
                               dark class="height-50">
                        <v-toolbar-title>
                            {{ speaker.full_name_designation }}
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text style="color: #666666 !important;">
                        <div class="d-flex">
                            <div class="mr-8">
                                <v-avatar size="140px">
                                    <v-img :src="speaker.headshot_url"/>
                                </v-avatar>
                            </div>
                            <div class="flex-grow-1">
                                <div v-if="speaker.job_title">
                                    {{ speaker.job_title }}
                                </div>

                                <div v-if="speaker.practice_name">
                                    {{ speaker.practice_name }}
                                </div>
                                <div v-if="speaker.city && speaker.state">
                                    {{ cityName }}, {{ stateName }}
                                </div>
                                <div v-if="speaker.biography"
                                     class="mt-8 mb-10"
                                     v-html="speaker.biography">
                                </div>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import BrandLogo from '../../components/BrandLogo';
import dataService from '../../services/dataService';
import _ from 'lodash';
import env from '../../../../config/dev.env';

export default {
    components: {BrandLogo},
    data() {
        return {
            busy: false,
            speaker: null,
            states: dataService.getStates(),
        }
    },
    computed: {
        cityName() {
            return this.speaker.city;
        },
        stateName() {
            if (!this.speaker.state) {
                return '';
            }
            const s = this.states.find(st => st.id === this.speaker.state.toUpperCase());
            if (s) {
                return s.name;
            }
            return this.speaker.state.toUpperCase();
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.getSpeaker();
            this.getSpeakerMeetingDates();
        },
        decorateSpeaker(speaker) {
            const state = dataService.getStates().find(s => s.id === speaker.state);
            if (state) {
                speaker.state_name = state.name;
            } else {
                speaker.state_name = speaker.state;
            }
            return speaker;
        },
        getSpeaker() {
            this.busy = true;
            axios.get(`${env.MEDCHAT_API_URL}/api/speakers/${this.$route.params.speakerID}?brand=${this.$root.brandCode}`)
                .then(response => {
                    this.busy = false;
                    this.speaker = this.decorateSpeaker(response.data);
                })
                .catch(err => {
                    console.error('Error', err.response.data);
                    //this.$router.push({name: 'speakers'});
                });
        },
        getSpeakerMeetingDates() {
            /*this.busy = true;
            let options = {
                params: {
                    speaker_id: this.$route.params.speakerID
                }
            };
            axios.get(`${env.MEDCHAT_API_URL}/api/meetings`, options)
                .then(response => {
                    this.meetingDates = response.data.meetingDates;
                })*/
        }
    }
}
</script>
