<template>
    <v-container class="pt-10"
                 v-if="loaded">
        <!-- <brand-logo class="mb-4">
        </brand-logo> -->

        <v-card outlined
                class="mb-4">
            <v-toolbar color="primary"
                       dark
                       flat
                       tile class="height-50">
                <v-toolbar-title class="Word-Break-Text">
                    <strong>Step 2:</strong> Select 3 Speakers and 3 Dates and Times
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="body-1 Common-Text-Color-Black">

                <p class="mb-2">
                    medChat programs may be scheduled between {{ startTime.format('h:mm') }} <span class="text-small-caps">am</span> and {{ endTime.format('h:mm') }} <span class="text-small-caps">pm</span> ET, Monday through Friday. The earliest you may schedule your medChat program is {{ soonest.format('dddd, MMMM D, YYYY') }}.
                </p>

                <p class="mb-2">
                    Please select at least {{ minSpeakers }} speakers in order of preference and date you are available. We will try to schedule your first-choice speaker, but a particular speaker cannot be guaranteed.
                </p>

                <p class="mb-2">
                    Please click SPEAKERS above to search speakers by specialty or state.
                </p>

            </v-card-text>
        </v-card>

        <v-form @submit.prevent="submit"
                ref="form">
            <v-card outlined
                    class="mb-4">

                <v-card-text>

                    <v-simple-table class="Schedule-Table">
                        <template>
                            <thead>
                            <tr>
                                <th class="text-left text--primary"
                                    style="vertical-align: bottom; font-size: 16px;"
                                    :style="speakerColumnWidth">
                                    <strong>Appointment &rarr;</strong><br/>
                                    <strong>Speaker &darr;</strong>
                                </th>
                                <th class="text-left pt-3"
                                    v-for="i in maxAppointments">
                                    <div class="text-no-wrap Common-Text-Color-Black-Light font--size-18 font-weight-regular mb-1">
                                        Option {{ i }}
                                    </div>
                                    <v-select label="Date"
                                              @change="checkAvailability"
                                              v-model="form.appointments[i - 1].date"
                                              :items="availableDates"
                                              item-disabled="disabled"
                                              item-text="name"
                                              item-value="id"
                                              outlined
                                              dense
                                              :rules="i === 1 ? [rules.required] : []"
                                              class="Word-Break-Text">
                                        <template v-slot:append>
                                            <select-icon>
                                            </select-icon>
                                        </template>
                                    </v-select>

                                    <v-select label="Time"
                                              @change="checkAvailability"
                                              :disabled="!form.appointments[i - 1].date"
                                              v-model="form.appointments[i - 1].time"
                                              :items="availableTimes"
                                              item-text="name"
                                              item-value="id"
                                              outlined
                                              dense
                                              :rules="i === 1 ? [rules.required] : []"
                                              class="Word-Break-Text">
                                        <template v-slot:item="slotProps">
                                            <span v-html="slotProps.item.label"></span>
                                        </template>
                                        <template v-slot:append>
                                            <select-icon>
                                            </select-icon>
                                        </template>
                                    </v-select>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(speaker, index) in form.speakers"
                                :key="speaker.id">
                                <td class="py-6">
                                    <div class="body-1">
                                        <strong>
                                            #{{ index + 1 }}
                                        </strong>
                                        {{ speaker.full_name_designation }} - {{ speaker.formatted_timezone }}
                                    </div>
                                    <div class="text-no-wrap">
                                        <v-btn :disabled="form.speakers.length < 2 || index === 0"
                                               @click.prevent="moveUp(speaker)"
                                               small>
                                            &uarr;
                                        </v-btn>
                                        <v-btn :disabled="form.speakers.length < 2 || index === (form.speakers.length - 1)"
                                               @click.prevent="moveDown(speaker)"
                                               small>
                                            &darr;
                                        </v-btn>
                                        <v-btn @click.prevent="removeSpeaker(speaker)"
                                               small>
                                            <v-icon color="error">
                                                <!-- mdi-delete -->
                                                {{  mdiDelete }}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </td>
                                <td v-for="appointment in form.appointments"
                                    class="text-center">
                                    <div v-if="checkingAvailability">
                                        <v-progress-circular indeterminate>
                                        </v-progress-circular>
                                    </div>
                                    <div v-if="!checkingAvailability">
                                        <!--<v-chip v-if="appointment.result.available.includes(speaker.id)"
                                                color="success"
                                                label>
                                            Available
                                        </v-chip>-->
                                        <template v-if="appointment.result.unavailable.includes(speaker.id)">
                                            <v-chip color="#d91046"
                                                    small
                                                    label class="Text-white">
                                                Booked
                                            </v-chip>
                                            <div class="tertiary--text" style="font-size: 12px;">
                                                The speaker you have selected currently has another medChat booked at this time.
                                            </div>
                                        </template>
                                        <!--<v-chip v-if="appointment.result.id === undefined"
                                                label>
                                            TBD
                                        </v-chip>-->
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot v-show="form.speakers.length < maxSpeakers && newSpeakers.length">
                            <tr>
                                <td class="pt-4">
                                    <v-select @change="addSpeaker"
                                              v-model="newSpeakerID"
                                              label="Select speaker"
                                              :items="newSpeakers"
                                              item-value="id"
                                              item-text="text"
                                              outlined
                                              dense
                                              hide-details>
                                        <template v-slot:append>
                                            <select-icon>
                                            </select-icon>
                                        </template>
                                    </v-select>
                                </td>
                            </tr>
                            </tfoot>
                        </template>
                    </v-simple-table>

                </v-card-text>

                <v-card-actions class="pa-4 pt-0 flex-column">

                    <p v-if="errorMessage"
                       class="error--text">
                        {{ errorMessage }}
                    </p>

                    <v-btn type="submit"
                           color="secondary"
                           class="font-weight-bold"
                           large
                           :disabled="!isFormValid"
                           depressed
                           tile
                           block>
                        Next
                    </v-btn>
                    <v-btn
                           :to="{name: 'wizard.topic'}"
                           color="#414141"
                           class="ml-0 font-weight-bold"
                           large
                           text
                           block>
                        Go Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import moment from 'moment';
import BrandLogo from '../../components/BrandLogo';
import speakerService from '../../services/speakerService';
import formValidation from '../../plugins/formValidation';
import SelectIcon from '../../components/forms/SelectIcon';
import { mdiDelete } from '@mdi/js';
import _ from 'lodash';

export default {
    components: {SelectIcon, BrandLogo},
    mixins: [formValidation],
    data() {
        return {
            mdiDelete,
            availableDates: [],
            availabilityResults: [],
            availableTimes: [],
            checkingAvailability: false,
            errorMessage: null,
            form: new Form({
                appointments: [],
                speakers: [],
            }),
            loaded: false,
            minAppointments: 3, // MYC-25
            maxAppointments: 3,
            minSpeakers: 3,
            maxSpeakers: 3,
            newSpeakerID: '',
            speakers: [],
        }
    },
    computed: {
        ...mapGetters([
            'blackoutDates',
            'brand',
            'brandSettings',
            'meetingType',
            'topic',
            'user',
            'wizard',
            'isAdmin',
            'isHCP',
            'isRep',
        ]),
        startTime() { // JC-58
            const dt = moment().tz('America/New_York').format('YYYY-MM-DD');
            return moment.tz(`${dt} ${this.brandSettings.time_of_day.start}`, 'America/New_York');
        },
        endTime() { // JC-58
            const dt = moment().tz('America/New_York').format('YYYY-MM-DD');
            // @see https://vorenus.atlassian.net/browse/MSPR-18?focusedCommentId=38110
            return moment.tz(`${dt} ${this.brandSettings.time_of_day.end}`, 'America/New_York');
            //return moment.tz(`${dt} ${this.brandSettings.time_of_day.end}`, 'America/New_York').subtract(this.brandSettings.meeting.scheduled_length, 'minutes');
        },
        appointments() {
            return _.filter(this.form.appointments, a => {
                return a.date && a.time;
            })
        },
        appointmentsHaveAvailableSpeakers() {
            let ret = false;
            this.appointments.forEach(appointment => {
                if (appointment.result && appointment.result.available && appointment.result.available.length > 0) {
                    ret = true;
                }
            });
            return ret;
        },
        events() {
            return _.map(this.appointments, appointment => {
                const startAt = moment.tz(`${appointment.date} ${appointment.time}`, 'America/New_York');
                const endAt = startAt.clone().add(this.brandSettings.meeting.scheduled_length, 'minutes');
                return {
                    id: appointment.id,
                    date: appointment.date,
                    time: appointment.time,
                    start_at: startAt.format(),
                    end_at: endAt.format(),
                    available: [],
                    unavailable: [],
                };
            });
        },
        isFormValid() {
            if (this.speakerCount < this.minSpeakers) {
                return false;
            }
            if (this.appointments.length < this.minAppointments) {
                return false;
            }

            return true;
        },
        newSpeakers() {
            return _.filter(this.speakers, s => {
                return !this.form.speakers.map(s => s.id).includes(s.id);
            })
        },
        selectedSpeakers() {
            return _.map(this.form.speakers, s => {
                return this.getSpeaker(s);
            })
        },
        soonest() {
            let soonest = moment().tz('America/New_York').add(this.brandSettings.meeting.schedule_days_in_future, 'day').startOf('day');
            while ([0, 6].includes(soonest.day())) {
                soonest.add(1, 'day');
            }
            return soonest;
        },
        speakerColumnWidth() {
            if (this.maxAppointments === 1) {
                return 'width: 50%;';
            } else {
                return 'width: 30%;';
            }
        },
        speakerCount() {
            return this.form.speakers.length;
        },
    },
    created() {
        this.fetchData();
    },
    methods: {
        appointmentSet(index) {
            return this.form.appointments[index].date && this.form.appointments[index].time;
        },
        getAppointment(id) {
            return _.find(this.form.appointments, a => a.id === id);
        },
        getAppointmentIndex(id) {
            return _.findIndex(this.form.appointments, a => a.id === id);
        },
        getSpeaker(speakerID) {
            return _.find(this.speakers, s => s.id === speakerID);
        },
        getSpeakerIndex(speakerID) {
            return _.findIndex(this.form.speakers, s => s.id === speakerID);
        },
        addSpeaker(speakerID) {
            let currentLeftScroll = window.pageXOffset;
            let currentTopScroll = window.pageYOffset;
            if (!this.form.speakers.map(s => s.id).includes(speakerID)) {
                const speaker = this.getSpeaker(speakerID);
                if (speaker) {
                    this.form.speakers.push(speaker);
                }
            }
            this.newSpeakerID = '';
            this.checkAvailability();
            
        },
        removeSpeaker(speaker) {
            const index = this.getSpeakerIndex(speaker.id);
            if (this.form.speakers[index] === undefined) {
                return;
            }
            this.form.speakers.splice(index, 1);
            this.checkAvailability();
        },
        swap(arr, x, y) {
            // https://gist.github.com/eerohele/5195815
            if (arr[x] === undefined || arr[y] === undefined) {
                return arr;
            }
            const a = x > y ? y : x;
            const b = x > y ? x : y;
            return [
                ...arr.slice(0, a),
                arr[b],
                ...arr.slice(a + 1, b),
                arr[a],
                ...arr.slice(b + 1)
            ];
        },
        moveUp(speaker) {
            const index = this.getSpeakerIndex(speaker.id);
            this.form.speakers = this.swap(this.form.speakers, index, index - 1);
        },
        moveDown(speaker) {
            const index = this.getSpeakerIndex(speaker.id);
            this.form.speakers = this.swap(this.form.speakers, index, index + 1);
        },
        checkAvailability() {
            const events = this.events;
            const speakers = this.form.speakers;
            if (speakers.length && events.length) {
                this.checkingAvailability = true;
                const speakerIDs = speakers.map(s => s.id);
                speakerService.checkAvailability(speakerIDs, events)
                    .then(data => {
                        this.availabilityResults = data;
                        data.forEach(result => {
                            const appointment = this.getAppointment(result.id);
                            const index = this.getAppointmentIndex(result.id);
                            if (appointment) {
                                this.form.appointments[index].result = result;
                            }
                        });
                    })
                    .finally(() => {
                        this.checkingAvailability = false;
                    })
            }
        },
        isDateAllowed(date) {
            if (this.blackoutDates.includes(date.format('YYYY-MM-DD'))) { // MCHAT-449
                return false;
            }
            const dow = date.format('dddd').toLowerCase();
            return this.brandSettings.days_of_week[dow];
        },
        initDates() {
            this.availableDates = [];
            const date = this.soonest.clone();
            const maxDate = date.clone().add(60, 'days');
            do {
                const dt = {
                    id: date.format('YYYY-MM-DD'),
                    name: date.format('dddd, MMMM D, YYYY'),
                    allowed: this.isDateAllowed(date),
                    //disabled: !this.isDateAllowed(date),
                };
                if (dt.allowed) {
                    this.availableDates.push(dt);
                }
                date.add(1, 'day');
            } while (date.isSameOrBefore(maxDate, 'date'));
        },
        initTimes() {
            this.availableTimes = [];
            const time = moment(this.startTime);
            const endTime = moment(this.endTime);
            do {
                this.availableTimes.push({
                    id: time.format('HH:mm:00'),
                    name: time.format('h:mm A [ET]'), // NNI-44
                    time: time,
                    label: `${time.format('h:mm [<span class="caption text-small-caps">]A[</span>] [ET]')}`
                });
                time.add(15, 'minutes');
            } while (time.isSameOrBefore(endTime));
        },
        isSpeakerEqual() {
            let wizardSpeakers = this.wizard.speakers;
            let equalSpeakers = this.speakers.filter(function (s1) {
                return wizardSpeakers.some(function (s2) {
                    return s1.id === s2.id;
                });
            });
            return equalSpeakers;
        },
        initForm() {
            this.initDates();
            this.initTimes();

            if (this.isSpeakerEqual().length == 3 && this.wizard.appointments && this.wizard.appointments.length) {
                //console.log('Loading appointments from VueX');
                this.wizard.appointments.forEach(a => {
                    this.form.appointments.push(_.clone(a));
                });
                for (let i = this.form.appointments.length; i < this.maxAppointments; i++) {
                    this.form.appointments.push({
                        id: (i + 1),
                        date: '',
                        time: '',
                        allowed: true,
                        result: {
                            available: [],
                            unavailable: [],
                        }
                    })
                }

                if (this.wizard.speakers && this.wizard.speakers.length) {
                    //console.log('Loading speakers from VueX');
                    this.wizard.speakers.forEach(s => {
                        let speaker = this.getSpeaker(s.id);
                        this.form.speakers.push(speaker);
                    })
                }

            } else if (this.isSpeakerEqual().length >= 1 && this.isSpeakerEqual().length <= 2 && this.wizard.appointments && this.wizard.appointments.length) {
                this.wizard.appointments.forEach(a => {
                    this.form.appointments.push(_.clone(a));
                });
                for (let i = this.form.appointments.length; i < this.maxAppointments; i++) {
                    this.form.appointments.push({
                        id: (i + 1),
                        date: '',
                        time: '',
                        allowed: true,
                        result: {
                            available: [],
                            unavailable: [],
                        }
                    })
                }
                
                this.isSpeakerEqual().forEach(s => {
                    let speaker = this.getSpeaker(s.id);
                    this.form.speakers.push(speaker);
                })

            } else {
                //console.log('Creating new appointments.');
                for (let i = 0; i < this.maxAppointments; i++) {
                    this.form.appointments.push({
                        id: (i + 1),
                        date: '',
                        time: '',
                        allowed: true,
                        result: {
                            available: [],
                            unavailable: [],
                        }
                    })
                }
            }

            //this.appointments = this.wizard.appointments;
        },
        fetchData() {
            if (this.wizard
                && this.wizard.brand
                && this.wizard.topic
            ) {
                speakerService.getSpeakersForTopic(this.wizard.topic.id)
                    .then(data => {
                        data.forEach(speaker => {
                            speaker.text = `${speaker.full_name_designation} - ${speaker.formatted_timezone}`;
                        });
                        this.speakers = _.orderBy(data, ['slug_reverse'], ['asc']);
                        this.initForm();
                        this.loaded = true;
                    })
                return;
            }
            this.$root.startWizard();
        },
        submit() {
            this.errorMessage = null;
            if (!this.validateForm()) {
                return;
            }
            if (this.speakerCount < this.minSpeakers) {
                this.errorMessage = `You must select at least ${this.minSpeakers} speakers.`;
                return;
            }
            if (!this.form.appointments.length) {
                this.errorMessage = 'You must select a time slot.';
                return;
            }
            const appointments = this.appointments;

            if (appointments.length) {
                appointments.forEach((appointment, index) => {
                    const start = moment.tz(appointment.date + ' ' + appointment.time, 'America/New_York'); // NNI-44
                    const end = start.clone().add(this.brandSettings.meeting.scheduled_length, 'minutes');
                    appointment.start = start.format();
                    appointment.end = end.format();
                    appointments[index] = appointment;
                    //console.log(appointment.start, appointment.end);
                });
                this.$store.commit('SET_WIZARD_SPEAKER_CONFIRMED', false); // AM-17
                this.$store.commit('SET_WIZARD_APPOINTMENTS', appointments);
                this.$store.commit('SET_WIZARD_SPEAKERS', this.form.speakers);
                if (this.isRep) {
                    console.log(this.isRep);
                    this.$router.push({name: 'wizard.hcps'});
                } else if (this.isHCP && this.wizard.hcp) {
                    this.$router.push({name: 'wizard.review_hcp'});
                } else {
                    this.$router.push({name: 'register.hcp'});
                }
            } else {
                //this.$toast.error('No appointments');
            }
        }
    }
}
</script>
