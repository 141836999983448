<template>
    <v-container class="pt-10">

        <v-row>
            <v-col cols="12"
                   md="4">
                <!-- <brand-logo brand-code="005"
                            class="mb-7">
                </brand-logo> -->

                <form @submit.prevent="submit">
                    <v-card outlined
                            flat
                            tile>
                        <v-toolbar color="primary"
                                   flat
                                   tile
                                   dark class="height-50">
                            <v-toolbar-title>
                                Search for Speakers
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pb-0">

                            <v-text-field v-model="form.last_name"
                                          placeholder="Speaker's last name"
                                          outlined
                                          dense
                                          single-line>
                            </v-text-field>

                            <v-select v-model="form.specialty"
                                      :items="specialties"
                                      outlined
                                      single-line
                                      dense
                                      label="Select specialty">
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-select v-model="form.state"
                                      :items="states"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      single-line
                                      dense
                                      label="Select state">
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-select v-model="form.topic_id"
                                      :items="topics"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      single-line
                                      dense
                                      label="Select topic">
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                        </v-card-text>
                        <v-card-actions class="pt-0 d-block text-center">
                            <v-btn @click.prevent="reset"
                                   color="secondary"
                                   class="font-weight-bold mb-4"
                                   depressed
                                   tile
                                   block>
                                Reset
                            </v-btn>

                            <div class="tertiary--text font--size-14 mb-2">
                                Speakers are paid consultants of Sanofi.
                            </div>
                        </v-card-actions>
                    </v-card>
                </form>
            </v-col>
        </v-row>

        <v-row
            >
            <v-col cols="12">
                <v-btn text
                       color="speaker_link"
                       class="font-weight-bold"
                       :disabled="page === 1"
                       @click.prevent="prevPage">
                    <!-- <v-img class="mr-1" src="/images/p-arrow.png"/>  -->
                    <span class="font-20">&laquo;</span>Previous
                </v-btn>
                <v-btn text
                       color="speaker_link"
                       class="font-weight-bold Next-Float-Right"
                       :disabled="page === totalPages"
                       @click.prevent="nextPage">
                    Next<span class="font-20">&raquo;</span>
                    <!-- <v-img class="ml-1" src="/images/n-arrow.png"/> -->
                </v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12"
                   :sm="columns.small"
                   :md="columns.medium"
                   v-for="speaker in speakersOnPage"
                   :key="speaker.id"
                   class="align-stretch">
                <speaker-panel :speaker="speaker">
                </speaker-panel>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import dataService from '../../services/dataService';
import _ from 'lodash';
import SpeakerPanel from '../../components/speakers/SpeakerPanel';
import BrandLogo from '../../components/BrandLogo';
import SelectIcon from '../../components/forms/SelectIcon';

export default {
    components: {SelectIcon, BrandLogo, SpeakerPanel},
    data() {
        return {
            columns: {
                small: 6,
                medium: 6,
            },
            busy: false,
            form: new Form({
                last_name: '',
                specialty: '',
                state: '',
                topic_id: '',
            }),
            allspeakerswithoutfilter: [],
            allspeakers: [],
            speakers: [],
            topics: [],
            treatments: [],
            page: 1,
            perPage: 6,
            specialties: [], // MPEX-10
            states: [],
        }
    },
    computed: {
        ...mapGetters([
            'brands',
        ]),
        filtered() {
            return !(!this.form.last_name
                && !this.form.specialty
                && !this.form.state
                && !this.form.topic_id);
        },
        filter() {
            return {
                last_name: this.form.last_name.trim().toLowerCase(),
                specialty: this.form.specialty,
                state: this.form.state.trim().toLowerCase(),
                topic_id: this.form.topic_id,
            }
        },
        filteredSpeakers() {
            if (!this.filtered) {
                return this.allspeakers;
            }
            const filter = this.filter;
            let allFilterSpeakers = this.allspeakerswithoutfilter.filter(s => {
                if (filter.last_name && !s.last_name.trim().toLowerCase().includes(filter.last_name)) {
                    return false;
                }
                if (filter.specialty && s.specialty !== filter.specialty) {
                    return false;
                }
                if (filter.state && (!s.state || s.state.trim().toLowerCase() !== filter.state)) {
                    return false;
                }
                if (filter.topic_id && !s.topic_ids.includes(filter.topic_id)) {
                    return false;
                }

                return true;
            });

            let speakerObject  = {};
            let i;
            let filterSpeakers = [];
            for( i=0; i < allFilterSpeakers.length; i++) {
                speakerObject[allFilterSpeakers[i].full_name] = allFilterSpeakers[i];
            }
            for(i in speakerObject) {
                filterSpeakers.push(speakerObject[i]);
            }
            return filterSpeakers;
        },
        speakersOnPage() {
            const start = this.perPage * (this.page - 1);
            const end = start + this.perPage;
            return this.filteredSpeakers.slice(start, end);
        },
        totalPages() {
            let speakerperpage;
            if (!this.filtered) {
                speakerperpage = this.allspeakers;
            } else {
                speakerperpage = this.speakers;
            }
            if (speakerperpage.length % this.perPage !== 0) {
                return Math.ceil(speakerperpage.length / this.perPage);
            }
            return Math.floor(speakerperpage.length / this.perPage);
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        prevPage() {
            if (this.page > 1) {
                this.page--;
            }
        },
        nextPage() {
            if (this.page < this.totalPages) {
                this.page++;
            }
        },
        async initTreatments() {
            await this.$store.dispatch('fetchBrands');
            this.brands.forEach(b => {
                if (b.code === 'sanofi_unbranded') {
                    this.treatments.push({id: b.id, name: 'CONDITION NAME'});
                }
                if (b.code === 'menquadfi') {
                    this.treatments.push({id: b.id, name: 'CONDITION NAME'});
                }
            })
        },
        fetchData() {
            if (this.busy) {
                return;
            }
            this.busy = true;
            //this.initTreatments();

            //new change
            // dataService.getTopics('005')
            //     .then(data => {
            //         this.topics = data;
            //     })
            
            dataService.getMultipleTopics(dataService.BRAND_CODES)
                .then(data => {
                    this.topics = _.orderBy(data, 'id');
                })

            return Promise.all([
                dataService.getSpeakersForBrands()
                    .then(data => {
                        // console.log(data);
                        let Allspeakers = _.orderBy(data.filter(s => {
                            return s.is_type_primary && s.status === 'Active';
                        }), ['full_name_reverse'], ['asc']);
                        this.allspeakerswithoutfilter = Allspeakers;

                        let speakerObject1  = {};
                        let i;
                        for( i=0; i < Allspeakers.length; i++) {
                            speakerObject1[Allspeakers[i].full_name] = Allspeakers[i];
                        }
                        for(i in speakerObject1) {
                            this.speakers.push(speakerObject1[i]);
                        }

                        let speakerObject  = {};
                        for( i=0; i < this.speakers.length; i++) {
                            speakerObject[this.speakers[i].id] = this.speakers[i];
                        }
                        for(i in speakerObject) {
                            this.allspeakers.push(speakerObject[i]);
                        }

                        this.specialties = _.uniq(_.sortBy(data.map(s => s.specialty)).filter(s => !_.isNull(s)));

                        // MLYNX-12
                        const stateIDs = _.uniq(_.sortBy(data.map(s => s.state)).filter(s => !_.isNull(s)));
                        this.states = dataService.getStates().filter(state => stateIDs.includes(state.id));
                    }),
            ])
                .finally(() => {
                    this.busy = false;
                })


        },
        reset() {
            this.form.last_name = '';
            this.form.specialty = '';
            this.form.state = '';
            this.form.topic_id = '';
        },
        submit() {

        }
    }
}
</script>
