<template>
    <v-card-text>

        <v-text-field v-model="form.first_name"
                      label="First Name"
                      :error-messages="form.errors.get('first_name')">
        </v-text-field>

        <v-text-field v-model="form.last_name"
                      label="Last Name"
                      :error-messages="form.errors.get('last_name')">
        </v-text-field>

        <v-text-field v-model="form.email"
                      label="Email"
                      :error-messages="form.errors.get('email')">
        </v-text-field>

        <v-text-field v-model="form.password"
                      type="password"
                      label="Password"
                      placeholder="Leave this blank unless you want to change it"
                      autocomplete="new-password"
                      :error-messages="form.errors.get('password')">
        </v-text-field>

        <v-text-field v-model="form.phone"
                      v-mask="'###-###-####'"
                      label="Phone"
                      autocomplete="new-phone"
                      :error-messages="form.errors.get('phone')">
        </v-text-field>

        <v-select v-model="form.state"
                  label="State"
                  :items="states"
                  item-text="name"
                  item-value="id"
                  :error-messages="form.errors.get('state')">
        </v-select>

        <v-text-field v-model="form.zip_code"
                      v-mask="'#####'"
                      label="ZIP code"
                      autocomplete="new-zip"
                      :error-messages="form.errors.get('zip_code')">
        </v-text-field>

        <v-select v-model="form.timezone"
                  label="Time zone"
                  :items="timezones"
                  item-text="name"
                  item-value="id"
                  :error-messages="form.errors.get('timezone')">
        </v-select>

        <v-radio-group v-model="form.type"
                       label="Type">
            <v-radio v-for="type in types"
                     :key="type.id"
                     :value="type.id"
                     :label="type.name">
            </v-radio>
        </v-radio-group>

        <template v-if="form.type === 'hcp'">
            <v-select v-model="form.professional_designation"
                      label="Degree"
                      :items="professionalDesignations"
                      item-text="name"
                      item-value="id"
                      :error-messages="form.errors.get('professional_designation')">
            </v-select>

            <v-select v-model="form.specialty"
                      label="Specialty"
                      :items="specialties"
                      item-text="name"
                      item-value="id"
                      :error-messages="form.errors.get('specialty')">
            </v-select>

            <v-text-field v-model="form.npi"
                          v-mask="'##########'"
                          label="NPI"
                          autocomplete="new-npi"
                          :error-messages="form.errors.get('npi')">
            </v-text-field>
        </template>

        <template v-if="form.type === 'sales_rep'">
            <v-switch v-model="form.email_verified"
                        label="Email Is Verified"
                        class="mb-0">
            </v-switch>

            <v-switch v-model="form.is_password_set"
                        label="Password is set">
            </v-switch>
        </template>

    </v-card-text>
</template>
<script>
    import dataService from '../../services/dataService';

    export default {
        name: 'user-form',
        props: {
            form: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                professionalDesignations: dataService.professionalDesignations,
                specialties: dataService.specialties,
                states: dataService.getStates(),
                timezones: dataService.timezones(),
                types: [
                    {id: 'admin', name: 'Admin'},
                    {id: 'sales_rep', name: 'Sales Rep'},
                    {id: 'hcp', name: 'HCP'},
                ]
            }
        }
    }
</script>
