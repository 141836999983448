<template>
    <v-container class="pt-10">

        <v-card outlined
                class="Resources__Card mb-8">
            <v-toolbar color="primary"
                       flat
                       tile
                       dark class="height-50">
                <v-toolbar-title>
                    Download medChat Content
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>

                <p class="mb-4 pt-3">
                    Materials here are to be used only in the event of technical malfunction. They should not be otherwise distributed, duplicated, or used in detailing.
                </p>
                <v-list tile
                        outlined class="view-list">
                    <!-- <v-list-item :href="url('medchatforsanofi-module-1')"
                                 target="_blank">
                        <v-list-item-icon>
                            <v-icon>
                                {{mdiFilePdfOutline}}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            Understanding the Burden and Impact of Invasive Meningococcal Disease
                        </v-list-item-title>
                    </v-list-item> -->
                    <!-- <v-divider></v-divider> -->
                    <v-list-item :href="url('medchatforsanofi-module-2')"
                                 target="_blank"> 
                        <v-list-item-icon>
                            <v-icon>
                                {{mdiFilePdfOutline}}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            MenQuadfi<sup>&reg;</sup>: Sanofi’s Latest Innovation in MenACWY Vaccination
                        </v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-card-text>
        </v-card>

        <download-app-card>
        </download-app-card>
    </v-container>
</template>
<script>

import DownloadAppCard from '../../components/resources/DownloadAppCard';
import SupportDialog from '../../components/resources/SupportDialog';
import {mdiFilePdfOutline} from '@mdi/js'


export default {
    data() {
        return {
            mdiFilePdfOutline
        }
    },
    components: {SupportDialog, DownloadAppCard},
    computed: {

    },
    methods: {
        url(filename) {
	        return `/pdfs/${filename}.pdf`;
        }
    }
}
</script>
