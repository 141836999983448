import dataService from '../services/dataService';

/**
 * Vee-Validate, Vuelidate, or Simple Vue Validator
 * @see https://medium.com/thetribeio/vue-js-form-validation-fd9113e09701
 *
 * @see https://vuejs.org/v2/guide/mixins.html
 * @see https://vuetifyjs.com/en/components/forms/#validation-with-submit-clear
 *
 * @see Vuelidate - https://vuelidate.js.org/
 * @see VeeValidate - https://logaretm.github.io/vee-validate/
 *
 */
export default {
    data: () => ({
       rules: {
           required: value => !!value || 'Required.',
           email: value => {
               const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
               return pattern.test(value) || 'Invalid email.'
           },
       }
    }),
    methods: {
        validateForm (form = 'form') {
            return this.$refs[form].validate();
        },
        resetForm (form = 'form') {
            this.$refs[form].reset();
        },
        resetFormValidation (form = 'form') {
            this.$refs[form].resetValidation();
        },
    }
}
