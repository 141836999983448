<template>
    <v-container class="mt-10">

        <v-row justify="center">
            <v-col cols="12"
                   md="7">

                <v-form @submit.prevent="submit"
                        ref="form"
                        class="mb-4">
                    <v-card outlined
                            flat
                            tile>
                        <v-toolbar color="primary"
                                   flat
                                   tile
                                   dark class="height-50 mb-5">
                            <v-toolbar-title>
                                Change My Password
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="px-5 pt-7 pb-0">

                            <v-text-field v-model.trim="form.current_password"
                                          type="password"
                                          label="Current password"
                                          placeholder=" "
                                          autocomplete="current-password"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('current_password')"
                                          :rules="[rules.required]"
                                          class="mb-0"
                                          required>
                            </v-text-field>

                            <v-text-field v-model.trim="form.password"
                                          type="password"
                                          label="New password"
                                          placeholder=" "
                                          autocomplete="new-password"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('password')"
                                          :rules="[rules.required]"
                                          class="mb-0"
                                          required>
                            </v-text-field>

                            <v-text-field v-model.trim="form.password_confirmation"
                                          type="password"
                                          label="Confirm password"
                                          placeholder=" "
                                          autocomplete="new-password"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('password_confirmation')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                            </v-text-field>

                            <p>
                                Your password must be at least 8 characters long and contain letters and numbers.
                            </p>

                        </v-card-text>
                        <v-card-actions class="px-5 pb-7">
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   :disabled="form.busy"
                                   x-large
                                   depressed
                                   tile
                                   block>
                                Change Password
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <v-alert type="success"
                         color="#23004c"
                         dark
                         v-if="message">
                    {{ message }}
                </v-alert>

            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import BrandLogo from '../../components/BrandLogo';
import {Form} from 'vform';
import dataService from '../../services/dataService';
import SelectIcon from '../../components/forms/SelectIcon';
import RequiredLabel from '../../components/forms/RequiredLabel';
import formValidation from '../../plugins/formValidation';
import _ from 'lodash';
import axios from 'axios';
import env from '../../../../config/dev.env';

export default {
    components: {BrandLogo, RequiredLabel, SelectIcon},
    mixins: [formValidation],
    data() {
        return {
            form: new Form({
                current_password: '',
                password: '',
                password_confirmed: '',
            }),
            message: ''
        }
    },
    computed: {
        ...mapGetters([
            'isHCP',
            'user',
        ])
    },
    created() {
        this.initForm();
    },
    methods: {
        initForm() {
        },
        async submit() {
            if (this.form.busy) {
                return;
            }
            try {
                // const userData = JSON.parse(localStorage.user);
                // this.form.user = userData;
                await this.form.put(`${env.MEDCHAT_API_URL}/api/user/password`)

                await this.$store.dispatch('fetchUser');

                this.message = 'Your password has been changed!';

                this.form.reset();
                this.$refs.form.resetValidation();

                /*if (this.isHCP) {
                    this.$router.push({name: 'home'});
                } else {
                    this.$router.push({name: 'about'});
                }*/

            } catch (e) {
                //this.$toast.error(e);
            }

        }
    }
}
</script>
