<template>
    <v-container fluid>

        <v-card>
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    mChat Meetings
                </v-toolbar-title>

            </v-toolbar>
            <v-card-text>

                <v-skeleton-loader v-if="busy"
                                   type="table"
                                   :types="types">

                </v-skeleton-loader>

                <v-simple-table v-if="!busy">
                    <thead>
                    <tr>
                        <th>
                            ID
                        </th>
                        <th>
                            Scheduled Start At
                        </th>
                        <th>
                            Status
                        </th>
                        <th>
                            Topic
                        </th>
                        <th>
                            Speaker
                        </th>
                        <th>
                            Sales Rep
                        </th>
                        <th>
                            HCPs
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="meeting in meetings"
                        :key="meeting.id">
                        <td>
                            <v-btn :href="`${$root.mchatURL}/meeting/${meeting.id}`"
                               target="_blank">
                                {{ meeting.id }}
                            </v-btn>
                        </td>
                        <td>
                            <div v-if="meeting.meeting_scheduled_at">
                                {{ meeting.meeting_scheduled_at | datetime('YYYY-MM-DD h:mm a z') }}
                            </div>
                        </td>
                        <td>
                            {{ meeting.status }}
                        </td>
                        <td>
                            <div v-if="meeting.topic">
                                {{ meeting.topic.name }}
                            </div>
                        </td>
                        <td>
                            <div v-if="meeting.speaker">
                                {{ meeting.speaker.name }}
                            </div>
                        </td>
                        <td>
                            <div v-if="meeting.salesRep">
                                {{ meeting.salesRep.name }}
                            </div>
                        </td>
                        <td>
                            <div v-if="meeting.meetingHCPs.length">
                                {{ meeting.meetingHCPs.length | pluralize('HCP') }}
                            </div>
                        </td>

                    </tr>
                    </tbody>
                </v-simple-table>

            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import axios from 'axios';
    import _ from 'lodash';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';

    export default {
        data() {
            return {
                busy: false,
                meetings: [],
                types: {
                    //"table": "table-heading, table-thead, table-tbody, table-tfoot",
                    'table': 'table-thead, table-tbody',
                    'table-thead': 'heading@4',
                    'table-tbody': 'table-row-divider@4',
                    'table-row-divider': 'table-row, divider',
                    'table-row': 'table-cell@7',
                    'table-cell': 'text',
                }
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                this.busy = true;
                axios.get(`${env.MEDCHAT_API_URL}/api/meetings`)
                    .then(response => {
                        this.meetings = _.orderBy(response.data, ['id'], ['desc']);
                    })
                    .finally(() => {
                        this.busy = false;
                    })
            }
        }
    }
</script>
