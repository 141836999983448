<template>
    <section class="Footer-Section disabled_isi">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <!-- <brand1-isi :pinned="pinned"></brand1-isi> -->
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>
<script>
//import Brand1Isi from './Brand1-ISI'; // by vigneswaran

export default {
    //components: {Brand1Isi},
    props: {
        pinned: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
