<template>
    <v-container class="pt-9">
        <v-row justify="center">
            <v-col cols="12"
                   md="6">
                <v-form @submit.prevent="submit"
                        ref="form"
                        autocomplete="off">
                    <v-card flat
                            tile
                            outlined>
                        <v-toolbar color="primary"
                                   tile
                                   flat
                                   dark class="height-50">
                            <v-toolbar-title>
                                Log In
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pt-12 pb-0 px-5">
                            <v-text-field v-model="form.email"
                                          label="Email address"
                                          placeholder=" "
                                          outlined
                                          dense
                                          autocomplete="off"
                                          :rules="[rules.required, rules.email]"
                                          :error-messages="form.errors.get('email')"
                                          clear-icon="mb-2"
                                          required>
                            </v-text-field>
                            <v-text-field v-model="form.password"
                                          type="password"
                                          label="Password"
                                          placeholder=" "
                                          outlined
                                          dense
                                          autocomplete="off"
                                          :rules="[rules.required]"
                                          :error-messages="form.errors.get('password')"
                                          required>
                            </v-text-field>

                            <v-checkbox v-model="form.remember"
                                        class="mt-0 re-me"
                                        label="Remember me"/>

                            <div v-if="error"
                                 class="error--text">
                                {{ error }}
                            </div>
                        </v-card-text>
                        <v-card-actions class="d-block px-5">
                            <v-btn type="submit"
                                   dark
                                   block
                                   tile
                                   depressed
                                   x-large
                                   color="secondary"
                                   class="mb-2 font-weight-bold ">
                                Log in
                            </v-btn>

                            <div class="d-flex justify-space-between">
                                <v-btn :to="{name: 'register.rep'}"
                                       text
                                       color="primary"
                                       class="font-weight-bold px-2">
                                    Sign Up
                                </v-btn>

                                <v-btn :to="{name: 'password.request'}"
                                       text
                                       color="primary"
                                       class="font-weight-bold px-2">
                                    Reset Password
                                </v-btn>
                            </div>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapGetters, Store} from 'vuex';
import formValidation from '../../plugins/formValidation';
import {Form} from 'vform';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';
import axios from 'axios';

export default {
    mixins: [formValidation],
    data() {
        return {
            error: null,
            form: new Form({
                email: '',
                password: '',
                remember: true,
            }),
            usernew: {},
        }
    },
    computed: {
        ...mapGetters([
            'authenticated',
            'user',
        ]),
        config() {
            return process.env;
        },
        redirectTo() {
            return this.$route.query.redirect;
        }
    },
    created() {
        this.initLogin();
    },
    methods: {
        initLogin() {
            this.$store.commit('LOGOUT');
            // Since we are on seperate domains we cannot use the csrf-cookie, we are utilizing token based auth 
            // axios.get(`/sanctum/csrf-cookie`).then(response => {
            //     // Login...
            // });
        },
        async submit() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.error = null;
            try {
                let headers = {'Content-Type': 'application/json;charset=utf-8'}
                const response = await this.form.post(`${env.MEDCHAT_API_URL}/login`, {headers});
                if (response.status > 300) {
                    return;
                }
                let access_token = response.data.access_token;
                // console.log(access_token);
                await this.$store.dispatch('setAccessToken', access_token);
                // axios.defaults.headers.common = {'Authorization': `Bearer ${response.data.access_token}`};
                
                
                await this.$store.dispatch('fetchUser');

                if (this.user && !this.user.is_password_set) {
                    this.$router.push({name: 'set_password'});
                } else {
                    let target = {name: 'about'}; // MSPR-29
                    if (this.redirectTo) {
                        target = this.redirectTo;
                    }
                    // console.log("target", target);
                    this.$router.push(target);
                }
            } catch (e) {
                // console.log("Error: ", e.response);
                if (e.response && e.response.data && e.response.data.message) {
                    if (e.response.data.errors && e.response.data.errors.email) {
                        // this.$toast.error(e.response.data.errors.email[0]);
                    } else {
                        // this.$toast.error(e.response.data.message);
                        this.error = e.response.data.message;
                    }
                }
            }
        }
    }
}
</script>
