<template>
    <v-form @submit.prevent="submit"
            class="fill-height" ref="form">
        <v-card class="fill-height">
            <v-toolbar color="primary"
                       dark class="height-50">
                <v-toolbar-title>
                    Import HCP
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="dismiss"
                       icon>
                    <v-icon>
                        fal fa-times
                    </v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text class="pt-7 pb-0">

                <v-text-field v-model.trim="form.first_name"
                              placeholder=" "
                              outlined
                              dense
                              :rules="[rules.required]"
                              :error-messages="form.errors.get('first_name')"
                              class="mb-4"
                              required>
                    <template v-slot:label>
                        <required-label label="First name"/>
                    </template>
                </v-text-field>

                <v-text-field v-model.trim="form.last_name"
                              placeholder=" "
                              outlined
                              dense
                              :rules="[rules.required]"
                              :error-messages="form.errors.get('last_name')"
                              class="mb-4"
                              required>
                    <template v-slot:label>
                        <required-label label="Last name"/>
                    </template>
                </v-text-field>

                <v-select v-model.trim="form.professional_designation"
                          placeholder=" "
                          :items="professionalDesignations"
                          item-value="name"
                          item-text="name"
                          outlined
                          dense
                          :error-messages="form.errors.get('professional_designation')"
                          :rules="[rules.required]"
                          class="mb-4"
                          :menu-props="{offsetY: true}"
                          required>
                    <template v-slot:label>
                        <required-label label="Degree"/>
                    </template>
                    <template v-slot:append>
                        <select-icon>
                        </select-icon>
                    </template>
                </v-select>

                <v-select v-model.trim="form.specialty"
                          placeholder=" "
                          :items="specialties"
                          item-value="name"
                          item-text="name"
                          outlined
                          dense
                          :error-messages="form.errors.get('specialty')"
                          :rules="[rules.required]"
                          class="mb-4"
                          :menu-props="{offsetY: true}"
                          required>
                    <template v-slot:label>
                        <required-label label="Specialty"/>
                    </template>
                    <template v-slot:append>
                        <select-icon>
                        </select-icon>
                    </template>
                </v-select>

                <v-text-field v-model.trim="form.npi"
                              placeholder=" "
                              label="NPI"
                              autocomplete="new-npi"
                              v-mask="'##########'"
                              outlined
                              dense
                              :error-messages="form.errors.get('npi')"
                              pattern="[0-9]+"
                              class="mb-4">
                </v-text-field>

                <v-text-field v-model.trim="form.email"
                              placeholder=" "
                              type="email"
                              autocomplete="email"
                              outlined
                              dense
                              :error-messages="form.errors.get('email')"
                              :rules="[rules.required, rules.email]"
                              class="mb-4"
                              required>
                    <template v-slot:label>
                        <required-label label="Email"/>
                    </template>
                </v-text-field>

                <v-text-field v-model.trim="form.phone"
                              placeholder=" "
                              type="tel"
                              autocomplete="tel"
                              v-mask="'###-###-####'"
                              outlined
                              dense
                              :error-messages="form.errors.get('phone')"
                              :rules="[rules.required]"
                              class="mb-4"
                              required>
                    <template v-slot:label>
                        <required-label label="Phone"/>
                    </template>
                </v-text-field>

                <v-text-field v-model.trim="form.practice_name"
                              placeholder=" "
                              outlined
                              dense
                              :error-messages="form.errors.get('practice_name')"
                              :rules="[rules.required]"
                              class="mb-4"
                              required>
                    <template v-slot:label>
                        <required-label label="Practice name"/>
                    </template>
                </v-text-field>

                <v-radio-group v-model="form.address"
                               placeholder=" "
                               @change="selectAddress"
                               outlined
                               dense
                               :error-messages="form.errors.get('address')"
                               :rules="[rules.required]"
                               class="mb-4"
                               required>
                    <template v-slot:label>
                        <required-label label="Address"/>
                    </template>
                    <v-radio v-for="address in addresses"
                             :key="address.id"
                             :value="address"
                             :label="address.one_line"
                             @change="selectAddress">
                    </v-radio>
                </v-radio-group>

                <template v-if="form.address">

                    <v-text-field v-model.trim="form.street1"
                                  placeholder=" "
                                  autocomplete="street-address"
                                  outlined
                                  dense
                                  :error-messages="form.errors.get('street1')"
                                  :rules="[rules.required]"
                                  class="mb-4"
                                  required>
                        <template v-slot:label>
                            <required-label label="Practice address 1"/>
                        </template>
                    </v-text-field>

                    <v-text-field v-model.trim="form.street2"
                                  label="Practice address 2"
                                  placeholder=" "
                                  outlined
                                  dense
                                  :error-messages="form.errors.get('street2')"
                                  class="mb-4">
                    </v-text-field>

                    <v-text-field v-model.trim="form.city"
                                  placeholder=" "
                                  autocomplet="address-level-2"
                                  outlined
                                  dense
                                  :error-messages="form.errors.get('city')"
                                  :rules="[rules.required]"
                                  class="mb-4"
                                  required>
                        <template v-slot:label>
                            <required-label label="City"/>
                        </template>
                    </v-text-field>

                    <v-select v-model="form.state"
                              placeholder=" "
                              autocomplete="address-level1"
                              :items="states"
                              item-text="name"
                              item-value="id"
                              outlined
                              dense
                              :error-messages="form.errors.get('state')"
                              :rules="[rules.required]"
                              class="mb-4"
                              :menu-props="{offsetY: true}"
                              required>
                        <template v-slot:label>
                            <required-label label="State"/>
                        </template>
                        <template v-slot:append>
                            <select-icon>
                            </select-icon>
                        </template>
                    </v-select>

                    <v-text-field v-model.trim="form.zip_code"
                                  placeholder=" "
                                  autocomplete="postal-code"
                                  v-mask="'#####'"
                                  outlined
                                  dense
                                  :error-messages="form.errors.get('zip_code')"
                                  :rules="[rules.required]"
                                  class="mb-4"
                                  required>
                        <template v-slot:label>
                            <required-label label="ZIP code"/>
                        </template>
                    </v-text-field>
                </template>

                <v-select v-model="form.timezone"
                          placeholder=" "
                          :items="timezones"
                          item-text="name"
                          item-value="id"
                          outlined
                          dense
                          :error-messages="form.errors.get('timezone')"
                          :rules="[rules.required]"
                          class="mb-4"
                          :menu-props="{offsetY: true}"
                          required>
                    <template v-slot:label>
                        <required-label label="Time zone"/>
                    </template>
                    <template v-slot:append>
                        <select-icon>
                        </select-icon>
                    </template>
                </v-select>

            </v-card-text>
            <v-card-actions>
                <v-btn type="submit"
                       color="secondary"
                       dark
                       class="font-weight-bold"
                       :disabled="form.busy"
                       x-large
                       tile
                       depressed
                       block>
                    Submit
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import {uniqBy} from 'lodash';
import RequiredLabel from '../../../components/forms/RequiredLabel';
import formValidation from '../../../plugins/formValidation';
import SelectIcon from '../../../components/forms/SelectIcon';
import geocodeService from '../../../services/geocodeService';
import dataService from '../../../services/dataService';
import axios from 'axios'
import env from '../../../../../config/dev.env';

export default {
    components: {SelectIcon, RequiredLabel},
    mixins: [formValidation],
    props: {
        hcp: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: new Form({
                address: null,
                first_name: this.hcp.first_name,
                last_name: this.hcp.last_name,
                specialty: this.hcp.specialty,
                professional_designation: this.hcp.professional_designation,
                email: this.hcp.email,
                phone: this.hcp.phone,
                practice_name: this.hcp.practice_name ? this.hcp.practice_name : '',
                street1: this.hcp.street1,
                street2: null,
                city: this.hcp.city,
                state: this.hcp.state,
                zip_code: this.hcp.zip_code,
                timezone: this.hcp.timezone,
                npi: this.hcp.npi,
                external_id: this.hcp.jnj_id,
            }),
        }
    },
    computed: {
        ...mapGetters([
            'professionalDesignations',
            'specialties',
            'states',
            'timezones',
            'user',
        ]),
        addresses() {
            const addresses = [];
            addresses.push({
                id: 0,
                line_1: 'Other',
                address: 'Other',
                one_line: 'Other',
            })
            return uniqBy(addresses, 'id');
        },
        // organizations() {
        //     if (this.hcp.parent_hcos__v && this.hcp.parent_hcos__v.length) {
        //         return this.hcp.parent_hcos__v.filter(hco => {
        //             return hco.parent_hco_status__v === 'A'
        //         })
        //     }
        //     return [];
        // },
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            if (this.busy) {
                return;
            }
            this.busy = true;
            
        },
        dismiss() {
            // console.log('dismissing dialog');
            this.$emit('dismiss');
        },
        resetAddressFields() {
            this.form.street1 = null;
            this.form.street2 = null;
            this.form.city = null;
            this.form.state = null;
            this.form.zip_code = null;
        },
        async selectAddress() {
            // if (this.form.address) {
            //     const a = this.form.address;
            //     this.resetAddressFields();
            //     if (a.id !== 0) {
            //         this.form.street1 = a.line_1;
            //         this.form.street2 = a.line_2;
            //         this.form.city = a.city;
            //         this.form.state = a.state
            //         this.form.zip_code = a.zip_code;
            //         try {
            //             await geocodeService.geocodeAddress(this.form);
            //         } catch (e) {
            //         }
            //     }
            // } else {
            //     //this.resetAddressFields();
            // }
        },
        async submit() {
            if (this.form.busy) {
                return;
            }
            if (!this.validateForm()) {
                return;
            }
            if (!this.form.address) {
                this.form.errors.set('address', 'Select an address');
                return;
            }
            this.form.practice_name = this.form.practice_name.substring(0, 99);
            this.form.startProcessing();
            try {
                await geocodeService.geocodeAddress(this.form)
                // const userData = JSON.parse(localStorage.user);
                // this.form.user = userData;
                // console.log(this.form);
                const {data: hcp} = await this.form.put(`${env.MEDCHAT_API_URL}/api/hcps/${this.hcp.id}`)
                this.$store.commit('ADD_WIZARD_HCP', hcp);
                this.$emit('dismiss');
            } catch (e) {
            } finally {
                this.form.busy = false;
            }
        }
    }
}
</script>
