<template>
        <v-icon>
            {{ mdiMenuSwap }}
        </v-icon>
</template>
<script>
    import {mdiMenuSwap} from '@mdi/js';

    export default {
        name: 'select-icon',
        data() {
            // console.log(mdiMenuSwap);
            return {
                mdiMenuSwap
            }
        }
    }
</script>
