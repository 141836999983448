<template>
    <v-container v-if="created"
                 class="pt-10">
        <v-row justify="center">

            <v-col cols="12"
                   md="8">

                <v-card flat
                        tile
                        class="mb-10 Register-Disclaimer pa-4">
                    The information you provide will be used by Sanofi, our affiliates, and our service providers for your registration and participation in the medChat program. You may withdraw from the program by calling <span class="text-no-wrap"><span class="text-no-wrap">1-877-238-8500</span></span>. Our <a href="https://connexion360.com/index.php/privacy-policy/" target="_blank text-decoration-underline">Privacy Policy</a> further governs the use of the information you provide. By providing this information and selecting the REGISTER button, you indicate that you read, understand, and agree to these terms.
                </v-card>

                <v-form @submit.prevent="submit"
                        ref="form"
                        class="mb-4">
                    <v-card outlined>
                        <v-toolbar color="primary"
                                   dark class="height-50">
                            <v-toolbar-title v-if="isWebhookForm || redirectTo">
                                HCP Registration Information
                            </v-toolbar-title>
                            <v-toolbar-title v-else>
                                <strong>Step 3:</strong> HCP Registration Information
                            </v-toolbar-title>
                        </v-toolbar>

                        <v-card-text class="pt-7 pb-0">

                            <v-text-field v-model.trim="form.first_name"
                                        label = "First name"
                                        placeholder=" "
                                        autocomplete="given-name"
                                        outlined
                                        dense
                                        :rules="[rules.required]"
                                        :error-messages="form.errors.get('first_name')"
                                        class="mb-4"
                                        required>
                                <template v-slot:label>
                                    <required-label label="First name"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.last_name"
                                          placeholder=" "
                                          autocomplete="family-name"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('last_name')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Last name"/>
                                </template>
                            </v-text-field>

                            <v-select v-model.trim="form.professional_designation"
                                      placeholder=" "
                                      :items="professionalDesignations"
                                      item-value="name"
                                      item-text="name"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('professional_designation')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Degree"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-select v-model.trim="form.specialty"
                                      placeholder=" "
                                      :items="specialties"
                                      item-value="name"
                                      item-text="name"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('specialty')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Specialty"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-text-field v-model.trim="form.npi"
                                          placeholder=" "
                                          label="NPI"
                                          autocomplete="new-npi"
                                          v-mask="'##########'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('npi')"
                                          pattern="[0-9]+"
                                          class="mb-4">
                            </v-text-field>

                            <v-text-field v-model.trim="form.email"
                                          placeholder=" "
                                          type="email"
                                          autocomplete="email"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('email')"
                                          :rules="[rules.required, rules.email]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Email"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.phone"
                                          placeholder=" "
                                          type="tel"
                                          autocomplete="tel"
                                          v-mask="'###-###-####'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('phone')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Phone"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.address1"
                                          placeholder=" "
                                          autocomplete="street-address"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('address1')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="Practice address 1"/>
                                </template>
                            </v-text-field>

                            <v-text-field v-model.trim="form.address2"
                                          label="Practice address 2"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('address2')"
                                          class="mb-4">
                            </v-text-field>

                            <v-text-field v-model.trim="form.city"
                                          placeholder=" "
                                          autocomplet="address-level-2"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('city')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="City"/>
                                </template>
                            </v-text-field>

                            <v-select v-model="form.state"
                                      placeholder=" "
                                      autocomplete="address-level1"
                                      :items="states"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('state')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="State"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <v-text-field v-model.trim="form.zip_code"
                                          placeholder=" "
                                          autocomplete="postal-code"
                                          v-mask="'#####'"
                                          outlined
                                          dense
                                          :error-messages="form.errors.get('zip_code')"
                                          :rules="[rules.required]"
                                          class="mb-4"
                                          required>
                                <template v-slot:label>
                                    <required-label label="ZIP code"/>
                                </template>
                            </v-text-field>

                            <v-select v-model="form.timezone"
                                      placeholder=" "
                                      :items="timezones"
                                      item-text="name"
                                      item-value="id"
                                      outlined
                                      dense
                                      :error-messages="form.errors.get('timezone')"
                                      :rules="[rules.required]"
                                      class="mb-4"
                                      required>
                                <template v-slot:label>
                                    <required-label label="Time zone"/>
                                </template>
                                <template v-slot:append>
                                    <select-icon>
                                    </select-icon>
                                </template>
                            </v-select>

                            <div class="font-italic">
                                <span class="error--text">*</span>Required field
                            </div>

                        </v-card-text>
                        <v-card-actions class="px-4">
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   :disabled="form.busy"
                                   x-large
                                   tile
                                   depressed
                                   block>
                                Register
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>

        </v-row>

        <template v-if="$root.edifyDebug && isWebhookForm">
            <v-card v-if="hcpForm"
                    class="mb-4">
                <v-toolbar color="primary"
                           dark class="height-50">
                    <v-toolbar-title>
                        HCP Form
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p>
                        This is the data received from the HTTP POST. It will not appear if you perform a GET request. The <code>verified</code> property will be true if the HMAC of the timestamp and token match the signature, which means no fields were manipulated in transit.
                    </p>
                    <pre>{{ hcpForm }}</pre>
                </v-card-text>
            </v-card>

            <v-card v-if="sampleForm"
                    class="mb-4">
                <v-toolbar color="primary"
                           dark class="height-50">
                    <v-toolbar-title>
                        Sample Form
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p>
                        This is the sample data passed when you press the "Register HCP From Edify" button at the bottom of the screen. Look at the signature now starts that with <code>{{ sampleForm.signature.substring(0, 5) }}</code>. When you press <strong>Register HCP From Edify</strong>, you will see that signature as the HCP Form signature.
                    </p>
                    <pre>{{ sampleForm }}</pre>
                </v-card-text>
            </v-card>
        </template>
    </v-container>
</template>
<script src="https://www.google.com/recaptcha/api.js?render=6Lfqrm4aAAAAAIV44iRqDkYFd6hddAl_S1r9h9LH"></script>
<script>
import {mapGetters} from 'vuex';
import BrandLogo from '../../components/BrandLogo';
import {Form} from 'vform';
import {find} from 'lodash';
import SelectIcon from '../../components/forms/SelectIcon';
import RequiredLabel from '../../components/forms/RequiredLabel';
import formValidation from '../../plugins/formValidation';
import axios from 'axios';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';


export default {
    components: {BrandLogo, RequiredLabel, SelectIcon},
    mixins: [formValidation],
    data() {
        return {
            created: false,
            form: new Form({
                first_name: this.getField('first_name'),
                last_name: this.getField('last_name'),
                email: this.getField('email'),
                phone: this.getField('phone'),
                timezone: this.getField('timezone'),
                professional_designation: this.getField('professional_designation'),
                specialty: this.getField('specialty'),
                address1: this.getField('address1'),
                address2: this.getField('address2'),
                city: this.getField('city'),
                state: this.getField('state'),
                zip_code: this.getField('zip_code'),
                npi: this.getField('npi'),
                token: this.getField('token'),
                timestamp: this.getField('timestamp'),
                signature: this.getField('signature'),
                jnj_id: this.getField('jnj_id'),
                janrain_id: this.getField('janrain_id'),
                webhook: false,
                recaptcha_token: null, // JC-38
            }),
        }
    },
    computed: {
        ...mapGetters([
            'isHCP',
            'professionalDesignations',
            'specialties',
            'states',
            'timezones',
            'user',
        ]),
        redirectTo() {
            return this.$route.query.redirect;
        },
        isWizard() { // MSPR-25
            return this.$route.name === 'register.hcp';
        },
        isWebhookForm() {
            return this.$route.name === 'webhook.register.hcp';
        },
        hcpForm() {
            return window.hcpForm;
        },
        sampleForm() {
            return window.sampleForm;
        },
        specialty() {
            if (!this.hcpForm) {
                return null;
            }
            return find(this.specialties, s => s.name === this.hcpForm.specialty || s.id === this.hcpForm.specialty);
        }
    },
    async created() {
        await this.initForm();
        await this.fetchData();
    },
    methods: {
        getField(fieldName, defaultValue = '') {
            return window.hcpForm ? window.hcpForm[fieldName] : defaultValue
        },
        async initForm() {
            //await axios.get('/sanctum/csrf-cookie').catch();

            // MSPR-25 if the user is an HCP we don't need to make them register again.
            if (this.isWizard && this.user && this.isHCP) {
                const {data: hcp} = await axios.get(`${env.MEDCHAT_API_URL}/api/hcps/${this.user.mchat_hcp_id}`)
                this.$store.commit('SET_WIZARD_HCP', hcp);
                await this.$router.push({name: 'wizard.review_hcp'});
                return;
            }
            if (this.hcpForm) {
                this.form.webhook = true;
                this.form.specialty = this.specialty ? this.specialty.name : '';
                this.form.professional_designation = this.hcpForm.professional_designation;
            }
            this.created = true;
        },
        fetchData() {
            if (this.busy) {
                return;
            }
            this.busy = true;
        },
        async submit() {
            this.error = null;

            if(!this.$refs.form.validate()) {
                return;
            }

            //grecaptcha.ready(async () => {
                try {
                    //this.form.recaptcha_token = await grecaptcha.execute(process.env.RECAPTCHA_SITE_KEY, {action: 'register_hcp'});
                    let config = {
                    headers: {'content-type': 'application/json;charset=utf-8',
                    'X-Requested-With': 'XMLHttpRequest' }
                }
                    await this.form.post(`${env.MEDCHAT_API_URL}/api/register/hcp`, config).then((res) => {
                        // console.log(res);
                        // Error handling needs to be revisited at some point,
                        
                        // Working around the axios error rejection here
                        if(res.data.errors) {
                            this.error = 'There was an error submitting the form.'
                        }
                        else if (res.data.access_token) {
                            this.$store.commit('SET_ACCESS_TOKEN', res.data.access_token)
                            axios.defaults.headers.common = {'Authorization': `Bearer ${res.data.access_token}`};
                        }
                        })
                        this.form.login = true;

                    

                    await this.$store.dispatch('fetchUser');

                    if (this.isWebhookForm) { // MSPR-25
                        await this.$router.push({name: 'wizard.start'});
                        return;
                    }
                    if (this.redirectTo) {
                        await this.$router.push(this.redirectTo);
                        return;
                    }

                    const {data: hcp} = await axios.get(`${env.MEDCHAT_API_URL}/api/hcps/${this.user.mchat_hcp_id}`)
                    this.$store.commit('SET_WIZARD_HCP', hcp);
                    await this.$router.push({name: 'wizard.review_hcp'});
                } catch (e) {
                    // console.log(e)
                    if (e.response && e.response.data) {
                        //this.$toast.error(e.response.data.message);
                        this.errors = e.response.data;
                    } else {
                        //this.$toast.error(e.message);
                    }
                }
            //});
        }
    }
}
</script>
