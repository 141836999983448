<template>
    <v-card>
        <v-toolbar color="primary"
                   flat
                   tile
                   dark class="height-50">
            <v-toolbar-title>
                Other Sanofi Rep Attending
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>

            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Phone
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <sales-rep-row v-for="rep in meeting.meetingSalesReps"
                                   :key="rep.id"
                                   :id="rep.rep_id">
                    </sales-rep-row>
                    </tbody>
                </template>
            </v-simple-table>

        </v-card-text>
        <v-card-actions v-if="!alreadyAttending">
            <v-btn color="secondary"
                   block
                   dark
                   tile
                   depressed
                   class="font-weight-bold"
                   @click.prevent="addCurrentRep">
                Add myself to this event
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import {mapGetters} from 'vuex';
import SalesRepRow from './SalesRepRow';
import {Form} from 'vform';
import dataService from '../../services/dataService'
import env from '../../../../config/dev.env';

export default {
    components: {SalesRepRow},
    props: {
        meeting: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            form: new Form({})
        }
    },
    computed: {
        ...mapGetters([
            'user',
        ]),
        alreadyAttending() {
            if (!this.user) {
                return false;
            }
            if (this.user.is_rep || this.user.is_admin) {
                if (this.meeting.rep_id === this.user.mchat_rep_id) {
                    return true;
                }
                if (this.meeting.meetingSalesReps.map(r => r.rep_id).includes(this.user.mchat_rep_id)) {
                    return true;
                }
            }
            if (this.meeting.meetingHCPs.map(h => h.hcp_id).includes(this.user.mchat_hcp_id)) {
                return true;
            }
            return false;
        },
        meetingID() {
            return parseInt(this.$route.params.meetingID);
        },
    },
    created() {

    },
    methods: {
        addCurrentRep() {
            this.form.post(`${env.MEDCHAT_API_URL}/api/meetings/${this.meeting.id}/add-current-rep`)
                .then(response => {
                    this.$emit('updated', response.data);
                    //this.$toast.success('You have been added to this meeting.');
                })
                .catch(err => {
                    if (err.response && err.response.data && err.response.data.error) {
                        //this.$toast.error(err.response.data.error);
                    }
                })
        }
    }
}
</script>
