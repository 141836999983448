// https://github.com/w3c/IntersectionObserver/tree/master/polyfill
import 'intersection-observer'; // IE-11 PolyFill
import Vue from 'vue'
//import App from './components/App'
import App from '../src/views/components/App'
import router from '../src/views/router'
import store from '../src/views/store'
import '../src/views/plugins';
import vuetify from '../src/views/plugins/vuetify'
import {i18n} from '../src/views/plugins'
import '../src/views/layouts';
import {isObject} from 'lodash';
import {mapGetters} from 'vuex';
import '../src/views/components'
import themes from '../src/views/themes';
import _ from 'lodash';

import './assets/main.css'
import './assets/base.css'
import './assets/home.css'
import './assets/_about.scss'
import './assets/_alerts.scss'
import './assets/app.scss'
import './assets/_app_bar.scss'
import './assets/cards.scss'
import './assets/_fonts.scss'
import './assets/_footer.scss'
import './assets/_forms.scss'
import './assets/_isi.scss'
import './assets/lists.scss'
import './assets/_meeting.scss'
import './assets/_meeting_list.scss'
import './assets/_meeting_table.scss'
import './assets/_my_meetings.scss'
import './assets/_register.scss'
import './assets/_resources.scss'
import './assets/_speakers.scss'
import './assets/_text.scss'
import './assets/_transitions.scss'
import './assets/_wizard.scss'
import './assets/_variables.scss'
import './assets/_vuetify_variables.scss'

import 'vuetify/dist/vuetify.min.css';
import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
import '@fortawesome/fontawesome-pro/scss/light.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import '@fortawesome/fontawesome-pro/scss/solid.scss';


Vue.config.productionTip = false;

store.dispatch('initStore')
    .then(() => {
        new Vue({
            i18n,
            router,
            store,
            vuetify,
            data() {
                return {
                    drawer: false,
                    themes: themes,
                }
            },
            computed: {
                ...mapGetters([
                    'brandCode', // MPEX-6
                    'authenticated',
                    'user',
                    'accessToken'
                ]),
                isPasswordSet() {
                    return this.user && this.user.is_password_set;
                },
                isEmailVerified() {
                    return this.user && this.user.email_verified_at;
                },
                layout() {
                    return (this.$route.meta.layout || 'unbranded') + '-layout';
                },
                title() {
                    return (this.$route.meta.title || appName)
                },
                isHomeRoute() {
                    return this.$route.name === 'home';
                },
                isUnbranded() {
                    return this.$route.meta && this.$route.meta.layout === 'unbranded';
                },
                isAdmin() {
                    return this.$route.meta && this.$route.meta.layout === 'admin';
                },
                isBranded() {
                    return !this.isUnbranded && !this.isAdmin;
                },
                isSanofi() {
                    if (this.isUnbranded) {
                        return false;
                    }
                    return this.brandCode === this.$root.brandCode;
                },
                mchatURL() {
                    return process.env.mchatURL;
                },
                sanofi() {
                    return this.themes.sanofi;
                },
                unbranded() {
                    return this.themes.unbranded;
                },
                edifyDebug() { // MSPR-30
                    if (isObject(process.env)) {
                        return !!process.env.edify_debug;
                    }
                    return false;
                },
                isProduction() { // MSPR-25
                    if (isObject(process.env)) {
                        return process.env.env === 'production'
                    }
                    return false;
                },
                theme() {
                    if (this.isUnbranded) {

                    }
                    let theme = this.unbranded;
                    if (this.isSanofi) {
                        theme = this.sanofi;
                    } else if (this.isBrand2) {
                        theme = this.brand2;
                    }
                    _.forOwn(theme, (value, key) => {
                        this.$vuetify.theme.themes.light[key] = value;
                    })
                    /*this.$vuetify.theme.themes.light.primary = theme.primary;
                    this.$vuetify.theme.themes.light.secondary = theme.secondary;
                    this.$vuetify.theme.themes.light.tertiary = theme.tertiary;
                    this.$vuetify.theme.themes.light.quaternary = theme.quaternary;
                    this.$vuetify.theme.themes.light.accent = theme.accent;
                    this.$vuetify.theme.themes.light.info = theme.info;
                    this.$vuetify.theme.themes.light.anchor = theme.anchor;
                    this.$vuetify.theme.themes.light.resource_button = theme.resource_button;*/

                    return this.brandCode;
                }
            },
            methods: {
                toggleDrawer() {
                    this.drawer = !this.drawer;
                },
                startWizard(replace) {
                    if (this.brandCode) {
                        if (replace) {
                            this.$router.replace({name: 'wizard.start'});
                        } else {
                            this.$router.push({name: 'wizard.start'});
                        }
                    }
                }
            },
            render: h => h(App)
        }).$mount('#app');
    });
