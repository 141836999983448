<template>
    <v-app id="inspire"
           :class="$root.theme">
        <!-- <system-bar>
        </system-bar> -->
        <app-bar>
        </app-bar>

        <v-main class="ML__Content">
            <transition name="page"
                        mode="out-in">
                <router-view class="ML__Content_Main"></router-view>
            </transition>
        </v-main>

        <brand1-footer v-if="pinISI"
                       pinned
                       style="border-top: 1px solid #333333; position: fixed; z-index: 100000; width: 100%; bottom: 0; height: 150px; overflow: hidden;">
        </brand1-footer>

        <brand1-footer v-intersect="{handler: unpinImportantSafetyInformation, options: {threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]}}">
        </brand1-footer>

        <company-footer>
        </company-footer>
    </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import AppBar from '../components/AppBar';
import NavigationDrawer from '../components/NavigationDrawer';
import CompanyFooter from './partials/CompanyFooter';
// import Brand1Footer from './partials/Brand1Footer'; // by vigneswaran
// import SystemBar from '../components/SystemBar'; // by vigneswaran

export default {
    components: {CompanyFooter, NavigationDrawer, AppBar},
    data() {
        return {
            bottomVisible: false,
        }
    },
    computed: {
        ...mapGetters([
            'authenticated',
            'pinISI',
            'user',
        ]),
        footer() {
            return `brand1-footer`;
        },
    },
    methods: {
        unpinImportantSafetyInformation(entries, observer, isIntersecting) {
            if (this.pinISI === isIntersecting) {
                //console.log('unpinImportantSafetyInformation', isIntersecting, entries[0].intersectionRatio);
                this.bottomVisible = isIntersecting;
                this.$store.commit('SET_PIN_ISI', !isIntersecting);
            }
        }
    }
}
</script>
