import axios from 'axios';
import dataService from './dataService';
import env from '../../../config/dev.env';
import _ from 'lodash';

export default {
  getSpeakersForTopic(topicID) {
    return axios.get(`${env.MEDCHAT_API_URL}/api/topics/${topicID}/speakers`)
      .then(response => response.data);
  },
  checkAvailability(speakerIDs, events) {
    const form = {
      speakers: _.isArray(speakerIDs) ? speakerIDs : [speakerIDs],
      events: _.isArray(events) ? events : [events],
    };
    return axios.post(`${env.MEDCHAT_API_URL}/api/mchat/speakers/availability`, form)
      .then(response => response.data);
  }
}
