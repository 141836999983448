<template>
    <v-app class="Layout--Unbranded"
           :class="$root.theme">
        <v-app-bar app
                   color="secondary"
                   dark>
            <div class="mr-4">
                <v-img src="/images/mchat_white.png"
                       width="54px"/>
            </div>

            <v-btn text
                   :to="{name: 'admin.users'}"
                   class="mr-3">
                Users
            </v-btn>

            <v-btn text
                   :to="{name: 'admin.requests'}"
                   class="mr-3">
                Meeting Requests
            </v-btn>

            <!--<v-btn text
                   :to="{name: 'admin.meetings'}"
                   class="mr-3">
                mChat Meetings
            </v-btn>-->

            <v-btn text
                   :to="{name: 'admin.reps'}">
                Sales Reps
            </v-btn>

            <v-btn text
                   :to="{name: 'admin.speakers'}">
                Speakers
            </v-btn>

            <!--<v-btn text
                   :to="{name: 'admin.territories'}">
                Territories
            </v-btn>-->

            <v-btn text
                   @click.prevent="clearCache">
                Clear Cache
            </v-btn>

            <v-spacer></v-spacer>

            <v-btn text
                   @click.prevent="logout">
                Log out
            </v-btn>
        </v-app-bar>

        <v-main class="ML_Content">
            <transition name="page"
                        mode="out-in">
                <router-view class="ML__Content_Main"></router-view>
            </transition>
        </v-main>

        <company-footer>
        </company-footer>
    </v-app>
</template>

<script>
    import AppBar from '../components/AppBar';
    import NavigationDrawer from '../components/NavigationDrawer';
    import CompanyFooter from './partials/CompanyFooter';
    import dataService from '../services/dataService';
    import env from '../../../config/dev.env';

    export default {
        components: {CompanyFooter, NavigationDrawer, AppBar},
        methods: {
            clearCache() {
                axios.post(`${env.MEDCHAT_API_URL}/api/cache/clear`)
                    .then(() => {
                        //this.$toast.success('Cache cleared');
                    })
                    .catch(err => {
                        //this.$toast.error('Could not clear cache');
                    })
            },
            logout() {
                this.$store.dispatch('logout')
                    .finally(() => {
                        this.$router.push({name: 'login'});
                    })
            },
        }
    }
</script>
