export default {
    unbranded: {
        primary: '#23004c', // confirmed
        secondary: '#7a00e6', // confirmed
        tertiary: '#2c3a61', // confirmed
        accent: '#4066aa',
        info: '#d9ecf7', // confirmed
        anchor: '#002f87',
        resource_button: '#b2e2f5',
        isi_text: '#626262',
        speaker_link: '#23004c',
        meeting_button: '#009fdf',
    },
    sanofi: {
        primary: '#23004c', // confirmed
        secondary: '#7a00e6', // confirmed
        tertiary: '#2c3a61', // confirmed
        accent: '#4066aa',
        info: '#d9ecf7', // confirmed
        anchor: '#002f87',
        resource_button: '#fbe4cc',
        isi_text: '#626262',
        speaker_link: '#23004c',
        meeting_button: '#00a59d',
    },
}
