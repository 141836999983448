<template>
    <v-dialog v-model="dialog"
              width="566">
        <template v-slot:activator="{on}">
            <v-btn v-on="on"
                   block
                   depressed
                   tile
                   x-large
                   color="#fff3e1"
                   class="mb-3 font-weight-bold secondary--text">
                Get Support
            </v-btn>
        </template>
        <v-card flat
                tile>
            <v-toolbar flat
                       tile
                       dark
                       color="primary" class="height-50">
                <v-toolbar-title>
                    <span class="support_test">Support</span>
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0 font--size-16">
                <div class="py-7" style="border-top: 1px solid #666666; border-bottom: 1px solid #666666;">
                    <p>
                        Please contact ConneXion360 at <span class="text-no-wrap">1-877-238-8500</span> between <br/>8 <span class="text-small-caps">am</span> and 6 <span class="text-small-caps">pm</span> ET.
                    </p>
                    <p class="mb-0">
                        For reschedule or cancellation requests, please have your event ID listed on the event confirmation email ready.
                    </p>
                </div>
            </v-card-text>
            <v-card-actions class="py-5 px-6">
                <v-btn @click.prevent="dialog = false"
                       color="secondary"
                       class="font-weight-bold"
                       tile
                       x-large
                       block>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        data() {
            return {
                dialog: false,
            }
        },
    }
</script>
