<template>
    <span>
        <span>{{ label }}</span><!--
        --><span class="error--text">*</span>
    </span>
</template>
<script>
    /**
     * How to remove the spacing between span tags - add comments
     * @see https://stackoverflow.com/questions/2519923/get-rid-of-spaces-between-spans
     */
    export default {
        props: {
            label: {
                type: String,
                required: true,
            },
        }
    }
</script>
