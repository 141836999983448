import axios from 'axios';
import dataService from './dataService';
import env from '../../../config/dev.env';

export default {
    buildAddress(form) {
        const parts = [];
        if (form.street1) parts.push(form.street1);
        //if (form.street2) parts.push(form.street2);
        if (form.city) parts.push(form.city);
        if (form.state) parts.push(form.state);
        if (form.zip_code) parts.push(form.zip_code);
        return parts.join(' ');
    },
    /**
     * https://www.geocod.io/docs/#single-address
     * @returns {Promise<void>}
     * @param form
     * @param accuracy
     */
    geocodeAddress(form, accuracy = 0.8) {
        const address = this.buildAddress(form);
        return axios.post(`${env.MEDCHAT_API_URL}/api/geocode`, {address})
            .then(response => {
                if (response.data && response.data.results && response.data.results.length) {
                    const result = response.data.results[0];
                    if (result && result.accuracy >= accuracy) {
                        const address = result.address_components;
                        if (address) {
                            if (address.number && address.formatted_street) {
                                form.street1 = `${address.number} ${address.formatted_street}`
                            }
                            form.city = address.city;
                            form.state = address.state;
                            form.zip_code = address.zip;
                        }
                        const fields = result.fields;
                        if (fields && fields.timezone && fields.timezone.name) {
                            form.timezone = fields.timezone.name;
                        }
                    }
                }
                return response.data;
            });
    }
}
