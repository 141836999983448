<template>
    <v-img :src="`/images/footer-logo.png`"
           :max-width="maxWidth"
           contain
           :alt="`${localBrandCode} logo`"/>
</template>
<script>
export default {
    props: {
        brandCode: {
            type: Number,
            default: '005',
        },
        maxWidth: {
            type: Number,
            default: 293,
        }
    },
    computed: {
        localBrandCode() {
            return this.brandCode ? this.brandCode : this.$root.brandCode;
        }
    }
}
</script>
