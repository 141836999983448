<template>
    <div v-cloak
         :class="routeName">
        <transition name="page"
                    mode="out-in">
            <component :is="$root.layout">

            </component>
        </transition>
    </div>
</template>

<script>
import slugify from '../plugins/slugify';
const appName = process.env.APP_NAME;

export default {
    metaInfo() {
        return {
            title: appName,
            titleTemplate: `%s · ${appName}`
        }
    },
    computed: {
      routeName() {
          return slugify(`route-${this.$route.name}`);
      }
    },
    data() {
        return {}
    },
    created() {
        //console.log('App.vue created()');
    },
}
</script>
