<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12"
                   sm="8"
                   md="6">

                <v-form @submit.prevent="submit">
                    <v-card outlined
                            flat
                            tile>
                        <v-toolbar color="primary"
                                   dark
                                   flat class="height-50">
                            <v-toolbar-title>
                                Reset Your Password
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pb-0">
                            <v-text-field v-model="form.email"
                                          type="email"
                                          label="Email"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :rules="[rules.required, rules.email]"
                                          :error-messages="form.errors.get('email')"
                                          required>
                            </v-text-field>

                            <v-text-field v-model="form.password"
                                          type="password"
                                          autocomplete="new-password"
                                          label="Password"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :rules="[rules.required]"
                                          :error-messages="form.errors.get('password')"
                                          persistent-hint
                                          required>
                            </v-text-field>

                            <v-text-field v-model="form.password_confirmation"
                                          type="password"
                                          autocomplete="new-password"
                                          label="Confirm Password"
                                          placeholder=" "
                                          outlined
                                          dense
                                          :rules="[rules.required]"
                                          :error-messages="form.errors.get('password_confirmation')"
                                          required>
                            </v-text-field>

                            <p class="caption mb-0">
                                Your password must be at least 8 characters long and contain letters and numbers.
                            </p>

                        </v-card-text>
                        <v-card-actions>
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   :disabled="form.busy"
                                   x-large
                                   tile
                                   depressed
                                   block>
                                Reset Password
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <v-alert type="success"
                         color="#23004c"
                         class="mt-3"
                         v-if="message">
                    <span>
                        {{ message }}
                    </span>
                    <router-link :to="{name: 'login'}"
                                 class="white--text text-decoration-underline">
                        Log in now.
                    </router-link>
                </v-alert>

            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import {Form} from 'vform';
import validationService from '../../../services/validationService';
import env from '../../../../../config/dev.env';

export default {
    data() {
        return {
            form: new Form({
                // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/decodeURIComponent
                email: decodeURIComponent(this.$route.query.email),
                password: '',
                password_confirmation: '',
                token: this.$route.params.token,
            }),
            message: null,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                email: v => validationService.isEmailValid(v) || 'Email must be valid.',
            },
        }
    },
    computed: {
        ...mapGetters([
            'isHCP',
            'user',
        ]),
    },
    methods: {
        async submit() {
            try {
                this.message = null;
                let config = {
                    headers: {'content-type': 'application/json;charset=utf-8',
                    'X-Requested-With': 'XMLHttpRequest' }
                }
                const {data} = await this.form.post(`${env.MEDCHAT_API_URL}/password/reset`, config);
                this.message = data.message;

                //await this.$store.dispatch('fetchUser');

                /*if (this.isHCP) {
                    this.$router.push({name: 'home'});
                } else {
                    this.$router.push({name: 'about'});
                }*/
            } catch (e) {
                if(e.response && e.response.data) {
                    this.errors = e.response.data
                } else {
                    // Log Error Somehow
                }
            }
        }
    }
}
</script>
