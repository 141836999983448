import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import themes from '../themes'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg',
    },
    // https://lobotuerto.com/vuetify-color-theme-builder/
    theme: {
        dark: false,
        themes,
    }
});
