<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12"
                   md="6">
                <v-form @submit.prevent="submit">
                    <v-card outlined
                            flat
                            tile
                            class="mb-4">
                        <v-toolbar color="primary"
                                   flat
                                   tile
                                   dark class="height-50">
                            <v-toolbar-title>
                                Verify Your Email Address
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5 pt-7 pb-0">
                            <p>
                                We sent a verification email to <strong>{{ email }}</strong>. Please click the link in that email to verify your email address so you can register for events.
                            </p>

                            <p class="mb-0">
                                If you did not receive the email,
                            </p>
                        </v-card-text>
                        <v-card-actions class="px-5 py-3">
                            <v-btn type="submit"
                                   color="secondary"
                                   class="font-weight-bold"
                                   :disabled="form.busy"
                                   tile
                                   block
                                   depressed
                                   dark>
                                Click here to resend
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-alert type="info"
                             color="#23004c"
                             icon="fas fa-check-circle">
                        Check your email and spam folder!
                    </v-alert>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import Form from 'vform';
import env from '../../../../config/dev.env';
import axios from 'axios';

export default {
    data() {
        return {
            form: new Form({
                email: ''
            })
        }
    },
    computed: {
        email() {
            return this.$route.query.email;
        }
    },
    created() {
        if (this.$root.isEmailVerified) {
            this.$router.push({name: 'about'});
        } else {
            this.verifyEmail();
        }
    },
    methods: {
        submit() {
            this.form.email = this.email;
            this.form.post(`${env.MEDCHAT_API_URL}/email/resend`)
                .then(response => {
                    //this.$toast.success('Check your email and spam folder!', {timeout: 6});
                })
        },
        async verifyEmail() {
            
            if(this.$route.params.id && this.$route.params.hash) {
                try {
                    const params = new URLSearchParams(this.$route.query)
                    let res = await axios.get(
                        `${env.MEDCHAT_API_URL}/email/verify/` +
                        this.$route.params.id +
                        '/' +
                        this.$route.params.hash,
                    { params }
                    )
                    // console.log(res);
                    
                    if (res.data.access_token) {
                        this.$store.commit('SET_ACCESS_TOKEN', res.data.access_token)
                        axios.defaults.headers.common = {'Authorization': `Bearer ${res.data.access_token}`};
                    }
                    
                    this.$router.push({ name: 'set_password' })
                    
                    } catch (error) {
                        // console.log(error.response)

                }
            }
        }
    }
}
</script>
