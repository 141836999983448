<template>
    <v-form @submit.prevent="search"
            ref="form">
        <v-card outlined
                flat
                tile>
            <v-toolbar color="primary"
                       flat
                       tile
                       dark class="height-50">
                <v-toolbar-title>
                    Add HCPs to this Event
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text class="pb-0">

                <v-text-field v-model="form.query"
                              name="fake-search-query"
                              label="Search for HCPs by Last Name"
                              outlined
                              autocomplete="fake-search-query"
                              clearable
                              @keyup.enter="search"
                              :rules="selectedHCPs.length ? [] : [rules.required]"
                              @click:clear="clearSearch">
                </v-text-field>

                <div class="d-flex justify-center"
                     v-if="form.busy">
                    <v-progress-circular indeterminate>
                    </v-progress-circular>
                </div>

                <div v-if="!form.busy && searched">
                    <div style="color: #00b0a9;" class="font--size-24 mb-2">
                        {{ hcps.length | pluralize('Match') }}
                    </div>
                    <v-list class="mb-2 HCP-Search-Results">
                        <v-list-item v-for="hcp in hcps"
                                     :key="hcp.id"
                                     @click.stop="toggleHCP(hcp)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ hcp.full_name_designation }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action v-if="!isSelected(hcp)">
                                <img src="/images/add_hcp_icon.png"
                                     alt="Add HCP"/>
                            </v-list-item-action>
                            <v-list-item-action v-else>
                                <img src="/images/remove_hcp_icon.png"
                                     alt="Remove HCP"/>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item :to="{name: 'hcp.create', query: {meeting_id: meeting.id}}">
                            <v-list-item-content>
                                <v-list-item-title class="font-weight-bold">
                                    Not found - add new HCP
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <img src="/images/add_hcp_icon.png"
                                     alt="Add HCP"/>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>

                <!--<div v-if="!form.busy && selectedHCPs.length">
                    <div style="color: #00b0a9;" class="font&#45;&#45;size-24 mb-2">
                        Selected
                    </div>
                    <v-list class="HCP-Search-Results">
                        <v-list-item v-for="hcp in selectedHCPs"
                                     :key="hcp.id"
                                     @click.stop="removeHCP(hcp)">
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ hcp.full_name_designation }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <img src="/images/remove_hcp_icon.png"
                                     alt="Remove HCP"/>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </div>-->
            </v-card-text>
            <v-card-actions class="pa-4 flex-column">
                <v-btn v-if="selectedHCPs.length === 0"
                       @click.stop="search"
                       color="secondary"
                       class="font-weight-bold text-no-transform"
                       :disabled="form.busy"
                       x-large
                       dark
                       tile
                       depressed
                       block>
                    SEARCH HCPs
                </v-btn>
                <v-btn v-if="selectedHCPs.length"
                       @click.stop="addHCPs"
                       color="secondary"
                       class="font-weight-bold text-no-transform"
                       :disabled="form.busy"
                       x-large
                       dark
                       tile
                       depressed
                       block>
                    ADD SELECTED HCPs TO THIS MEETING
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-alert type="error"
                 v-if="error"
                 class="my-4">
            {{ error }}
        </v-alert>
    </v-form>
</template>
<script>
import _ from 'lodash';
import {Form} from 'vform';
import BrandLogo from '../../components/BrandLogo';
import formValidation from '../../plugins/formValidation';
import SelectIcon from '../../components/forms/SelectIcon';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';

export default {
    name: 'add-hcps-card',
    components: {SelectIcon, BrandLogo},
    mixins: [formValidation],
    props: {
        meeting: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            error: null,
            form: new Form({
                query: '',
            }),
            hcps: [],
            searched: false,
            selectedHCPs: [],
        }
    },
    methods: {
        search() {
            if (this.form.busy) {
                return;
            }
            if (!this.form.query || this.form.query.length < 2) {
                return;
            }
            const options = {
                params: {
                    //query: this.form.query, Vigneswaran changed
                    last_name: this.form.query,
                }
            }
            this.form.get(`${env.MEDCHAT_API_URL}/api/hcps`, options)
                .then(response => {
                    this.hcps = response.data.filter(h => {
                        const name = h.full_name_designation.toLowerCase();
                        return !name.includes('sales')
                            && !name.includes('rep-');
                    });
                    this.searched = true;
                })
                .catch(err => {
                    this.hcps = [];
                })
        },
        clearSearch() {
            this.form.reset();
            this.form.clear();
            this.$refs.form.reset();
            this.form.query = '';
            this.hcps = [];
            this.searched = false;
            this.selectedHCPs = [];
        },
        toggleHCP(hcp) {
            if (this.isSelected(hcp)) {
                this.removeHCP(hcp);
            } else {
                this.addHCP(hcp);
            }
        },
        addHCP(hcp) {
            const index = _.findIndex(this.selectedHCPs, h => h.id === hcp.id);
            if (index === -1) {
                this.selectedHCPs.push(hcp);
            }
        },
        removeHCP(hcp) {
            this.selectedHCPs = this.selectedHCPs.filter(h => h.id !== hcp.id);
        },
        isSelected(hcp) {
            const index = _.findIndex(this.selectedHCPs, h => h.id === hcp.id);
            return index !== -1;
        },
        addHCPs() {
            const form = {
                hcp_ids: _.map(this.selectedHCPs, h => {
                    return h.id;
                }),
            };
            // console.log('Add selected HCPs to the meeting', form);
            axios.post(`${env.MEDCHAT_API_URL}/api/meetings/${this.meeting.id}/hcps`, form)
                .then(response => {
                    this.clearSearch();
                    this.$emit('updated');
                })
                .catch(err => {
                    if (err.response && err.response.data) {
                        //this.$toastr.error(err.response.data);
                    }
                });
        }
    }
}
</script>
