<template>
    <v-navigation-drawer app
                         right
                         dark
                         v-model="$root.drawer">
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="title">
                    medChat for Sanofi
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-divider>
        </v-divider>

        <v-list dense
                nav>

            <v-list-item :to="{name: 'home'}"
                         v-if="authenticated">
                <v-list-item-icon>
                    <v-icon>
                        {{ mdiHome }}
                        <!-- mdi-home -->
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        Home
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item @click.prevent="logout"
                         v-if="authenticated">
                <v-list-item-icon>
                    <v-icon>
                        {{mdiLogout}}
                        <!-- mdi-logout -->
                    </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>
                        Log Out
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
    import {mapGetters} from 'vuex';
    import {mdiHome,mdiLogout } from'@mdi/js'

    export default {
        data() {
            return {
                mdiHome,
                mdiLogout,
                items: []
            }
        },
        computed: {
            ...mapGetters({
                authenticated: 'authCheck',
                cartItems: 'cart/items',
                user: 'authUser',
            }),
        },
        methods: {
            async logout() {
                await this.$store.dispatch('logout');
                this.$router.push({name: 'login'});
            }
        }
    }
</script>
