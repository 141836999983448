<template>
    <router-view class="Wizard"></router-view>
</template>
<script>
    import BrandLogo from '../../components/BrandLogo';

    export default {
        components: {BrandLogo}
    }
</script>
