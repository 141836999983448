<template>
    <v-list-item @click.stop="addHCP(hcp)">
        <v-list-item-content>
            <v-list-item-title>
                {{ hcp.full_name_designation }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="hcp.email">
                Email: {{ hcp.email }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="hcp.npi">
                NPI: {{ hcp.npi }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="hcp.practice_name">
                {{ hcp.practice_name }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="hcp.city && hcp.state">
                {{ hcp.city }}, {{ hcp.state }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
            <img src="/images/add_hcp_icon.png"
                 alt="Add HCP"/>
        </v-list-item-action>
    </v-list-item>
</template>
<script>
export default {
    name: 'hcp-list-item',
    props: {
        hcp: {
            type: Object,
            required: true,
        }
    },
    methods: {
        addHCP() {
            this.$emit('addHCP', this.hcp);
        }
    }
}
</script>
