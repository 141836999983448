<template>
    <v-container>
    </v-container>
</template>
<script>
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';

export default {
    data() {
        return {
            busy: false,
        }
    },
    created() {
        this.busy = true;
        this.$store.commit('CLEAR_WIZARD');
    
        // Just init the wizard, with no brand for now
        this.$store.dispatch('setWizard', dataService.initWizardFields());
        this.$router.push({name: 'wizard.topic'});

        // TODO: I think we need to set the brand for the wizard after we have the Topic Selected -
        // I am thinking we will keep the two brands in state and then just refer to the objects 
        // in state rather than making another request here - this request seems to be duplicated 
        // a couple of different ways in a couple of different places.

        // const brandCode = 'sanofi_unbranded';
        // const brandCode = this.$root.brandCode;
        
        // axios.get(`${env.MEDCHAT_API_URL}/api/brands/${brandCode}`)
        //     .then(response => response.data)
        //     .then(brand => {
        //         console.log(brand);
        //         axios.get(`${env.MEDCHAT_API_URL}/api/brands/${brand.code}/topics`)
        //             .then(response => {
        //                 brand.topics = response.data;
        //                 console.log(brand.topics);
        //                 this.$store.commit('SET_BRAND', brand); // MPEX-6
        //                 this.$store.dispatch('setWizard', dataService.initWizardFields(brand));
        //                 this.$router.push({name: 'wizard.topic'});
        //             })
        //     })
        //     .catch(err => {
        //         console.error(err);
        //         this.$router.push({name: 'about'});
        //     })
        //     .finally(() => {
        //         this.busy = false;
        //     })
    }
}
</script>
