<template>
    <tr v-if="rep">
        <td>
            {{ rep.name }}
        </td>
        <td>
            {{ rep.email }}
        </td>
        <td>
            {{ rep.phone }}
        </td>
    </tr>
</template>
<script>
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';
export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            rep: null,
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            axios.get(`${env.MEDCHAT_API_URL}/api/mchat/salesreps/${this.id}`)
                .then(response => {
                    this.rep = response.data;
                })
                .catch(err => {

                })
        }
    }
}
</script>
