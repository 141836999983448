<template>
    <v-container>
        <div class="MeetingTable__Container px-7 py-5">

            <template>
                <v-container class="py-0">
                    <v-row>
                        <v-col cols="6"
                               class="pl-md-0 mb-3">
                            <v-btn tile
                                   :disabled="busy || !canGoPrevious()"
                                   @click.prevent="gotoPrevious">
                                &larr;
                                Previous
                            </v-btn>
                        </v-col>
                        <v-col cols="6"
                               class="d-flex justify-end pr-0 pb-3">
                            <v-btn tile
                                   :disabled="busy || !canGoNext()"
                                   @click.prevent="gotoNext">
                                Next
                                &rarr;
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <v-skeleton-loader v-if="busy"
                               type="heading, table-tbody"
                               :types="types">
            </v-skeleton-loader>

            <p v-if="!busy && filteredMeetings.length === 0"
               class="mb-0">
                There are no meetings scheduled between {{ startDate.format('MMMM D, YYYY') }} and {{ endDate.format('MMMM D, YYYY') }}.
            </p>

            <template v-if="!busy && filteredMeetings.length > 0"
                      v-for="(meetings, date) in filteredMeetingDates">
                <v-container class="MeetingTable__DateContainer py-0"
                             :key="date">
                    <v-row class="MeetingTable__DateRow">
                        <v-col cols="12"
                               class="pl-md-0">
                            <div class="MeetingTable__Date">
                                {{ date }}
                            </div>
                        </v-col>
                    </v-row>
                    <v-row v-for="meeting in meetings"
                           :key="meeting.id"
                           class="MeetingTable__Meeting">
                        <v-col cols="12"
                               md="2"
                               class="pl-md-0 MeetingTable__Time">
                            {{ meeting.meeting_scheduled_at | datetime('h:mm') }} <span class="text-small-caps">{{ meeting.meeting_scheduled_at | datetime('a') }}</span> {{ meeting.meeting_scheduled_at | datetime('z') }}
                        </v-col>
                        <v-col cols="12"
                               md="1" class="MeetingTable__Type">
                            <v-img v-if="meeting.is_source_portal"
                                   src="/images/small-medchat.png"
                                   width="58"
                                   height="32"
                                   contain
                                   alt="mChat icon"/>

                            <v-img v-if="meeting.is_source_api"
                                   src="/images/small-medchat.png"
                                   width="58"
                                   height="32"
                                   contain
                                   alt="mChat icon"/>
                        </v-col>
                        <v-col cols="12"
                               md="3"
                               class="MeetingTable__Topic">
                            {{ meeting.topic.name }}
                        </v-col>
                        <v-col cols="12"
                               md="4"
                               class="MeetingTable__Speaker">
                            <div v-if="meeting.speaker.name && meeting.speaker.professional_designation">
                                {{ meeting.speaker.name }}, {{ meeting.speaker.professional_designation }}
                            </div>
                            <div v-else>
                                {{ meeting.speaker.name }}
                            </div>
                            <div>
                                {{ meeting.speaker.practice_name }}
                            </div>
                            <div v-if="meeting.speaker.city && meeting.speaker.state">
                                {{ meeting.speaker.city }}, {{ meeting.speaker.state }}
                            </div>
                        </v-col>
                        <v-col cols="12"
                               md="2"
                               class="MeetingTable__Actions">
                            <v-btn :to="{name: 'meeting.show', params: {meetingID: meeting.id}}"
                                   color="secondary"
                                   class="font-weight-bold text-no-transform"
                                   dark
                                   tile
                                   depressed>
                                REGISTER HCPs
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </div>

    </v-container>
</template>
<script>
import {mapGetters} from 'vuex';
import groupBy from 'lodash/groupBy';
import jstz from 'jstz';
import MeetingRow from './MeetingRow';
import dataService from '../../services/dataService';
import env from '../../../../config/dev.env';
import moment from 'moment-timezone';

export default {
    name: 'meeting-table',
    components: {MeetingRow},
    props: {
        prescheduled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            incrementMonths: 2,
            busy: false,
            meetings: [],
            startDate: moment().startOf('day'),
            endDate: moment().add(2, 'months'),
            types: {
                'heading': 'heading',
                'table': 'table-thead, table-tbody',
                'table-heading': 'heading, text',
                'table-thead': 'heading@4',
                'table-tbody': 'table-row-divider@8',
                'table-row-divider': 'table-row, divider',
                'table-row': 'table-cell@4',
                'table-cell': 'text',
                'table-tfoot': 'text@2, avatar@2',
                'text': 'text'
            }
        }
    },
    computed: {
        ...mapGetters([
            'authenticated',
            'isHCP',
        ]),
        now() {
            return moment();
        },
        filteredMeetings() {
            if (this.prescheduled) {
                return this.meetings.filter(m => m.is_source_portal);
            }
            return this.meetings;
        },
        filteredMeetingDates() {
            return groupBy(this.filteredMeetings, m => {
                return moment(m.meeting_scheduled_at).format('MMMM D, YYYY');
            })
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        canGoPrevious() {
            //return this.startDate.isAfter(this.now, 'day');
            return moment().isBefore(this.startDate, 'day');
        },
        canGoNext() {
            return this.startDate.isBefore(moment().add(6, 'months'), 'day');
        },
        fetchData() {
            this.getMeetings();
        },
        gotoPrevious() {
            this.startDate.subtract(this.incrementMonths, 'months');
            this.endDate.subtract(this.incrementMonths, 'months');
            this.getMeetings();
        },
        gotoNext() {
            this.startDate.add(this.incrementMonths, 'months');
            this.endDate.add(this.incrementMonths, 'months');
            this.getMeetings();
        },
        getMeetings() {
            if (this.busy) {
                return;
            }
            let options = {
                params: {
                    start: this.startDate.format('YYYY-MM-DD'),
                    end: this.endDate.format('YYYY-MM-DD'),
                    timezone: jstz.determine().name(),
                }
            };
            this.busy = true;
            axios.get(`${env.MEDCHAT_API_URL}/api/meetings/upcoming`, options)
                .then(response => {
                    this.meetings = response.data;
                })
                .catch(err => {
                })
                .finally(() => {
                    this.busy = false;
                })
        }
    }
}
</script>
