<template>
  <v-card outlined
          class="Resources__Card mt-5">
    <v-toolbar color="primary"
               flat
               tile
               dark class="height-50 mb-5">
      <v-toolbar-title>
        Download the mChat Mobile Player
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>

      <v-btn href="https://apps.apple.com/us/app/mchat-mobile-player/id1491889550"
             target="_blank"
             block
             depressed
             tile
             x-large
             color="#e4ccfa"
             class="mb-3 font-weight-bold text-no-transform black--text"
             style="letter-spacing: initial;">
        <strong>FOR iPHONE<sup>&reg;</sup>&nbsp;AND&nbsp;iPAD<sup>&reg;</sup></strong>
      </v-btn>

      <v-btn href="https://play.google.com/store/apps/details?id=com.connexion360.mchatplayer"
             target="_blank"
             block
             depressed
             tile
             x-large
             color="#e4ccfa"
             class="mb-3 font-weight-bold text-no-transform black--text"
             style="letter-spacing: initial;">
        <strong>FOR ANDROID™</strong>
      </v-btn>

      <p class="mt-7">
        iPhone and iPad are registered trademarks of Apple, Inc.
      </p>
      <p>
        Android is a trademark of Google, LLC.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: 'download-app-card',
}
</script>
