<template>
    <div>
        <!-- <v-container class="pa-0">
            <div class="Home__Hero mb-3 pa-0 d-flex">
                
            </div>
        </v-container> -->
        <v-container class="pb-0 pt-10">
            <v-row class="py-0 justify-center Home__Align-End">
                <v-col cols="12"
                        md="3">
                        <v-img class="mb-1" src="/images/medchat.png"
                        alt="medChat"/>
                </v-col>
                <v-col cols="12"
                        md="5">
                        <h1 class="Home__Hero-Title">
                            Online Scheduling Assistant
                        </h1>
                        <h2 class="Home__Hero-Subtitle">
                            medChat is a webinar platform that provides healthcare professionals the opportunity to engage with national and regional thought leaders via virtual speaker programs.
                        </h2>
                </v-col>

                <v-col cols="12"
                       md="8" class="mt-3">
                    <v-card tile
                            class="Home__RegisterCard">
                        <v-toolbar dark
                                   color="primary"
                                   flat
                                   tile
                                   class="d-flex justify-center height-50">
                            <v-toolbar-title>
                                REGISTER NOW!
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="pa-8 padding-no">
                            <div class="mx-12 mob-margin-no">
                                <p>
                                    Register for a medChat to get an exclusive presentation and Q&A session for your practice of 3 or more healthcare professionals.*
                                </p>
                                <p class="font-weight-bold mb-1">
                                    For your medChat, you select:
                                </p>
                                <ul class="Purple-Bullets mb-3">
                                    <li>
                                        The program module topic of most interest
                                    </li>
                                    <li>
                                        3 convenient dates and times
                                    </li>
                                    <li>
                                        3 preferred speakers
                                    </li>
                                </ul>
                                <p class="font-weight-bold mt-5 mb-9">
                                    Your medChat will be confirmed within 5 business days.
                                </p>
                            </div>
                            <v-btn :to="{name: 'wizard.start'}"
                                   color="secondary"
                                   class="font-weight-bold text-no-transform mb-6"
                                   tile
                                   depressed
                                   block>
                                REGISTER FOR A medCHAT
                            </v-btn>
                            <p class="text-center font--size-14 mb-0">
                                *More than one practice may participate in a custom medChat.
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import MeetingList from '../components/meetings/MeetingList';

export default {
    components: {MeetingList},
    computed: {
        ...mapGetters([
            'authenticated',
            'isHCP',
            'isRep',
            'user',
        ])
    },
    // created() {
    //     if (this.isRep) { // MSPR-29 a rep will be redirected to the About page.
    //         this.$router.push({name: 'about'});
    //     }
    // }
}
</script>
