<template>
    <v-container fluid
                 v-if="request">

        <v-card>
            <v-toolbar color="secondary"
                       dark>
                <v-toolbar-title>
                    request
                </v-toolbar-title>

            </v-toolbar>
            <v-card-text>

                <pre>{{ request }}</pre>

            </v-card-text>
        </v-card>

    </v-container>
</template>
<script>
    import axios from 'axios';
    import dataService from '../../services/dataService';
    import env from '../../../../config/dev.env';

    export default {
        data() {
            return {
                request: null,
            }
        },
        computed: {
            requestID() {
                return parseInt(this.$route.params.requestID);
            }
        },
        created() {
            this.fetchData();
        },
        methods: {
            fetchData() {
                axios.get(`${env.MEDCHAT_API_URL}/api/meeting-requests/${this.requestID}`)
                    .then(response => {
                        this.request = response.data
                    })
                    .catch(() => {
                        this.$router.replace({name: 'admin.requests'});
                    })
            }
        }
    }
</script>
