<template>
    <v-container v-if="user">
        <v-form @submit.prevent="submit">
            <v-card>
                <v-toolbar color="secondary"
                           dark>
                    <v-toolbar-title>
                        Edit User
                    </v-toolbar-title>
                </v-toolbar>
                <user-form :form="form">
                </user-form>
                <v-card-actions>
                    <v-btn type="submit"
                           dark
                           color="secondary">
                        Submit
                    </v-btn>
                    <v-btn text
                           @click.prevent="$router.back()">
                        Cancel
                    </v-btn>
                    <v-spacer />
                    <v-btn type="button"
                           @click.prevent="sendResetPassword">
                        Send Reset Password
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>

    </v-container>
</template>
<script>
import axios from 'axios';
import dataService from '../../services/dataService';
import UserForm from './UserForm';
import env from '../../../../config/dev.env';

export default {
    components: {UserForm},
    data() {
        return {
            form: new Form(dataService.initUserForm()),
            user: null,
        }
    },
    computed: {
        userID() {
            return parseInt(this.$route.params.userID);
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData() {
            axios.get(`${env.MEDCHAT_API_URL}/api/users/${this.userID}`)
                .then(response => {
                    this.user = response.data;
                    this.initForm();
                })
                .catch(() => {
                    this.$router.replace({name: 'admin.users'});
                })
        },
        initForm() {
            this.form.first_name = this.user.first_name;
            this.form.last_name = this.user.last_name;
            this.form.email = this.user.email;
            this.form.timezone = this.user.timezone;
            this.form.phone = this.user.phone;
            this.form.specialty = this.user.specialty;
            this.form.professional_designation = this.user.professional_designation;
            this.form.npi = this.user.npi;
            this.form.state = this.user.state;
            this.form.zip_code = this.user.zip_code;
            this.form.type = this.user.type;
            this.form.mchat_rep_id = this.user.mchat_rep_id;
            this.form.mchat_hcp_id = this.user.mchat_hcp_id;
            this.form.email_verified = !!this.user.email_verified_at;
            this.form.is_password_set = this.user.is_password_set;
        },
        submit() {
            this.form.put(`${env.MEDCHAT_API_URL}/api/users/${this.userID}`)
                .then(response => {
                    this.$router.push({name: 'admin.users'});
                })
        },
        sendResetPassword() {
            const form = {
                email: this.user.email,
            }
            axios.post(`${env.MEDCHAT_API_URL}/password/email`, form)
                .then(response => {
                    //this.$toast.success(response.data.message)
                    this.$router.push({name: 'admin.users'});
                })
                .catch(err => {

                })
        }
    }
}
</script>
