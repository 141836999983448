const WIZARD_KEY = 'wizard';
const VERSION = 3;
import _ from 'lodash';
import axios from 'axios';
import env from '../../../config/dev.env';
//import { resolve } from 'core-js/fn/promise';

const API_TOKEN = 'api_token';
const MUST_CONFIRM = 'must_confirm';
const WIZARD_FIELDS = [
    'version',
    'brand',
    'meeting_type',
    'appointments',
    'hcps',
    'speakerConfirmed',
    'speakers',
    'topic_id',
];

const BRAND_CODES = [
    'menquadfi',
    'sanofi_unbranded',
];
// const BRAND_CODE = 'sanofi_unbranded';

const localStorage = window.localStorage;

export default {
    initUserForm() {
        return {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            timezone: '',
            phone: '',
            specialty: '',
            professional_designation: '',
            npi: '',
            state: '',
            zip_code: '',
            type: '',
            mchat_rep_id: '',
            mchat_hcp_id: '',
            email_verified: false,
            is_password_set: false,
        }
    },
     BRAND_CODES: BRAND_CODES,
    // BRAND_CODE: BRAND_CODE,
    PROFESSIONAL_DESIGNATION : [],
    //MEDCHAT_API_URL: MEDCHAT_API_URL,
    icons() {
        return {
            mchat: '/images/mchat_logo.png',
        }
    },
    getMustConfirmEmail() {
        return localStorage.getItem(MUST_CONFIRM);
    },
    mustConfirmEmail(email) {
        localStorage.setItem(MUST_CONFIRM, email);
    },
    clearConfirmEmail() {
        localStorage.removeItem(MUST_CONFIRM);
    },
    clearWizardFields() {
        //console.log('clearWizardFields()');
        localStorage.removeItem(WIZARD_KEY);
    },
    getWizardFields() {
        const wizard = JSON.parse(localStorage.getItem(WIZARD_KEY));
        if (wizard && wizard.version === VERSION) {
            //console.log('wizard was in localStorage');
            return _.pick(wizard, WIZARD_FIELDS);
        } else {
            this.clearWizardFields();
            return this.initWizardFields();
        }
    },
    saveWizardFields(wizard) {
        const w = JSON.stringify(_.pick(wizard, WIZARD_FIELDS));
        //console.log('saveWizardFields()');
        localStorage.setItem(WIZARD_KEY, w);
    },
    initWizardFields(brand = null) {
        return {
            version: VERSION,
            appointments: [],
            brand: brand,
            hcp: null,
            hcps: [],
            meeting_type: 'mchat',
            speakerConfirmed: false, // AM-17
            speakers: [],
            topic_id: null,
            topic: null,
        }
    },
    getApiToken() {
        return localStorage.getItem(API_TOKEN);
    },
    login(apiToken) {
        //localStorage.setItem(API_TOKEN, apiToken);
        //location.href = '/';
    },
    logout() {
        localStorage.removeItem(API_TOKEN);
    },
    meetingTypes() {
        return [
            {name: 'mChat', id: 'mchat'},
        ]
    },
    async professionalDesignations() {
        // This isn't currently in use but leftover from legacy when this was stored in window storage,
        // the actual data fetch happens in the vuex store  (when refactored this seemed like a redundant function)
        const res = await axios.get(`${env.MEDCHAT_API_URL}/api/degrees`);
        return res;
        
    },
    async specialties() {
        // This isn't currently in use but leftover from legacy when this was stored in window storage,
        // the actual data fetch happens in the vuex store (when refactored this seemed like a redundant function)
        const res = await axios.get(`${env.MEDCHAT_API_URL}/api/specialties`)
        return res;        
        
    },
    getStates() {
        // https://gist.github.com/mshafrir/2646763
        return [
            {
                'name': 'Alabama',
                'id': 'AL'
            },
            {
                'name': 'Alaska',
                'id': 'AK'
            },
            /*{
             "name": "American Samoa",
             "id": "AS"
             },*/
            {
                'name': 'Arizona',
                'id': 'AZ'
            },
            {
                'name': 'Arkansas',
                'id': 'AR'
            },
            {
                'name': 'California',
                'id': 'CA'
            },
            {
                'name': 'Colorado',
                'id': 'CO'
            },
            {
                'name': 'Connecticut',
                'id': 'CT'
            },
            {
                'name': 'Delaware',
                'id': 'DE'
            },
            {
                'name': 'District of Columbia',
                'id': 'DC'
            },
            /*{
             "name": "Federated States Of Micronesia",
             "id": "FM"
             },*/
            {
                'name': 'Florida',
                'id': 'FL'
            },
            {
                'name': 'Georgia',
                'id': 'GA'
            },
            {
                'name': 'Guam',
                'id': 'GU'
            },
            {
                'name': 'Hawaii',
                'id': 'HI'
            },
            {
                'name': 'Idaho',
                'id': 'ID'
            },
            {
                'name': 'Illinois',
                'id': 'IL'
            },
            {
                'name': 'Indiana',
                'id': 'IN'
            },
            {
                'name': 'Iowa',
                'id': 'IA'
            },
            {
                'name': 'Kansas',
                'id': 'KS'
            },
            {
                'name': 'Kentucky',
                'id': 'KY'
            },
            {
                'name': 'Louisiana',
                'id': 'LA'
            },
            {
                'name': 'Maine',
                'id': 'ME'
            },
            /*{
             "name": "Marshall Islands",
             "id": "MH"
             },*/
            {
                'name': 'Maryland',
                'id': 'MD'
            },
            {
                'name': 'Massachusetts',
                'id': 'MA'
            },
            {
                'name': 'Michigan',
                'id': 'MI'
            },
            {
                'name': 'Minnesota',
                'id': 'MN'
            },
            {
                'name': 'Mississippi',
                'id': 'MS'
            },
            {
                'name': 'Missouri',
                'id': 'MO'
            },
            {
                'name': 'Montana',
                'id': 'MT'
            },
            {
                'name': 'Nebraska',
                'id': 'NE'
            },
            {
                'name': 'Nevada',
                'id': 'NV'
            },
            {
                'name': 'New Hampshire',
                'id': 'NH'
            },
            {
                'name': 'New Jersey',
                'id': 'NJ'
            },
            {
                'name': 'New Mexico',
                'id': 'NM'
            },
            {
                'name': 'New York',
                'id': 'NY'
            },
            {
                'name': 'North Carolina',
                'id': 'NC'
            },
            {
                'name': 'North Dakota',
                'id': 'ND'
            },
            /*{
             "name": "Northern Mariana Islands",
             "id": "MP"
             },*/
            {
                'name': 'Ohio',
                'id': 'OH'
            },
            {
                'name': 'Oklahoma',
                'id': 'OK'
            },
            {
                'name': 'Oregon',
                'id': 'OR'
            },
            /*{
             "name": "Palau",
             "id": "PW"
             },*/
            {
                'name': 'Pennsylvania',
                'id': 'PA'
            },
            {
                'name': 'Puerto Rico',
                'id': 'PR'
            },
            {
                'name': 'Rhode Island',
                'id': 'RI'
            },
            {
                'name': 'South Carolina',
                'id': 'SC'
            },
            {
                'name': 'South Dakota',
                'id': 'SD'
            },
            {
                'name': 'Tennessee',
                'id': 'TN'
            },
            {
                'name': 'Texas',
                'id': 'TX'
            },
            {
                'name': 'Utah',
                'id': 'UT'
            },
            {
                'name': 'Vermont',
                'id': 'VT'
            },
            /*{
             "name": "Virgin Islands",
             "id": "VI"
             },*/
            {
                'name': 'Virginia',
                'id': 'VA'
            },
            {
                'name': 'Washington',
                'id': 'WA'
            },
            {
                'name': 'West Virginia',
                'id': 'WV'
            },
            {
                'name': 'Wisconsin',
                'id': 'WI'
            },
            {
                'name': 'Wyoming',
                'id': 'WY'
            }
        ]
    },
    timezones() {
        return [
            {name: 'Eastern', id: 'America/New_York'},
            {name: 'Central', id: 'America/Chicago'},
            {name: 'Mountain', id: 'America/Denver'},
            {name: 'Pacific', id: 'America/Los_Angeles'},
            {name: 'Alaska', id: 'America/Anchorage'},
            {name: 'Hawaii', id: 'Pacific/Honolulu'},
            {name: 'Atlantic (Puerto Rico)', id: 'America/Puerto_Rico'}
        ];
    },
    getSpeakersForBrands() {
        return axios.get(`${env.MEDCHAT_API_URL}/api/speakers`)
            .then(({data}) => data)
    },
    getSpeakersForBrand(brandCode) {
        return axios.get(`${env.MEDCHAT_API_URL}/api/brands/${brandCode}/speakers`)
            .then(({data}) => data)
    },
    getTopics(brandCode) {
        return axios.get(`${env.MEDCHAT_API_URL}/api/brands/${brandCode}/topics`)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.error(err.response.data);
                return [];
            })
    },
    //new change
    async getMultipleTopics(brandCodes) {
        // console.log('get topics')
        let data = [];
        await Promise.all(brandCodes.map(brand => {
            return axios.get(`${env.MEDCHAT_API_URL}/api/brands/${brand}/topics`)
            .then(response => {
                //console.log(response.data);
                data = [...data, ...response.data]
            })
            .catch(err => {
                //console.log(err.response.data);
                return data = []
            })
        }));
        //console.log(data);
        return data;
    }
}
